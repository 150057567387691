<template>
  <div>
    <b-button v-b-modal.pos-product-import-modal size="sm" variant="info">
      {{ $t('pos_product_import.button_title') }}
    </b-button>

    <b-modal size="lg" id="pos-product-import-modal" :title="$t('pos_product_import.modal_title')" :hide-footer="true"
      @show="getAllHumanProducts" @hide="clearData">
      <b-row class="mx-1 mb-3">
        <b-form-file variant="primary" :placeholder="$t('global.choose_or_drop_in_file')" @input="getImportInfo"
          :drop-placeholder="$t('global.drop_in_file')" :browse-text="$t('global.browse')">
        </b-form-file>
      </b-row>

      <div v-if="loading" class="d-flex mx-1 mb-3" style="align-items: center">
        <b-spinner small class="d-block" />
        <span class="ml-1 d-block">{{ $t('global.loading') }}</span>
      </div>
      <div v-if="sheetsInfo" class="mx-1 mb-3">
        <b-form-group :label="$t('pos_product_import.selected_sheet')">
          <b-form-select v-model="selectedSheet" :options="sheetsOptions" />
        </b-form-group>

        <div class="d-flex flex-wrap" style="gap: 0.25rem">
          <b-form-group style="flex-grow: 1" :label="$t('pos_product_import.pos_code_column')">
            <b-form-select v-model="posCodeColumn" :options="columnOptions" />
          </b-form-group>
          <b-form-group style="flex-grow: 1" :label="$t('pos_product_import.price_column')">
            <b-form-select v-model="priceColumn" :options="columnOptions" />
          </b-form-group>
          <b-form-group style="flex-grow: 1" v-for="language in activeLanguages" :key="language.key"
            :label="`${language.key} ${$t('pos_product_import.translation_column')}`">
            <b-form-select v-model="translationColumns[language.key]" :options="columnOptions"
              @input="recalcTrigger = !recalcTrigger" />
          </b-form-group>
        </div>

        <div v-if="importData">
          <hr />

          <b-card v-if="importData.productPrices.length" no-body class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.product-prices variant="info" class="mt-0 text-left">
                {{ importData.productPrices.length }}
                {{ $dt('pos_product_import.products_with_prices_in_spreadsheet', "products with prices in spreadsheet")
                }}
              </b-button>
            </b-card-header>
            <b-collapse id="product-prices" accordion="product-prices" role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(product, index) in importData.productPrices" :key="`${product.code}:${index}`"
                  class="my-0">
                  {{ product.code }}: {{ product.price }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-for="(values, key) in importData.productTranslations" :key="`product-translations-${key}`" no-body
            class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle="`product-translations-${key}`" variant="info" class="mt-0 text-left">
                {{ values.length }}
                {{ $dt('pos_product_import.products_with_translation_in_spreadsheet',
        'products with translations in spreadsheet')
                }}
                ({{ key }})
              </b-button>
            </b-card-header>
            <b-collapse v-bind:id="`product-translations-${key}`" :accordion="`product-translations-${key}`"
              role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(product, index) in values" :key="`${product.code}:${index}`" class="my-0">
                  {{ product.code }}: {{ product.translation }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-if="importData?.missingPrice.length" no-body class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.missing-price variant="warning" class="mt-0 text-left">
                {{ importData.missingPrice.length }}
                {{ $dt('pos_product_import.products_without_price_in_spreadsheet',
        'products without price in spreadsheet')
                }}
              </b-button>
            </b-card-header>
            <b-collapse id="missing-price" accordion="missing-price" role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(code, index) in importData.missingPrice" :key="`${code}:${index}`" class="my-0">
                  {{ code }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-for="(values, key) in importData.missingTranslation" :key="`missing-translation-${key}`" no-body
            class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle="`missing-translation-${key}`" variant="warning" class="mt-0 text-left">
                {{ values.length }}
                {{ $dt('pos_product_import.products_without_translation_in_spreadsheet',
        'products without translation in spreadsheet')
                }}
                ({{ key }})
              </b-button>
            </b-card-header>
            <b-collapse v-bind:id="`missing-translation-${key}`" :accordion="`missing-translation-${key}`"
              role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(code, index) in values" :key="`${code}:${index}`" class="my-0">
                  {{ code }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.matches variant="success" class="mt-0 text-left">
                {{ importData.matches.length }} {{ $dt('pos_product_import.products_matched', 'products matched') }}
              </b-button>
            </b-card-header>
            <b-collapse id="matches" accordion="matches" role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(match, index) in importData.matches" :key="`${match.humanProduct.code}:${index}`"
                  class="my-0">
                  {{ match.humanProduct.name }}
                  (ID: {{ match.humanProduct.id }}) - {{ match.humanProduct.code }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-if="importData?.missingProduct.length" no-body class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.missing-products variant="warning" class="mt-0 text-left">
                {{ importData.missingProduct.length }}
                {{ $dt('pos_product_import.products_not_found_in_database', 'products not found in database')
                }}
              </b-button>
            </b-card-header>
            <b-collapse id="missing-products" accordion="missing-products" role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(product, index) in importData.missingProduct" :key="`${product.code}:${index}`"
                  class="my-0">
                  {{ product.code }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <hr />

          <b-card v-if="importData.priceChanged.length" no-body class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.prices-changed variant="success" class="mt-0 text-left font-weight-bold">
                {{ importData.priceChanged.length }}
                {{ $dt('pos_product_import.products_price_will_be_changed', 'products price will be changed')
                }}
              </b-button>
            </b-card-header>
            <b-collapse id="prices-changed" accordion="prices-changed" role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(match, index) in importData.priceChanged"
                  :key="`${match.humanProduct.code}:${index}`" class="my-0">
                  {{ match.humanProduct.name }}
                  (ID: {{ match.humanProduct.id }}) - {{ match.humanProduct.code }}: {{ match.price }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-for="(values, key) in importData.translationChanged" :key="`translation-changed-${key}`" no-body
            class="mb-2">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle="`translation-changed-${key}`" variant="success"
                class="mt-0 text-left font-weight-bold">
                {{ values.length }}
                {{ $dt('pos_product_import.products_translation_will_be_changed',
        'products translation will be changed')
                }}
                ({{ key }})
              </b-button>
            </b-card-header>
            <b-collapse v-bind:id="`translation-changed-${key}`" :accordion="`translation-changed-${key}`"
              role="tabpanel">
              <b-card-body>
                <b-card-text v-for="(match, index) in values" :key="`${match.humanProduct.code}:${index}`" class="my-0">
                  {{ match.humanProduct.name }}
                  (ID: {{ match.humanProduct.id }}) - {{ match.humanProduct.code }}: {{ match.translations[key] }}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <b-row class="ml-1 mb-3">
        <b-col md="auto" class="pl-0 pr-3">
          <b-button v-if="!importing" variant="info" @click="runImport"
            :disabled="loading || !importData || !importData?.matches.length">
            {{ $t('global.import') }}
          </b-button>
          <b-button v-else variant="info" disabled>
            <b-spinner small class="mr-1" />
            {{ $t('global.importing') }}
          </b-button>
        </b-col>
      </b-row>

      <b-alert :show="!!successfulImports.length" variant="success" class="mx-1 mb-2">
        {{ successfulImports.length }} {{
        $dt('pos_product_import.products_imported_successfully', 'products imported successfully')
      }}
      </b-alert>
      <b-alert :show="!!failedImports.length" variant="danger" class="mx-1 mb-2">
        {{ failedImports.length }} {{
        $dt('pos_product_import.products_import_failed', 'products import failed')
        }}
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import HumanProductsService from '@/services/human-products-service';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PosProductImport',
  data() {
    return {
      sheetsInfo: null,
      selectedSheet: null,
      posCodeColumn: null,
      priceColumn: null,
      translationColumns: {},
      allHumanProducts: null,

      successfulImports: [],
      failedImports: [],

      loading: false,
      importing: false,

      // Not a real value, simply using it to trigger computed
      // property recalculation
      recalcTrigger: false,
    };
  },
  emits: ['imported'],
  computed: {
    ...mapGetters([
      'activeLanguages',
    ]),
    sheetsOptions() {
      return this.sheetsInfo.map(sheet => ({
        value: sheet,
        text: sheet.name,
      }));
    },
    columnOptions() {
      let options = this.selectedSheet.columns.map(column => ({
        value: column,
        text: column.name,
      }));
      options.unshift({
        value: null,
        text: this.$t('pos_product_import.no_column_selected'),
      });
      return options;
    },
    importData() {
      // Do not remove - this is used to trigger recalculation
      this.recalcTrigger;

      // At least one of these columns must be selected
      let atLeastOne = [this.priceColumn, ...Object.values(this.translationColumns)];
      if (!this.posCodeColumn || !this.allHumanProducts || !atLeastOne.some(column => column)) {
        return null;
      }

      let usedTranslationColumns = {};
      for (const [language, column] of Object.entries(this.translationColumns)) {
        if (column) {
          usedTranslationColumns[language] = column;
        }
      }

      return this.matchImportData(
        this.posCodeColumn.values,
        this.priceColumn?.values,
        usedTranslationColumns,
        this.allHumanProducts,
      );
    },
  },
  watch: {
    activeLanguages() {
      this.refreshTranslationColumns();
    },
  },
  mounted() {
    this.refreshTranslationColumns();
  },
  methods: {
    refreshTranslationColumns() {
      for (const language of this.activeLanguages) {
        if (!this.translationColumns[language.key]) {
          this.translationColumns[language.key] = null;
        }
      }
      for (const language in this.translationColumns) {
        if (!this.activeLanguages.find(lang => lang.key === language)) {
          delete this.translationColumns[language];
        }
      }
    },
    parsePrice(cell) {
      if (typeof cell === 'number') {
        return cell;
      }
      if (typeof cell === 'string') {
        return parseInt(cell);
      }
      return null;
    },
    matchImportData(posCodes, prices, translationColumns, humanProducts) {
      let productPrices = [];
      let productTranslations = {};
      let matches = [];
      let missingProduct = [];
      let missingPrice = [];
      let missingTranslation = {};

      for (const language of Object.keys(translationColumns)) {
        productTranslations[language] = [];
        missingTranslation[language] = [];
      }

      for (const [index, maybeCode] of posCodes.entries()) {
        if (!maybeCode) {
          continue;
        }

        const code = maybeCode.toString().trim();

        let price = null;
        if (prices) {
          price = this.parsePrice(prices[index]);
          if (price === null) {
            missingPrice.push(code);
          }
          else {
            productPrices.push({
              code,
              price,
            });
          }
        }

        let translations = {};
        for (const [languageKey, column] of Object.entries(translationColumns)) {
          if (!column) {
            continue;
          }

          let translation = column.values?.[index];
          if (translation === undefined || translation === null) {
            missingTranslation[languageKey].push(code);
          }
          else {
            translations[languageKey] = translation;
            productTranslations[languageKey].push({
              code,
              translation,
            });
          }
        }

        const humanProduct = humanProducts.find(product => product.code === code);
        if (humanProduct) {
          matches.push({
            humanProduct,
            price,
            translations,
          });
        } else {
          missingProduct.push({
            code,
            price,
          });
        }
      }

      let priceChanged = [];
      for (const match of matches) {
        const humanProduct = match.humanProduct;
        if (match.price !== null && humanProduct.price !== match.price) {
          priceChanged.push(match);
        }
      }

      let translationChanged = {};
      for (const language of Object.keys(translationColumns)) {
        translationChanged[language] = [];
      }

      for (const match of matches) {
        const humanProduct = match.humanProduct;
        for (const [languageKey, translation] of Object.entries(match.translations)) {
          if (humanProduct.translations.name?.[languageKey] !== translation) {
            translationChanged[languageKey].push(match);
          }
        }
      }

      for (const key of Object.keys(translationColumns)) {
        if (!translationChanged[key].length) {
          delete translationChanged[key];
        }
        if (!missingTranslation[key].length) {
          delete missingTranslation[key];
        }
      }

      return {
        productPrices,
        productTranslations,
        matches,
        missingProduct,
        missingPrice,
        missingTranslation,
        priceChanged,
        translationChanged,
      };
    },
    async getAllHumanProducts() {
      let response = await HumanProductsService.list();
      this.allHumanProducts = response.data;
    },
    clearData() {
      this.sheetsInfo = null;
      this.selectedSheet = null;
      this.posCodeColumn = null;
      this.priceColumn = null;
      for (const language in this.translationColumns) {
        this.translationColumns[language] = null;
      }

      this.successfulImports = [];
      this.failedImports = [];
    },
    async runImport() {
      this.importing = true;
      let failedImports = [];
      let successfulImports = [];

      let updates = {};

      for (const match of this.importData.priceChanged) {
        match.humanProduct.price = match.price;
        if (!updates[match.humanProduct.id]) {
          updates[match.humanProduct.id] = match.humanProduct;
        }
        else {
          updates[match.humanProduct.id].price = match.price;
        }
      }

      for (const [key, matches] of Object.entries(this.importData.translationChanged)) {
        for (const match of matches) {
          // PHP replaces empty objects with empty arrays
          if (match.humanProduct.translations.name?.length === 0) {
            match.humanProduct.translations.name = {};
          }
          match.humanProduct.translations.name[key] = match.translations[key];

          if (!updates[match.humanProduct.id]) {
            updates[match.humanProduct.id] = match.humanProduct;
          }
          else {
            updates[match.humanProduct.id].translations.name[key] = match.translations[key];
          }
        }
      }

      for (const update of Object.values(updates)) {
        try {
          await HumanProductsService.update(update);
          successfulImports.push(update);
        }
        catch (error) {
          failedImports.push(update);
        }
      }

      await this.getAllHumanProducts();

      this.importing = false;
      this.successfulImports = successfulImports;
      this.failedImports = failedImports;

      this.$emit('imported');
    },
    async getImportInfo(file) {
      this.clearData();

      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      this.loading = true;
      try {
        let response = await HumanProductsService.getImportInfo(formData);
        this.sheetsInfo = response.data;
        this.selectedSheet = this.sheetsInfo[0];

      } catch (error) {
        this.sheetsInfo = null;
        this.selectedSheet = null;
      }

      this.loading = false;
    },
  },
};
</script>
