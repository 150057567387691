<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        sm="6"
        md="12"
      >
        <change-password />
      </b-col>
    </b-row><!--/.row-->
  </div>
</template>

<script>
import ChangePassword from './ChangePassword';

export default {
  name      : 'UserProfile',
  components: { ChangePassword },
  data      : function () {
    return {};
  },
};
</script>
