<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="isActiveForm ? '6' : '12'"
        :md="isActiveForm ? '8' : '12'"
        :lg="isActiveForm ? '9' : '12'"
      >
        <b-card>
          <template #header>
            <template v-if="item">
              <i class="far fa-images" /> #{{ item.id }} {{ item.name }}
            </template>
            <b-spinner
              v-else
              small
            />
            <b-button
              class="pull-right"
              size="sm"
              variant="primary"
              style="margin-bottom: -0.25rem;"
              @click.prevent="changeActiveForm('filterForm')"
            >
              <i class="fas fa-filter" /> {{ $t('global.filters') }}
            </b-button>
          </template>
          <b-card-text>
            <b-row
              v-if="item"
              class="mb-3"
            >
              <b-col class="text-left">
                <table class="classes-models-table">
                  <tr>
                    <td>
                      <div>
                        {{ $dt('product_types.included_in_class_lists', 'Included in class lists') }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <div
                          v-for="class_list in item.class_lists"
                          :key="class_list.id"
                        >
                          <navigation-badge
                            :to="{ name: 'ClassListsEdit', params: { id: class_list.id } }"
                            variant="badge-teal"
                          >
                            {{ class_list.name }}
                          </navigation-badge>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        {{ $dt('product_types.included_in_models', 'Included in models') }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex">
                        <div
                          v-for="model in item.models"
                          :key="model.id"
                        >
                          <navigation-badge
                            :to="{ name: 'ModelsEdit', params: { id: model.id } }"
                            variant="badge-blue"
                          >
                            {{ model.name }}
                          </navigation-badge>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col class="text-right">
                <b-button-group size="sm">
                  <b-button
                    variant="info"
                    :disabled="multiEdit.length === 0"
                    @click.prevent="multiEditImages"
                  >
                    <i class="fa icon-pencil" /> {{ $t('product_types.change_product_type') }} ({{ multiEdit.length }})
                  </b-button>
                  <b-button
                    v-if="multiEdit.length > 0"
                    variant="warning"
                    @click.prevent="multiEdit = []"
                  >
                    <i class="fas fa-eraser" /> {{ $t('global.clear') }}
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <b-row
              v-if="item"
              class="mx-0"
            >
              <template v-if="segmentedImages.list.data?.length > 0">
                <b-col
                  v-for="(image, index) in segmentedImages.list.data"
                  :key="`segmented-image-${index}`"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                  class="p-0 mb-2 border-white border bg-black"
                >
                  <div
                    :class="{ 'train-accepted': image.usage === 1, 'testing-accepted': image.usage === 2, 'bg-secondary-light': !isSelected(image), 'bg-info': isSelected(image) }"
                    class="border-bottom border-white custom-border-top p-1 d-flex justify-content-between"
                  >
                    <div
                      v-if="image.usage === 1"
                      class="usage-status-tag green"
                    >
                      {{ $dt('product_types.use_in_training', 'Use in training') }}
                    </div>
                    <div
                      v-else-if="image.usage === 2"
                      class="usage-status-tag purple"
                    >
                      {{ $dt('product_types.use_in_testing', 'Use in testing') }}
                    </div>
                    <div
                      v-else
                      class="usage-status-tag gray"
                    >
                      {{ $dt('product_types.do_not_use', 'Do not use') }}
                    </div>
                    <b-input-group
                      class="w-auto"
                      size="sm"
                    >
                      <b-input-group-prepend is-text>
                        <input
                          v-model="multiEdit"
                          type="checkbox"
                          :value="image"
                        >
                      </b-input-group-prepend>
                      <b-input-group-append>
                        <b-button
                          variant="warning"
                          @click.prevent="deleteImage(image)"
                        >
                          {{ $t('global.delete') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div
                    class="d-flex justify-content-center align-items-center segmented-image with-action"
                    @click.prevent="editImage(image)"
                  >
                    <b-img-lazy
                      v-if="image.image_data"
                      :src="image.image_data"
                    />
                    <template v-else>
                      <div class="text-white text-center text-monospace text-image-notfound">
                        <i class="far fa-times-circle" /><br>
                        {{ $t('global.image_not_found') }}
                      </div>
                    </template>
                  </div>
                </b-col>
              </template>
              <template v-else>
                <b-col>
                  <div
                    v-if="segmentedImages.loading"
                    class="text-center"
                  >
                    <b-spinner small />
                  </div>
                  <b-alert
                    v-else
                    class="mb-0"
                    variant="info"
                    show
                  >
                    {{ $t('product_types.segmented_images_list_is_empty') }}
                  </b-alert>
                </b-col>
              </template>
            </b-row>
            <b-row v-if="segmentedImages.list.data?.length > 0">
              <b-col>
                <b-pagination
                  v-model="filter.currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="mt-3"
                >
                  <template #page="{ page, active }">
                    <template v-if="segmentedImages.loading && active">
                      <b-spinner small />
                    </template>
                    <template v-else>
                      {{ page }}
                    </template>
                  </template>
                </b-pagination>
              </b-col>
              <b-col class="text-right">
                <b-button-group
                  size="sm"
                  class="mt-3"
                >
                  <b-button
                    variant="info"
                    :disabled="multiEdit.length === 0"
                    @click.prevent="multiEditImages"
                  >
                    <i class="fa icon-pencil" /> {{ $t('product_types.change_product_type') }} ({{ multiEdit.length }})
                  </b-button>
                  <b-button
                    v-if="multiEdit.length > 0"
                    variant="warning"
                    @click.prevent="multiEdit = []"
                  >
                    <i class="fas fa-eraser" /> {{ $t('global.clear') }}
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        v-if="isActiveForm"
        ref="activeForm"
        sm="6"
        md="4"
        lg="3"
      >
        <filter-form
          :human-corrected="filter.humanCorrected"
          :usage="filter.usage"
          :confidence="filter.confidence"
          :created-at="filter.createdAt"
          :doesnt-have-human-products="filter.doesntHaveHumanProducts"
          @input="setFilters"
          @save="changeActiveForm()"
          @cancel="changeActiveForm()"
          @clear="changeActiveForm()"
        />
      </b-col>
    </b-row>
    <b-modal
      id="segmented-image-edit"
      ref="segmented-image-edit"
      :title="edit ? `#${edit.id}` : `-`"
      @hidden="resetModal"
      @ok="updateSegmentedImage"
    >
      <template v-if="edit">
        <div class="mb-1">
          <div class="btn-group">
            <span class="align-middle m-1">
              {{ $dt('product_types.tray_image_id_modal_title', 'Tray ID:') }}
            </span>
            <b-button
              class="btn btn-primary"
              style="min-width: 4rem"
              :to="{ name: 'TraysPlotter', params: { id: edit.tray_image_id } }"
              size="sm"
              title="Go to plotter"
            >
              {{ edit.tray_image_id }}
            </b-button>
            <b-button
              variant="outline"
              class="blue-border"
              size="sm"
              title="Copy"
              @click="copyToClipboard(edit.tray_image_id)"
            >
              <i class="fa fa-copy" />
            </b-button>
          </div>
          <div
            v-if="lastModifiedUser"
            class="float-right m-1"
          >
            {{ $t('global.last_modified_by') }}: {{ lastModifiedUser.name }}
          </div>
        </div>
      </template>
      <div class="bg-black d-flex justify-content-center align-items-center segmented-image mb-2">
        <b-img-lazy
          v-if="edit && edit.image_data"
          :src="edit.image_data"
        />
        <template v-else>
          <div class="text-white text-center text-monospace text-image-notfound">
            <i class="far fa-times-circle" /><br>
            {{ $t('global.image_not_found') }}
          </div>
        </template>
      </div>
      <form>
        <b-button
          variant="secondary"
          class="mb-3"
          @click.prevent="reloadImage"
        >
          {{ $dt('segmented_images.reload', 'Reload image') }}
        </b-button>
        <b-form-group :label="$dt('product_types.product_type', 'Product type')">
          <b-select-2
            v-model="selected.productType"
            :options="autocompleteProductTypes"
            dropdown-parent="#segmented-image-edit"
          />
        </b-form-group>
        <b-form-group :label="$dt('product_types.selection_for_usage', 'Selection for usage')">
          <b-select-2
            v-model="selected.usage"
            :options="usageStatusOptions"
            dropdown-parent="#segmented-image-edit"
          />
        </b-form-group>
      </form>
      <template #modal-ok>
        {{ $t('global.ok') }}
      </template>
      <template #modal-cancel>
        {{ $t('global.cancel') }}
      </template>
    </b-modal>
    <b-modal
      id="segmented-images-edit"
      ref="segmented-images-edit"
      title="Multiple segmented image edit"
      @hidden="resetMultiModal"
      @ok="updateSegmentedImages"
    >
      <b-row class="mx-0 segmented-images-modal">
        <b-col
          v-for="(image, index) in multiEdit"
          :key="`multi-edit-image-${index}`"
          cols="6"
          md="4"
          xl="3"
          class="p-0 mb-2 border-white border bg-black"
        >
          <div
            class="mx-auto segmented-image as-background"
            :style="`background-image: url('${image.image_data}');`"
          >
            <!-- <b-img-lazy v-if="image.image_data" :src="image.image_data"></b-img-lazy> -->
            <template v-if="!image.image_data">
              <div class="text-white text-center text-monospace text-image-notfound">
                <i class="far fa-times-circle" /><br>
                {{ $t('global.image_not_found') }}
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <form>
        <b-button
          variant="secondary"
          @click.prevent="multiReloadImage"
        >
          {{ $dt('segmented_images.reload_selected_images', 'Reload images') }}
        </b-button>
        <b-button
          variant="warning"
          @click.prevent="multiDeleteImage"
        >
          {{ $t('global.delete') }}
        </b-button>
        <b-form-group :label="$t('product_types.product_type')">
          <b-select-2
            v-model="selected.productType"
            :options="autocompleteProductTypes"
            dropdown-parent="#segmented-images-edit"
          />
        </b-form-group>
        <b-form-group :label="$dt('product_types.selection_for_usage', 'Selection for usage')">
          <b-select-2
            v-model="selected.usage"
            :options="usageStatusOptions"
            dropdown-parent="#segmented-images-edit"
          />
        </b-form-group>
      </form>
      <template #modal-ok>
        {{ $t('global.ok') }}
      </template>
      <template #modal-cancel>
        {{ $t('global.cancel') }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import FilterForm from './components/FilterForm';
import NavigationBadge from '@/components/NavigationBadge';
import ProductTypes from '@/services/product-types-service';
import SegmentedImages from '@/services/segmented-images-service';

export default {
  name: 'ProductTypesView',
  components: { FilterForm, NavigationBadge },
  props: {
    id: {
      required: true,
    },
    currentPage: {
      default: '1',
    },
    humanCorrected: {
      default: '',
    },
    trainAccepted: {
      default: '',
    },
    confidence: {
      default: '',
    },
    createdAt: {
      default: '',
    },
    doesntHaveHumanProducts: {
      default: '',
    },
    usage: {
      default: '',
    },
  },
  data() {
    return {
      item: null,
      edit: null,
      multiEdit: [],
      productTypeId: Number(this.id),
      selected: {
        productType: '',
        usage: 0,
      },
      autocomplete: {
        productTypes: [],
      },
      segmentedImages: {
        loading: false,
        list: {},
      },
      activeForm: null,
      filter: this.parseQuery(),
      usageStatusOptions: [
        { id: 0, text: this.$dt('product_types.do_not_use', 'Do not use') },
        { id: 1, text: this.$dt('product_types.use_in_training', 'Use in training') },
        { id: 2, text: this.$dt('product_types.use_in_testing', 'Use in testing') },
      ],
    };
  },
  computed: {
    autocompleteProductTypes() {
      if (this.autocomplete.productTypes.length === 0) {
        return [];
      }

      const response = Object.assign([], this.autocomplete.productTypes);
      response.unshift({ id: '', text: '' });

      return response;
    },
    showFilterForm() {
      return this.activeForm === 'filterForm';
    },
    isActiveForm() {
      return this.showFilterForm;
    },
    lastModifiedUser() {
      return this.edit?.tray?.last_modified_user;
    },
    totalRows() {
      // Workaround for b-pagination resetting the currentPage to 
      // 1 when no total count/page size is provided
      if (!this.segmentedImages.list.total) {
        return this.currentPage;
      }

      return this.segmentedImages.list.total;
    },
    perPage() {
      // See above hack
      if (!this.segmentedImages.list.per_page) {
        return 1;
      }

      return this.segmentedImages.list.per_page;
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.$router.push({ query: this.getQuery() }).catch(() => { });
      },
      deep: true,
    },
    '$route.query'() {
      this.filter = this.parseQuery();
      this.loadSegmentedImages();
    },
  },
  mounted() {
    this.$router.replace({ query: this.getQuery() }).catch(() => { });
    this.loadItem(() => {
      this.loadSegmentedImages();
      this.loadProductTypes();
      this.loadClassGroups();
      this.loadRelatedModels();
    });
  },
  methods: {
    getQuery() {
      // Skip serializing null fields
      let query = {};
      for (const key in this.filter) {
        if (this.filter[key] !== null) {
          query[key] = this.filter[key];
        }
      }
      return query;
    },
    parseQuery() {
      return {
        currentPage: Number(this.currentPage),
        humanCorrected: this.humanCorrected ? Number(this.humanCorrected) : null,
        usage: this.usage ? Number(this.usage) : null,
        confidence: this.confidence instanceof Array ? this.confidence.map(Number) : null,
        createdAt: this.createdAt instanceof Array ? this.createdAt : null,
        doesntHaveHumanProducts: this.doesntHaveHumanProducts ? this.doesntHaveHumanProducts === 'true' : null,
      };
    },
    loadItem(callback = null) {
      ProductTypes.get(this.productTypeId)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        })
        .then(() => {
          if (callback && typeof callback === 'function') {
            callback();
          }
        });
    },
    reloadImage() {
      SegmentedImages.reloadImage(this.edit.id)
        .then((_) => {
          this.$notify({
            type: 'success',
            title: this.$t('global.success'),
            duration: 1000,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    multiReloadImage() {
      const ids = this.multiEdit.flatMap(item => item.id);
      SegmentedImages.reloadImageMulti(ids)
        .then((_) => {
          this.$notify({
            type: 'success',
            title: this.$t('global.success'),
            duration: 1000,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    loadProductTypes() {
      ProductTypes.listAll()
        .then((response) => {
          this.autocomplete.productTypes = response.data.map((item) => {
            return { text: item.name, id: item.id };
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    loadSegmentedImages() {
      this.segmentedImages.loading = true;

      ProductTypes.segmentedImages(this.productTypeId, this.filter)
        .then((response) => {
          this.segmentedImages.list = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        })
        .then(() => {
          this.segmentedImages.loading = false;
        });
    },
    loadClassGroups() {
      ProductTypes.classGroups(this.productTypeId)
        .then((response) => {
          if (this.item) this.item.class_lists = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    loadRelatedModels() {
      ProductTypes.relatedModels(this.productTypeId)
        .then((response) => {
          this.item.models = response.data;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    editImage(item) {
      this.resetModal();
      this.edit = Object.assign({}, item);
      this.selected.productType = item.product_type_id;
      this.selected.usage = item.usage;
      this.$refs['segmented-image-edit'].show();
    },
    deleteImage(item) {
      if (confirm(this.$t('product_types.image_delete_confirm_text'))) {
        const data = Object.assign({}, item);
        data.product_type_id = null;
        
        SegmentedImages.update(data)
          .then(() => {
            this.loadSegmentedImages();
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('global.error'),
              text: `${error}`,
              duration: -1,
            });
          });
      }
    },
    multiDeleteImage() {
      if (confirm(this.$dt('product_types.image_delete_confirm_text_multi', 'Delete selected images?'))) {
        const data = {
          segmented_images: this.multiEdit.flatMap(item => item.id),
          product_type_id: null,
        };

        SegmentedImages.updateMulti(data)
          .then(() => {
            this.loadSegmentedImages();
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('global.error'),
              text: `${error}`,
              duration: -1,
            });
          });
      }
    },
    onTrainAccepted(value, item) {
      const data = {
        id: item.id,
        train_accepted: value ? 1 : 0,
      };

      SegmentedImages.updateTrainAccepted(data)
        .then((response) => {
          if (this.segmentedImages.list.data) {
            const imageIndex = this.segmentedImages.list.data.findIndex((image) => {
              return image.id === item.id;
            });
            Object.assign(this.segmentedImages.list.data[imageIndex], response.data);
          }
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    resetModal() {
      this.edit = null;
      this.selected.productType = '';
    },
    updateSegmentedImage() {
      const data = Object.assign({}, this.edit);
      data.product_type_id = this.selected.productType ? this.selected.productType : null;
      data.usage = this.selected.usage ? this.selected.usage : this.usageStatusOptions[0].value;
      
      SegmentedImages.update(data)
        .then(() => {
          this.loadSegmentedImages();
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    resetMultiModal() {
      this.selected.productType = '';
    },
    updateSegmentedImages() {
      const data = {
        product_type_id: this.selected.productType ? this.selected.productType : null,
        usage: this.selected.usage ? Number(this.selected.usage) : 0,
        segmented_images: this.multiEdit.flatMap(item => item.id),
      };

      SegmentedImages.updateMulti(data)
        .then(() => {
          this.multiEdit = [];
          this.loadSegmentedImages();
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: `${error}`,
            duration: -1,
          });
        });
    },
    setFilters(filters) {
      console.log(filters);
      this.filter = Object.assign({}, this.filter, filters);
    },
    changeActiveForm(formName = null) {
      this.activeForm = formName;

      if (formName) {
        this.$nextTick(() => {
          this.$refs.activeForm.scrollIntoView({ behavior: 'smooth', alignToTop: true });
        });
      }
    },
    multiEditImages() {
      this.resetMultiModal();
      this.selected.productType = this.item.id;
      this.$refs['segmented-images-edit'].show();
    },
    isSelected(item) {
      const selected = this.multiEdit.findIndex(i => i.id === item.id);
      return selected !== -1;
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
}

.bg-secondary-light {
  background-color: #c2cfd6 !important;
}

.custom-border-top {
  border-top: 3px solid #838383 !important;

  &.train-accepted {
    border-top: 3px solid #4dbd74 !important;
  }

  &.testing-accepted {
    border-top: 3px solid #9b59b6 !important;
  }
}

.segmented-image {
  min-height: 224px;
  min-width: 224px;

  &.with-action {
    cursor: pointer !important;
  }
}

.segmented-images-modal {
  .segmented-image {
    max-height: 100px !important;
    max-width: 100px !important;
    min-height: 100px !important;
    min-width: 100px !important;

    &.as-background {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .text-image-notfound i {
    font-size: 38px !important;
    margin-bottom: 0;
    margin-top: 0.725rem;
  }
}

.text-image-notfound i {
  font-size: 130px;
  margin-bottom: 1rem;
}

.custom-control-size ::v-deep .custom-control-label {
  font-size: 0.875rem !important;
  padding-top: 4px;
}

.no-pointer {
  cursor: default;
}

.no-opacity {
  opacity: 1;
}

.blue-border {
  border-color: #003C7E;
}

.usage-status-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  &.gray {
    background-color: #838383;
    color: #fff;
  }

  &.green {
    background-color: #4dbd74;
    color: #fff;
  }

  &.purple {
    background-color: #9b59b6;
    color: #fff;
  }
}

.classes-models-table {
  border-collapse: collapse;

  td {
    border: 1px solid #c2cfd6;
    padding: 0.2rem 1rem;;
  }
}
</style>