<template>
  <div
    v-if="list.length > 0"
    class="card"
  >
    <div class="card-header">
      <i class="fa fa-align-justify" /> {{ $t('models.trainings') }}
    </div>
    <div class="card-block">
      <div class="table-responsive">
        <table class="table table-striped table-condensed mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('models.model_name') }}</th>
              <th>{{ $t('models.training_name') }}</th>
              <th>{{ $t('global.status') }}</th>
              <th>{{ $t('global.progress') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in list"
              :key="`models-training-table-${item.id}`"
            >
              <td>{{ item.id }}</td>
              <td>
                <b-link :to="{ name: 'ModelsEdit' , params: { id: item.model_cpp.id } }">
                  {{ item.model_cpp.name }}
                </b-link>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ getStatus(item) }}</td>
              <!-- Backwards compatibility with old training config format -->
              <td>{{ item.progress }} / {{ item.config?.number_of_epochs || item.config?.numberOfEpochs }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModelTrainingService from '@/services/model-training-service';

export default {
  name: 'ModelsTrainingTable',
  data () {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters(['can']),
  },
  mounted () {
    this.load();
  },
  methods: {
    load () {
      ModelTrainingService.running()
        .then((response) => {
          this.list = response.data;
        })
        .catch((error) => {
          console.error('error:', error.response.data);
        });
    },
    getStatus (item) {
      return item.status === 'queue' ? 'waiting' : item.status;
    },
    updateItem (id, val) {
      const itemIndex = this.list.findIndex((item) => {
        return item.id == id;
      });
      if (itemIndex !== -1) {
        const item = Object.assign({}, this.list[itemIndex], val);
        this.$set(this.list, itemIndex, item);
      }
      if (['ready', 'failed'].includes(val.status)) {
        this.load();
      }
    },
  },
};
</script>

<style scoped>

</style>