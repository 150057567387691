import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/themes?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/themes/${id}`);
  },
  store (data) {
    return Api().post('/themes', data);
  },
  update (data) {
    return Api().put(`/themes/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/themes/${id}`);
  },
};
