<template>
  <div class="cash-desk-content">
    <h4 class="cash-desk-name">
      {{ cashDeskNumber }}
    </h4>
    <div class="fill">
      <img src="@/static/img/tools.svg">
      <p>{{ $t('cashier.grid.cashDeskCard.outOfService.title') }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name : 'OutOfService', 
  props: {
    cashDeskNumber: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .fill {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .cash-desk-content {
    border-radius: 10px;
    background: #DEDEDE;
  }

  p {
    color: #777777;
    font-size: 20px;
    margin: 0;
  }
</style>
