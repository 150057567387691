<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> Edit </span>
      <span v-else><i class="fa icon-pencil" /> Add new </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        label="human_product_id"
        :state="!errors.human_product_id"
        :invalid-feedback="errors.human_product_id"
      >
        <b-form-input
          v-model="modelData.human_product_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="human_corrected_id"
        :state="!errors.human_corrected_id"
        :invalid-feedback="errors.human_corrected_id"
      >
        <b-form-input
          v-model="modelData.human_corrected_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="segmented_image_id"
        :state="!errors.segmented_image_id"
        :invalid-feedback="errors.segmented_image_id"
      >
        <b-form-input
          v-model="modelData.segmented_image_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="tray_image_id"
        :state="!errors.tray_image_id"
        :invalid-feedback="errors.tray_image_id"
      >
        <b-form-input
          v-model="modelData.tray_image_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="alt_products"
        :state="!errors.alt_products"
        :invalid-feedback="errors.alt_products"
      >
        <b-form-input
          v-model="modelData.alt_products"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="human_corrected"
        :state="!errors.human_corrected"
        :invalid-feedback="errors.human_corrected"
      >
        <b-form-input
          v-model="modelData.human_corrected"
          type="text"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          Cancel
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          Update
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          Save
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TrayProductsForm',

  props: {
    model: {
      type    : Object,
      required: false,
      default () {
        return { enabled: false };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      modelData: _.cloneDeep(this.model), 
      errors: {}, 
    };
  },
  computed: {
    ...mapState(['tags']),
    isEditing () {
      return typeof this.modelData.id !== 'undefined' && this.modelData.id > 0;
    },
  },
  watch: {
    model: function () {
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.TRAY_PRODUCTS_ADD,
      update: VuexTypes.TRAY_PRODUCTS_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      const action = this.isEditing ? this.update(this.modelData) : this.add(this.modelData);

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>
<style>
</style>
