import Api from '@/services/api';

export default {
  list(page) {
    return Api().get(`/languages/?page=${page}`);
  },
  classifier() {
    return Api().get('/languages/list/');
  },
  createData() {
    return Api().get('/languages/create');
  },
  editData(id) {
    return Api().get(`/languages/${id}/edit`);
  },
  create(data) {
    return Api().post('/languages/', data);
  },
  update(data) {
    return Api().put(`/languages/${data.id}`, data);
  },
  delete(id) {
    return Api().delete(`/languages/${id}`);
  },
  importTranslations(id, data) {
    return Api().patch(`/languages/${id}/translations/import`, data);
  },
  exportTranslations(id) {
    return Api().get(`/languages/${id}/translations/export`);
  },
};
