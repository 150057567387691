<template>
  <div
    class="cash-desk"
    @click="$emit('click')"
  >
    <out-of-service 
      v-if="!raspberry.connection"
      :cash-desk-number="cashDeskNumber"
    />
    <ready-for-use
      v-else-if="!ordersByRaspberry[raspberry.id]"
      :cash-desk-number="cashDeskNumber"
    />
    <occupied
      v-else-if="ordersByRaspberry[raspberry.id]"
      :raspberry-id="raspberry.id"
      :cash-desk-number="cashDeskNumber"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import OutOfService from './DeskCards/OutOfService.vue';
import ReadyForUse from './DeskCards/ReadyForUse.vue';
import Occupied from './DeskCards/Occupied.vue';

export default {
  name      : 'CashierGridDeskCard',
  components: {
    OutOfService,
    ReadyForUse,
    Occupied,
  },
  props: {
    raspberry: {
      type: Object,
      required: true,
    },
    cashDeskNumber: {
      type: Number,
      required: true,
    },
  },
  
  computed: {
    ...mapState('cashierRaspberries', {
      rasberryList: 'list',
      loading: 'loading',
    }),
    ...mapState('cashierOrders', {
      ordersByRaspberry: 'byRaspberry',
    }),
  },
};
</script>

<style lang="scss" scoped>
  .cash-desk {
    border-radius: 10px;
    background: #FFFFFF;
    height: 180px;
  }
  
</style>
<style lang="scss">
  .cashier-main {
    .desk-card {
      position: relative;
    }

    .cash-desk-content {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .cash-desk-name {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 52px;
      font-weight: 800;
      font-size: 52px;
      color: #777777;

      &.light {
        color: white;
        opacity: 0.7;
      }
    }
  }
</style>
