import Api from '@/services/api';

export default {
  list(params) {
    return Api().post('/translations/list/', params);
  },
  untranslated() {
    return Api().get('/translations/untranslated_cnt');
  },
  createData() {
    return Api().get('/translations/create');
  },
  editData(id) {
    return Api().get(`/translations/${id}/edit`);
  },
  create(data) {
    return Api().post('/translations/', data);
  },
  update(data, config) {
    return Api().put('/translations/', data, config);
  },
  delete(id) {
    return Api().delete(`/translations/${id}`);
  },
};
