import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/tray_products?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/tray_products/${id}`);
  },
  store (data) {
    return Api().post('/tray_products', data);
  },
  cashDeskPush (data) {
    return Api().post('/tray_products/cash_desk_push', data);
  },
  cashDeskCorrection (data) {
    return Api().post('/tray_products/cash_desk_correction', data);
  },
  update (data) {
    return Api().put(`/tray_products/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/tray_products/${id}`);
  },
  softDeleteByProductId (id) {
    return Api().delete(`/tray_products/product_id/${id}`);
  },
  getAltProducts (id) {
    return Api().get(`/tray_products/alt_products/${id}`);
  },
  softDeleteById (id) {
    return Api().delete(`/tray_products/remove/${id}`);
  },
  softDeleteByHumanProductId (humanProductId, trayId) {
    return Api().delete(`/tray_products/human_product/${humanProductId}/tray/${trayId}`);
  },
  /**
   * 
   * @param {Number} trayProductId 
   * @param {Number} humanProductId 
   * @returns {Promise}
   */
  correctHumanProduct(trayProductId, humanProductId) {
    return Api().put(`/cashier/tray_products/${trayProductId}/correct_human_product`, {
      'human_product_id': humanProductId,
    });
  },
  /**
   * 
   * @param {Number} trayProductId 
   * @returns {Promise}
   */
  cashierDelete (trayProductId) {
    return Api().delete(`/cashier/tray_products/${trayProductId}`);
  },
};
