<template>
  <b-modal
    id="lock-modal"
    v-model="showModal"
    @change="toggle"
  >
    <div class="img-from-to">
      <div>
        <img
          v-if="getStoreHumanProduct(current_product?.id)?.image"
          :src="getStoreHumanProduct(current_product?.id)?.image"
          alt="Old product icon"
        >
        <img
          v-else
          src="@/static/img/human_product_placeholder.jpg"
          alt="Old product icon"
        >
      </div>
      
      <strong class="m-2">
        >
      </strong>
      <div>
        <img
          v-if="newProduct?.image"
          :src="newProduct.image"
          alt="New product icon"
        >
        <img
          v-else 
          src="@/static/img/human_product_placeholder.jpg"
          alt="New product icon"
        >
      </div>
    </div>
    <p class="modal-title">
      {{ $t('cashier.confirmHumanProductChange.title') }}
    </p>
    <p class="modal-text">
      {{ $t('cashier.confirmHumanProductChange.text1') }}
      <b>{{ getProductName(current_product) }}</b>
      {{ $t('cashier.confirmHumanProductChange.text2') }}
      <b>{{ getProductName(newProduct) }}</b>
      {{ $t('cashier.confirmHumanProductChange.text3') }}
    </p>

    <template #modal-footer="{ cancel }">
      <b-button
        class="confirm-lock-btn-cancel"
        @click="cancel()"
      >
        {{ $t('global.cancel') }}
      </b-button>  
      <b-button
        class="confirm-lock-btn-ok"
        @click="confirm"
      >
        {{ $t('cashier.confirmHumanProductChange.btn.edit') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { getProductName } from '@/helpers/tray';
import { mapGetters, mapActions } from 'vuex';

export default {
  name      : 'ModalConfirmHumanProductChange',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    oldProduct: {
      validator: (oldProduct) => {
        return typeof oldProduct === 'object' || oldProduct === null;
      },
      required: true,
    },
    newProduct: {
      validator: (newProduct) => typeof newProduct === 'object' || newProduct === null,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      getProductName,
    };
  },
  computed:{
    ...mapGetters('cashierHumanProducts', {
      getStoreHumanProduct: 'findById',
    }),
    current_product(){
      return this.oldProduct?.human_product_corrected ? this.oldProduct.human_product_corrected : this.oldProduct?.human_product;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        this.showModal = show;
      },
    },
  },
  methods: {
    toggle() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
  .img-from-to {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
</style>
