<template>
  <b-card
    class="bg-light-blue"
    header-tag="header"
  >
    <div slot="header">
      <i class="fas fa-filter" /> {{ $t('global.filters') }}
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click.prevent="onCancel"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <b-form-group :label="$t('product_types.human_corrected')">
      <b-form-select v-model="filters.humanCorrected">
        <b-form-select-option value="" />
        <b-form-select-option value="1">
          {{ $t('global.corrected') }}
        </b-form-select-option>
        <b-form-select-option value="0">
          {{ $t('global.non_corrected') }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group :label="$t('product_types.train_accepted')">
      <b-form-select v-model="filters.usage">
        <b-form-select-option value="" />
        <b-form-select-option value="0">
          {{ $dt('product_types.do_not_use', 'Don not use') }}
        </b-form-select-option>
        <b-form-select-option value="1">
          {{ $dt('product_types.use_in_training', 'Use in training') }}
        </b-form-select-option>
        <b-form-select-option value="2">
          {{ $dt('product_types.use_in_testing', 'Use in testing') }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <b-form-group :label="$t('product_types.confidence')">
      <b-vue-slider
        v-model="filters.confidence"
        :min="0"
        :max="1"
        :interval="0.01"
        :contained="true"
      />
      <b-input-group class="mt-1">
        <b-form-input
          v-model.number="filters.confidence[0]"
          type="number"
        />
        <b-input-group-text>
          -
        </b-input-group-text>
        <b-form-input
          v-model.number="filters.confidence[1]"
          type="number"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group :label="$t('product_types.create_date')">
      <date-picker
        v-model="filters.created_at"
        class="w-100"
        type="datetime"
        range
        :placeholder="$t('global.datetime.select_datetime_range')"
      />
    </b-form-group>
    <b-form-checkbox
      v-model="filters.doesntHaveHumanProducts"
      switch
    >
      {{ $t('product_types.without_human_products') }}
    </b-form-checkbox>
    <div class="mt-3">
      <b-btn
        variant="outline-danger"
        @click.prevent="onCancel"
      >
        {{ $t('global.cancel') }}
      </b-btn>
      <b-button-group class="pull-right">
        <b-btn
          variant="success"
          @click.prevent="onApply"
        >
          {{ $t('global.apply') }}
        </b-btn>
      </b-button-group>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'FilterForm',
  props: {
    humanCorrected: {
      default: null,
    },
    usage: {
      default: null,
    },
    confidence: {
      default: null,
    },
    createdAt: {
      default: null,
    },
    doesntHaveHumanProducts: {
      default: null,
    },
  },
  data() {
    return {
      filters: {
        humanCorrected: this.humanCorrected ? Number(this.humanCorrected) : null,
        usage: this.usage ? Number(this.usage) : null,
        confidence: this.confidence instanceof Array ? this.confidence.map(Number) : [0, 1],
        createdAt: this.createdAt,
        doesntHaveHumanProducts: this.doesntHaveHumanProducts ? Boolean(this.doesntHaveHumanProducts) : null,
      },
    };
  },
  mounted() {
  },
  methods: {
    onApply() {
      this.$emit('input', this.filters);
      this.$emit('save');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped></style>
