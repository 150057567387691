import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/segmentation_model?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/segmentation_model/${id}`);
  },
  store (data) {
    return Api().post('/segmentation_model', data);
  },
  update (data) {
    return Api().put(`/segmentation_model/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/segmentation_model/${id}`);
  },
};
