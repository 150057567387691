<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('global.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="role.name"
          type="text"
        />
      </b-form-group>
      <b-form-group :label="$t('roles.permissions')">
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="permissions"
          :options="users.permissions"
          name="permissions"
          value-field="id"
          text-field="name"
          stacked
        />
      </b-form-group>

      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RoleForm',

  props: {
    model: {
      type    : Object,
      required: true,
      default () {
        return { permissions: [] };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      role: _.cloneDeep(this.model),
      errors     : {},
      checked    : [],
      permissions: [],
    };
  },
  computed: {
    ...mapState(['users']),
    isEditing () {
      return typeof this.role.id !== 'undefined' && this.role.id > 0;
    },
  },
  watch: {
    role: function () {
      this.errors = {};
      this.permissions = (typeof this.role.permissions !== 'undefined') ? this.role.permissions.map((x) => x.id) : [];
    },
  },
  mounted () {
    this.listPermissions();
  },
  methods: {
    ...mapActions({
      update         : VuexTypes.ROLES_UPDATE,
      add            : VuexTypes.ROLES_ADD,
      listPermissions: VuexTypes.LIST_PERMISSIONS,
    }),
    closeClick () {
      this.errors = {};
      this.$emit('saved', false);
    },
    save () {
      const action = this.isEditing ? this.update(this.role) : this.add(this.role);
      this.role.permissions = this.permissions.map((x) => this.users.permissions.find((y) => y.id === x));

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>
<style>
</style>
