<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('product_types.header') }}
            <b-btn
              class="pull-right ml-2 mt-1"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
            <b-input-group class="pull-right custom-select2">
              <template #prepend>
                <b-input-group-text><i class="fas fa-search" /></b-input-group-text>
              </template>
              <b-form-input v-model.trim="search.byName" />
              <b-select-2
                v-model="filter.searchByHumanProduct"
                :options="optionsHumanProducts"
                :placeholder="$t('product_types.human_product')"
              />
              <b-select-2
                v-model="filter.classListStatus"
                :options="optionsClassListStatus"
                :placeholder="$dt('product_types.class_list_status', 'Class list status')"
              />
            </b-input-group>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('global.name') }}</th>
                    <th>{{ $t('global.description') }}</th>
                    <th>{{ $t('product_types.human_product') }}</th>
                    <th>{{ $t('product_types.num_segmented_images') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in productTypes.list.data"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                      <b-badge v-if="item.product">
                        {{ item.product.code }} {{ item.product.name }}
                      </b-badge>
                    </td>
                    <td class="text-center">
                      {{ item.segmented_images_train_count }} /
                      {{ item.segmented_images_test_count }} /
                      {{ item.segmented_images_unused_count }}
                      <b>({{ item.segmented_images_count }})</b>
                    </td>
                    <td>
                      <template v-if="can('super-admin', 'cvision-admin')">
                        <button
                          type="button"
                          class="btn btn-info btn-sm table-button-size"
                          @click="viewItem(item)"
                        >
                          <i class="far fa-images" /> {{ $t('global.view') }}
                        </button>
                      </template>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-success btn-sm table-button-size"
                        @click="editItem(item)"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteItem(item)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination and total count -->
            <b-row class="mt-4">
              <b-col
                cols="12"
                sm="6"
                class="m-0"
              >
                <b-pagination
                  v-model="filter.currentPage"
                  class="m-0"
                  :total-rows="totalRows"
                  :per-page="perPage"
                />
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex justify-content-end align-items-center"
              >
                <span class="text-muted text-capitalize mr-2">{{ $t('global.total') }}: {{ totalRows }}</span>
              </b-col>
            </b-row>

            <!-- Export as CSV -->
            <b-row class="mt-2">
              <b-col
                cols="12"
                class="d-flex justify-content-end align-items-center"
              >
                <b-btn
                  primary
                  class="btn-sm mr-2"
                  @click="downloadXlsx"
                >
                  {{ $t('global.export_as_xlsx') }}
                </b-btn>
                <b-btn
                  primary
                  class="btn-sm"
                  @click="downloadCsv"
                >
                  {{ $t('global.export_as_csv') }}
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <product-types-form
          v-model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import ProductTypesForm from './ProductTypesForm';
import ProductTypesService from '@/services/product-types-service';
import HumanProductsService from '@/services/human-products-service';

export default {
  name: 'ProductTypes',
  components: { ProductTypesForm },
  props: {
    currentPage: {
      type: String,
      default: '1',
    },
    searchByName: {
      type: String,
      default: null,
    },
    searchByHumanProduct: {
      type: String,
      default: null,
    },
    classListStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filter: this.parseQuery(),
      // Duplicated fields for debounce
      search: {
        byName: this.searchByName,
      },
      addItem: {},
      debounce: null,
      showAddForm: false,
      allHumanProducts: [],
    };
  },
  computed: {
    ...mapState(['productTypes']),
    ...mapGetters(['can']),
    showForm() {
      return this.addItem.id || this.showAddForm;
    },
    optionsHumanProducts() {
      const array = this.allHumanProducts.map((a) => {
        return { text: `${a.name}`, id: a.id };
      });
      array.unshift({ id: -1, text: `- ${this.$t('product_types.without_human_product')} -` });
      array.unshift({ id: '', text: '' });
      return array;
    },
    optionsClassListStatus() {
      return [
        { id: 0, text: this.$dt('product_types.any', 'Any') },
        { id: 1, text: this.$dt('product_types.at_least_one', 'At least one') },
        { id: 2, text: this.$dt('product_types.none', 'None') },
      ];
    },
    totalRows() {
      // Workaround for b-pagination resetting the currentPage to 
      // 1 when no total count/page size is provided
      if (!this.productTypes.list.total) {
        return this.currentPage;
      }

      return this.productTypes.list.total;
    },
    perPage() {
      // See above hack
      if (!this.productTypes.list.per_page) {
        return 1;
      }

      return this.productTypes.list.per_page;
    },
  },
  watch: {
    roles: 'onRolesLoaded',
    search: {
      handler: function () {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.filter.searchByName = this.search.byName;
        }, 200);
      },
      deep: true,
    },
    filter: {
      handler: function () {
        this.$router.push({ query: this.getQuery() }).catch(() => { });
      },
      deep: true,
    },
    '$route.query'() {
      this.filter = this.parseQuery();
      this.loadList();
    },
  },
  mounted() {
    this.$router.replace({ query: this.getQuery() }).catch(() => { });
    this.loadList();
    this.loadHumanProducts();
  },
  methods: {
    ...mapActions({
      list: VuexTypes.PRODUCT_TYPES_LIST,
      delete: VuexTypes.PRODUCT_TYPES_DELETE,
    }),
    getQuery() {
      // Skip serializing null fields
      let query = {};
      for (const key in this.filter) {
        if (this.filter[key] !== null) {
          query[key] = this.filter[key];
        }
      }
      return query;
    },
    parseQuery() {
      return {
        currentPage: Number(this.currentPage),
        searchByName: this.searchByName,
        searchByHumanProduct: this.searchByHumanProduct,
        classListStatus: this.classListStatus,
      };
    },
    viewItem(item) {
      this.$router.push({ name: 'ProductTypesView', params: { id: item.id } });
    },
    editItem(item) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, item);
    },
    deleteItem(item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.addItem = {};
      }
    },
    formSaved() {
      this.showAddForm = false;
      this.addItem = {};
    },
    openAddForm() {
      this.showAddForm = true;
      this.addItem = {};
    },
    loadList() {
      this.list({
        currentPage: this.filter.currentPage,
        extraParams: {
          order: ['name', 'asc'],
          perPage: 30,
          search: this.filter.searchByName,
          filters: {
            humanProduct: this.filter.searchByHumanProduct,
            classListStatus: this.filter.classListStatus,
          },
        },
      });
    },
    loadHumanProducts() {
      HumanProductsService.listAll()
        .then((response) => {
          this.allHumanProducts = response.data;
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    async downloadXlsx() {
      const response = await ProductTypesService.exportXlsx();
      this.initDownload(response.data, 'products.xlsx');
    },
    async downloadCsv() {
      const response = await ProductTypesService.exportCsv();
      this.initDownload(response.data, 'products.csv');
    },
    initDownload(content, filename) {
      const url = window.URL.createObjectURL(content);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}

.custom-select2 ::v-deep {
  width: 35%;

  .form-control {
    height: auto !important;
  }

  .select2-container--bootstrap4,
  .select2-selection--multiple,
  .select2-selection__clear {
    margin-top: 0;
  }

  .select2-container {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin-bottom: 0;
    position: relative;
    width: auto !important;
  }
}

@media (max-width: 768px) {
  .custom-select2 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-select2 {
    width: 60%;
  }
}
</style>