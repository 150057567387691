import Api from '@/services/api';
import { makeQuery } from '@/helper';

export default {
  list (page, search, filters = {}) {
    const queryParams = {page, search};
    for (const [key, value] of Object.entries(filters)) {
      queryParams[key] = value.join(',');
    }

    return Api().get('/combo' + makeQuery(queryParams));
  },
  get (id) {
    return Api().get(`/combo/${id}`);
  },
  store (data) {
    return Api().post('/combo', data);
  },
  update (data) {
    return Api().put(`/combo/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/combo/${id}`);
  },
};
