<template>
  <div>
    <theme-settings
      :themes="getThemesList"
      :cash-desk-id="cashDeskId"
    />
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import ThemeSettings from './ThemeSettings.vue';
import { integer } from 'vuelidate/lib/validators';

export default {
  name: 'TechnicalSettings',
  components: {
    ThemeSettings,
  },
  props: {
    cashDeskId: {
      type: integer,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getThemesList']),
  },  
  mounted() {
    this.listThemes();
  },
  methods: {
    ...mapActions({
      listThemes: VuexTypes.THEMES_LIST,
      get: VuexTypes.CASH_DESK_LOAD,
    }),
  },
};
</script>
  
<style lang="scss" scoped>
.settings {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

</style>