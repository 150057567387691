<template>
  <footer class="app-footer">
    <span>
      &copy; {{ new Date().getFullYear() }} LIDO
    </span>
  </footer>
</template>
<script>
export default { name: 'CFooter' };
</script>
