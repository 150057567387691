<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="addItem.id ? '6' : '12'"
        :md="addItem.id ? '8' : '12'"
        :lg="addItem.id ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('raspberry.header') }}
          </div>
          <div class="card-block">
            <raspberry-table
              ref="raspberryTable"
              :actions="actions"
              :enabled-only="false"
              @edit="editItem"
              @delete="deleteItem"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-show="addItem.id"
        sm="6"
        md="4"
        lg="3"
      >
        <raspberry-form
          v-model="addItem"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RaspberryForm from './RaspberryForm';
import RaspberryTable from './components/RaspberryTable';

export default {
  name      : 'Raspberry',
  components: { RaspberryForm, RaspberryTable },
  data () {
    return {
      addItem: {},
      actions: {
        enabled: true,
        edit   : true,
        delete : true,
      },
    };
  },
  computed: { ...mapGetters(['can']) },
  watch   : { roles: 'onRolesLoaded' },
  mounted () {
  },
  methods: {
    editItem (item) {
      this.addItem = Object.assign({}, item);
    },
    deleteItem () {
      this.addItem = {};
    },
    formSaved () {
      this.$refs.raspberryTable.updateList();
      this.addItem = {};
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
