<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('image.image_path')"
        :state="!errors.image_path"
        :invalid-feedback="errors.image_path"
      >
        <b-form-input
          v-model="image.image_path"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('image.proc_status')"
        :state="!errors.processing_status"
        :invalid-feedback="errors.processing_status"
      >
        <b-form-input
          v-model="image.processing_status"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('image.learning_state')"
        :state="!errors.learning_state"
        :invalid-feedback="errors.learning_state"
      >
        <b-form-input
          v-model="image.learning_state"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('image.tray_image_id')"
        :state="!errors.tray_image_id"
        :invalid-feedback="errors.tray_image_id"
      >
        <b-form-input
          v-model="image.tray_image_id"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('image.product_type_id')"
        :state="!errors.product_type_id"
        :invalid-feedback="errors.product_type_id"
      >
        <b-form-input
          v-model="image.product_type_id"
          type="text"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name      : 'SegmentedImageForm',
  components: {},
  props     : {
    model: {
      type    : Object,
      required: false,
      default () {
        return { enabled: false };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      image: _.cloneDeep(this.model),
      errors           : {},
      tag              : '',
      autocompleteItems: [],
    };
  },
  computed: {
    ...mapState(['segmentedImages']),
    isEditing () {
      return typeof this.image.id !== 'undefined' && this.image.id > 0;
    },
  },
  watch: {
    image: function () {
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.image_ADD,
      update: VuexTypes.image_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      const action = this.isEditing ? this.update(this.image) : this.add(this.image);
      action
        .then((data) => {
          this.errors = {};
          this.$emit('saved', data.data.id);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
