<template>
  <div>
    <p
      class="table-header"
    >
      {{ $t('cashier.TrayProductsTable.productCount') + ' ' + tray.tray_products.length }}
    </p>
    <b-table
      :items="tray.tray_products"
      :fields="fields"
      thead-class="d-none"
    >
      <template #cell(index)="item">
        <div class="confidence">
          {{ item.index + 1 }}
        </div>
      </template>

      <template #cell(name)="item">
        <div class="name-text">
          <div :class="{corrected: item.item.human_product_corrected}">
            {{ getProductName(item.item.human_product) }}
          </div>
          {{ getProductName(item.item.human_product_corrected) }}
        </div>
        <div class="price-text">
          {{ getStoreHumanProduct(getHumanProduct(item.item)?.id)?.price | toPrice }}
        </div>
      </template>

      <template #cell(count)="item">
        <div
          v-if="!disabled"
          class="cell-count"
        >
          <button
            class="btn"
            @click="() => productMinus({ tray, human_product: item.item.human_product })"
          >
            <img src="@/static/img/minus.svg">
          </button>
          {{ item.item.count }}
          <button
            class="btn"
            @click="() => productPlus({ tray, human_product: item.item.human_product })"
          >
            <img src="@/static/img/plus.svg">
          </button>
        </div>
      </template>
      
      <template #cell(edit)="item">
        <div
          v-if="!disabled"
          class="cell-actions"
        >
          <button
            class="btn"
            @click="startTrayProductEdit(item.item)"
          >
            <img src="@/static/img/pen-blue.svg">
          </button>
          <button
            class="btn"
            @click="trayProductDelete(item.item)"
          >
            <img src="@/static/img/trash.svg">
          </button>
        </div>
      </template>
      <template #custom-foot>
        <tr>
          <td colspan="3">
            <button
              :disabled="!canEditOrderProducts"
              @click="chooseTrayProductToAdd()"
            >
              <img src="@/static/img/plus.svg">
            </button>
          </td>
        </tr>
      </template>
    </b-table>

    <!-- {{ { 'productSelect.correctedHumanProduct': productSelect.correctedHumanProduct } }} -->
    <modal-product-select
      v-model="productSelect.correctedHumanProduct"
      :show="productSelect.show"
      @close="trayProductSelectClose"
      @input="newHumanProductSelected"
      @productClick="newHumanProductSelected"
    />

    <modal-confirm-human-product-change
      :show="confirmHumanProductChange.show"
      :old-product="trayProductToEdit ? trayProductToEdit : null"
      :new-product="productSelect.correctedHumanProduct ? productSelect.correctedHumanProduct : null"
      @confirm="correctHumanProduct"
      @close="confirmHumanProductChangeClose"
    />

    <modal-confirm-tray-product-delete
      :show="confirmTrayProductDelete.show"
      :tray-product-id="confirmTrayProductDelete.trayProducIdToDelete"
      @close="() => confirmTrayProductDelete.show = false"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { VuexTypes } from '@/store/types';
import ModalProductSelect from '../Modals/ModalProductSelect.vue';
import ModalConfirmHumanProductChange from '../Modals/ModalConfirmHumanProductChange.vue';
import ModalConfirmTrayProductDelete from '../Modals/ModalConfirmTrayProductDelete.vue';
import TraysService from '@/services/trays-service';
import { getProductName, getHumanProduct } from '@/helpers/tray';
import trayProductsService from '@/services/tray-products-service';
import { canEditOrderProducts } from '@/rules/order-rules';

export default {
  name: 'TrayProductsTable',
  components: {
    ModalProductSelect,
    ModalConfirmHumanProductChange,
    ModalConfirmTrayProductDelete,
  },
  inject: ['getTrayOrder'],
  props: {
    'tray': {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'index', tdClass: 'w-1'},
        { key: 'name'},
        { key: 'edit', tdClass: 'w-1'},
        // { key: 'index', tdClass: 'w-1'},
        // 'name', 
        // { key: 'count', tdClass: 'w-1'}, 
        // { key: 'edit', tdClass: 'w-1'}
      ],
      trayProductToEdit: null,
      productSelect: {
        show: false,
        correctedHumanProduct: null,
      },
      confirmHumanProductChange: {
        show: false,
      },
      confirmTrayProductDelete: {
        show: false,
        trayProducIdToDelete: null,
      },
      addTrayProductInProgress: false,
      getProductName,
      getHumanProduct,
    };
  },
  computed:{
    ...mapGetters('cashierHumanProducts', {
      getStoreHumanProduct: 'findById',
    }),
    canEditOrderProducts() {
      return canEditOrderProducts(this.getTrayOrder());
    },
  },
  watch: {
    'tray.tray_products': {
      immediate: true,
      handler() {
        this.parseProducts();
      },
    },
  },
  mounted(){
  },
  methods: {
    ...mapActions('cashierTrays', {
      'productPlus': VuexTypes.CASHIER_TRAYS_ACTION_PRODUCT_PLUS,
      'productMinus': VuexTypes.CASHIER_TRAYS_ACTION_PRODUCT_MINUS,
      'setTrayProduct': VuexTypes.CASHIER_TRAYS_ACTION_SET_TRAY_PRODUCT,
    }),
    parseProducts() {
      this.items = [];
    },
    startTrayProductEdit(trayProduct) {
      this.productSelect.show = true;
      this.trayProductToEdit = trayProduct;
    },
    trayProductSelectClose() {
      this.productSelect.show = false;
      this.trayProductToEdit = null;
      this.productSelect.correctedHumanProduct = null;
    },
    newHumanProductSelected() {
      if (this.trayProductToEdit) {
        this.confirmHumanProductChange.show = true;
      } else {
        this.addTrayProduct();
      }
    },
    confirmHumanProductChangeClose() {
      this.confirmHumanProductChange.show = false;
    },
    correctHumanProduct() {
      (async () => {
        await trayProductsService.correctHumanProduct(this.trayProductToEdit.id, this.productSelect.correctedHumanProduct.id);
        this.resetProductSelect();
      })();
    },
    resetProductSelect() {
      this.confirmHumanProductChange.show = false;
      this.productSelect.show = false;
      this.productSelect.correctedHumanProduct = null;
    },
    trayProductDelete(trayProduct) {
      this.confirmTrayProductDelete.trayProducIdToDelete = trayProduct.id;
      this.confirmTrayProductDelete.show = true;
    },
    chooseTrayProductToAdd() {
      this.trayProductToEdit = null;
      this.productSelect.correctedHumanProduct = null;
      this.productSelect.show = true;
    },
    addTrayProduct() {
      if (!this.productSelect.correctedHumanProduct || this.addTrayProductInProgress) {
        return;
      }

      this.addTrayProductInProgress = true;
      TraysService.addProduct(this.tray.id, this.productSelect.correctedHumanProduct.id)
        .then((resp) => {
          this.productSelect.correctedHumanProduct = null;
          this.productSelect.show = false;
        })
        .finally(() => {
          this.addTrayProductInProgress = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .table-header{
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .cell-count, .cell-actions {
    display: flex;
    white-space: nowrap;
  }

  .cell-count {

    .btn {
      border: 2px solid #003C7E;
    }
  }

  .corrected {
    text-decoration: line-through;
  }

  .confidence {
    width: 4.25rem;
    height: 2.875rem;
    background: #759F01;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
  }
  .name-text{
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.563rem;
    margin-bottom: 0.313rem;
  }
  .price-text{
    height: 100%;
    font-weight: 800;
    font-size: 1.125rem;
    line-height: 1.563rem;
  }
  tfoot button {
    width: 100%;
    padding: 0.9rem 1rem;
    height: 3.4rem;
    color: #FFFFFF;
    border: none;
    background: #DEDEDE;
    transition: background 0.2s ease-out;
    border-radius: 8.75rem;

    &:hover {
      transition: background 0.2s ease-in;
      background: #d1d1d1;
    }

    &:disabled {
      cursor: not-allowed;
      transition: none;
      background: #e8e8e8;
      img {
        opacity: 0.3;
      }
    }
  }
</style>

<style lang="scss">
  .table td {
    padding-left: 0rem;
    padding-right: 1.25rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    vertical-align: middle;
  }

  .w-1 {
    width: 1%;
  }
</style>
