<template>
  <div class="table-responsive">
    <table class="table table-striped table-condensed" :class="{ 'table-bordered': place === 'addon' }">
      <thead :class="{ 'thead-light': place === 'addon' }">
        <tr>
          <th>#</th>
          <th>{{ $t('global.name') }}</th>
          <th>{{ $t('global.status') }}</th>
          <th>{{ $t('global.progress') }}</th>
          <th>{{ $t('global.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="`${item.id}-${item.status}`">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.status }}</td>
          <!-- Backwards compatibility with old training config format -->
          <td>{{ item.progress }} / {{ item.config?.number_of_epochs || item.config?.numberOfEpochs }}</td>
          <td>
            <b-button v-if="canEdit(item.status)" size="sm" variant="success" @click="$emit('edit', item)">
              {{ $t('global.edit') }}
            </b-button>
            <b-button v-if="canCancel(item)" size="sm" variant="info" @click="cancel(item)">
              {{ $t('global.cancel') }}
            </b-button>
            <b-button v-if="canDelete(item.status)" size="sm" variant="warning" @click="$emit('delete', item)">
              {{ $t('global.delete') }}
            </b-button>
            <b-button v-if="hasInfo(item)" size="sm" variant="secondary" @click="openTrainingInfo(item)">
              {{ $dt('models.training_info', 'Info') }}
            </b-button>
            <template v-if="canTrain(item)">
              <b-button id="tooltip-target-1" size="sm" variant="info" @click="train(item)">
                {{ $t('models.train') }}
              </b-button>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal id="model-training-info" ref="model-training-info" size="xl" title="Training Info">
      <b-row class="mx-0 model-training-info-row">
        <b-col cols="12">
          <template v-for="(label, index) in Object.keys(confusionMatrix) ">
            <b-row :key="`label-${index}`">
              <b-col cols="12">
                <div class="label-title">
                  {{ label }}
                </div>
              </b-col>
            </b-row>
            <b-row :key="`value-${index}`">
              <b-col cols="12">
                <div class="info-table-wrapper">
                  <table class="training-info-table">
                    <tr>
                      <td />
                      <td v-for="(classValue, classIndex) in confusionMatrix[label].classes" :key="classIndex"
                        class="class-cell vertical-text">
                        {{ classValue }}
                      </td>
                      <td class="class-cell vertical-text">
                        %
                      </td>
                    </tr>
                    <tr v-for="(classValue, classIndex) in confusionMatrix[label].classes " :key="classIndex">
                      <td class="class-cell">
                        {{ classValue }}
                      </td>
                      <td v-for="(dataValue, dataIndex) in confusionMatrix[label].data[classIndex] "
                        :key="`${classIndex}-${dataIndex}`" class="data-cell"
                        :style="{ backgroundColor: getColor(dataValue, dataIndex, label) }">
                        {{ dataValue }}
                      </td>
                      <td class="data-cell"
                        :style="{ backgroundColor: getColor(getDiagonal(classIndex, label), classIndex, label, 3.0) }">
                        {{ getAccuracyPercent(classValue, label).toFixed(2) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
            </b-row>
            <b-card v-for="(predictions, className, index) in incorrectByClass[label]" :key="`incorrect-${index}`"
              no-body class="mb-2">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button block v-b-toggle="`classification-errors-${label}-${className}`" variant="warning"
                  class="mt-0 text-left">
                  {{ predictions.length }}
                  {{ $dt('classification.errors_for_class', 'classification errors for class') }}
                  {{ className }}
                </b-button>
              </b-card-header>
              <b-collapse v-bind:id="`classification-errors-${label}-${className}`"
                :accordion="`classification-errors-${label}-${className}`" @show="loadImages(predictions)"
                @hide="clearImages(predictions)" role="tabpanel">
                <b-card-body>
                  <div v-for="(prediction, index) in predictions" :key="`${className}:${index}`" class="mb-1">
                    <b-card-text class="mb-1">
                      {{ $dt('classification.segmented_object_id', 'Segmented object ID:') }}
                      {{ prediction.segmented_image_id }}
                      <br>
                      {{ $dt('classification.predicted_class', 'Predicted class:') }}
                      {{ prediction.predicted_class_name }}
                      <br>
                      {{ $dt('classification.tray', 'Tray:') }}
                      <navigation-badge
                        :to="{ name: 'TraysPlotter', params: { id: prediction.segmentedImageInfo.tray_image_id } }"
                        variant="badge-green"
                        >
                        {{ prediction.segmentedImageInfo.tray_image_id }}
                      </navigation-badge>
                    </b-card-text>
                    <img :ref="`image-${prediction.segmented_image_id}`" />
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </template>
        </b-col>
      </b-row>
      <template #modal-ok>
        {{ $t('global.ok') }}
      </template>
      <template #modal-cancel>
        {{ $t('global.cancel') }}
      </template>
    </b-modal>
  </div>
</template>

<script>
import ModelTrainingService from '@/services/model-training-service';
import segmentedImagesService from '@/services/segmented-images-service';
import NavigationBadge from '@/components/NavigationBadge';


export default {
  name: 'ModelTrainingTable',
  components: { NavigationBadge },
  props: {
    items: {
      type: Array,
      required: false,
      default() { return []; },
    },
    place: {
      type: String,
      required: false,
      default() { return ''; },
    },
    modelStatus: {
      type: String,
      required: false,
      default() { return ''; },
    },
  },
  data() {
    return {
      confusionMatrix: {},
      incorrectByClass: {},
      loadedSampleImages: {},
    };
  },
  computed: {

    sampleImages() {
      return this.loadedSampleImages;
    },
  },
  mounted() { },
  methods: {
    canEdit(status) {
      const allowed = ['created', 'failed', 'ready'];
      if (!allowed.includes(status)) {
        return false;
      }
      return true;
    },
    canTrain(item) {
      if (this.modelStatus !== 'ready') {
        return false;
      }
      const allowed = ['created', 'ready', 'failed'];
      if (!allowed.includes(item.status)) {
        return false;
      }
      return true;
    },
    canDelete(status) {
      const allowed = ['created', 'failed', 'ready', 'queue'];
      if (!allowed.includes(status)) {
        return false;
      }
      return true;
    },
    canCancel(item) {
      const allowed = ['queue', 'training'];
      if (!allowed.includes(item.status)) {
        return false;
      }
      return true;
    },
    train(item) {
      ModelTrainingService.train(item.id).then((response) => {
        if (typeof response.data === 'object') {
          this.$emit('train', response.data.id, response.data);
        }
      }).catch();
    },
    cancel(item) {
      ModelTrainingService.cancel(item.id).then((response) => {
        if (typeof response.data === 'object') {
          this.$emit('cancel', response.data.id, response.data);
        }
      }).catch();
    },
    hasInfo(item) {
      if (!item.info) {
        return false;
      }
      else if (JSON.parse(item.info)?.confusion_matrix) {
        return true;
      }
      return false;
    },
    getTotals(data) {
      // Calculate the total sum for each row in each label
      const totals = {};
      Object.keys(data).forEach(label => {
        let rowsByname = {};
        const classData = data[label].data;
        classData.forEach((row, index) => {
          rowsByname[data[label].classes[index]] = row.reduce((a, b) => a + b, 0);
        });
        totals[label] = rowsByname;
      });
      return totals;
    },
    openTrainingInfo(item) {
      let parsed = JSON.parse(item.info);
      this.confusionMatrix = parsed?.confusion_matrix;
      if (parsed?.incorrect_predictions) {
        this.loadPredictionInfos(parsed.incorrect_predictions).then((predictions) => {
          this.incorrectByClass = predictions;
        });
      }
      this.trainingTotals = this.getTotals(this.confusionMatrix);
      this.$refs['model-training-info'].show();
    },
    getColor(value, dataIndex, labelKey, exp = 0.4) {
      const className = this.confusionMatrix[labelKey].classes[dataIndex];
      const total = this.trainingTotals[labelKey][className];
      let opacity = 0;
      if (total > 0) {
        opacity = value / total;
      }
      opacity = Math.pow(opacity, exp);
      return `rgba(120, 200, 255, ${opacity})`;
    },
    getAccuracyPercent(className, labelKey) {
      const classIndex = this.confusionMatrix[labelKey].classes.indexOf(className);
      const total = this.trainingTotals[labelKey][className];
      const correct = this.getDiagonal(classIndex, labelKey);
      if (total > 0) {
        return 100 * correct / total;
      }
      return 100;
    },
    getDiagonal(classIndex, labelKey) {
      return this.confusionMatrix[labelKey].data[classIndex][classIndex];
    },
    async loadPredictionInfos(incorrectPredictions) {
      let byLabel = {};

      let promises = [];
      for (const [label, predictions] of Object.entries(incorrectPredictions)) {
        let byClass = {};
        for (const prediction of predictions) {
          let groundTruth = prediction.ground_truth_class_name;
          if (!byClass[groundTruth]) {
            byClass[groundTruth] = [];
          }

          promises.push(
            segmentedImagesService.get(prediction.segmented_image_id).then((response) => {
              prediction.segmentedImageInfo = response.data;
            }),
          );
          byClass[groundTruth].push(prediction);
        }
        byLabel[label] = byClass;
      }

      await Promise.all(promises);
      return byLabel;
    },
    loadImages(predictions) {
      predictions.forEach(prediction => {
        segmentedImagesService.getImg(prediction.segmented_image_id).then((response) => {
          this.$refs[`image-${prediction.segmented_image_id}`][0].src = `data:image/png;base64,${response.data.image}`;
        });
      });
    },
    clearImages(predictions) {
      predictions.forEach(prediction => {
        this.$refs[`image-${prediction.segmented_image_id}`][0].src = '';
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}

.info-table-wrapper {
  overflow-x: scroll;
  margin-bottom: 1rem;
}

.model-training-info-row {
  max-height: 70vh;
  overflow-y: scroll;
}

.training-info-table {
  tr {
    height: 1px;
  }

  td {
    border: 1px solid black;
    border-collapse: collapse;

    &.class-cell {
      background-color: #f0f0f0;
      padding: 0.5rem;
      font-weight: bold;
      text-align: end;
      max-width: 10rem;
      min-width: 2rem;
      max-height: 10rem;
      min-height: 2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.vertical-text {
        writing-mode: vertical-rl;
      }
    }

    &:not(.class-cell) {
      text-align: center;
    }

    &.data-cell {
      font-weight: bold;
    }
  }
}

.label-title {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}
</style>
