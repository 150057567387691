<template>
  <b-card header-tag="header">
    <div slot="header">
      <span>{{ $t('models.model_properties') }}</span>
    </div>
    <form>
      <b-form-group :label="$t('models.classes')">
        <div class="d-flex justify-content-between">
          <b-button v-if="canEditClasses" size="sm" variant="success" class="mb-1"
            @click="$emit('showModelClassForm')">
            <i class="fas fa-plus" /> {{ $t('global.add_new') }}
          </b-button>
          <div>
            <b-button v-if="canEditClasses" size="sm" variant="success" class="mb-1"
              @click="$emit('showClassListsForm')">
              {{ $t('models.add_from_class_lists') }}
            </b-button>
          </div>
        </div>
        <model-classes-table :editable="canEditClasses" :deletable="canDeleteClasses" :items="modelData.model_classes"
          :model-status="modelData.status" @edit="editClass" @detach="detachClass" />
        <b-button v-if="canAggregate" size="sm" variant="success" @click="aggregate">
          {{ $t('global.aggregate') }}
          <b-spinner v-if="aggregating" small label="Loading" type="grow" />
        </b-button>
      </b-form-group>
      <b-form-group :label="$t('models.trainings')">
        <b-button size="sm" variant="success" style="margin-bottom: 10px;" @click="$emit('showTrainingForm')">
          <i class="fas fa-plus" /> {{ $t('global.add_new') }}
        </b-button>
        <model-training-table :items="trainings" :model-status="modelData.status" :place="'addon'" @edit="editTraining"
          @delete="onTrainingDelete" @train="onTrainingTrain" @cancel="onTrainingCancel" />
      </b-form-group>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';
import ModelClassesTable from '../../CRUD/ModelClasses/components/ModelClassesTable';
import ModelClassesService from '@/services/model-classes-service';
import ModelTrainingTable from '../../CRUD/ModelTrainings/components/ModelTrainingTable';

export default {
  name: 'ModelsComponentsForm',
  components: {
    ModelClassesTable, ModelTrainingTable,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    canEditClasses: {
      type: Boolean,
      required: true,
    },
    canDeleteClasses: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modelData: _.cloneDeep(this.value),
      showClasses: true,
      aggregating: false,
    };
  },
  computed: {
    ...mapState(['models']),
    canModify() {
      const allowed = ['created', 'failed'];
      if (!allowed.includes(this.modelData.status)) {
        return false;
      }
      return true;
    },
    canAggregate() {
      let can = true;
      const allowed = ['created', 'ready', 'failed'];
      if (!allowed.includes(this.modelData.status)) {
        return false;
      }
      if (this.modelData.model_classes.length < 2) {
        return false;
      }
      _.forEach(this.modelData.model_classes, (modelClass) => {
        if (modelClass.product_types.length === 0) {
          can = false;
        }
      });
      return can;
    },
    trainings() {
      return this.modelData.model_training;
    },
    canAttachClasses() {
      const allowed = ['created', 'failed'];
      if (!allowed.includes(this.modelData.status)) {
        return false;
      }
      return true;
    },
  },
  watch: {
    value(newVal) {
      this.modelData = newVal;
      this.aggregating = false;
    },
  },
  mounted() {
    this.loadModels();
  },
  methods: {
    ...mapActions({
      add: VuexTypes.MODELS_ADD,
      update: VuexTypes.MODELS_UPDATE,
      deleteTraining: VuexTypes.MODEL_TRAINING_DELETE,
      loadModels: VuexTypes.MODELS_LIST,
    }),
    closeClick() {
      this.$emit('saved', false);
    },
    editClass(item) {
      this.$emit('fillModelClass', item);
    },
    detachClass(item, index) {
      if (_.isUndefined(item.id) || !_.isUndefined(item.tempId)) {
        this.modelData.model_classes.splice(index, 1);
      } else {
        if (confirm(this.$t('models.class_delete_confirm_text'))) {
          const data = {
            model_class_id: item.id,
            model_id: null,
          };
          ModelClassesService.attachModel(data)
            .then(() => {
              const index = _.findIndex(this.modelData.model_classes, (o) => { return o.id === item.id; });
              this.modelData.model_classes.splice(index, 1);
            }).catch(() => {
              console.error('Error detaching model class');
            });
        }
      }
      this.$emit('fillModelClass');
    },
    editTraining(item) {
      this.$emit('fillModelTraining', item);
    },
    onTrainingDelete(item) {
      if (confirm(this.$t('models.training_delete_confirm_text'))) {
        this.deleteTraining(item.id).then(() => {
          this.$emit('fillModelTraining');
        });
      }
    },
    aggregate() {
      this.aggregating = true;
      this.$emit('aggregate');
    },
    onTrainingTrain(id, item) {
      const index = _.findIndex(this.modelData.model_training, (o) => { return o.id === id; });
      this.modelData.model_training[index] = item;
    },
    onTrainingCancel(id, item) {
      const index = _.findIndex(this.modelData.model_training, (o) => { return o.id === id; });
      this.modelData.model_training[index] = item;
    },
    addClassFromClassLists() {
      this.$emit('showClassListsForm');
    },
  },
};
</script>
<style></style>
