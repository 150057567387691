<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('cash_desk.header') }}
            <b-btn
              class="pull-right"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('cash_desk.inventory_number') }}</th>
                    <th>{{ $t('global.description') }}</th>
                    <th>{{ $t('cash_desk.address') }}</th>
                    <th>{{ $t('cash_desk.table_code') }}</th>
                    <th>{{ $t('cash_desk.type') }}</th>
                    <th>{{ $t('cash_desk.station_id') }}</th>
                    <th>{{ $t('cash_desk.cashier_id') }}</th>
                    <th>{{ $t('cash_desk.payment_id') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in cashDesk.list.data"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td>{{ item.inventory_number }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.host }}:{{ item.port }}</td>
                    <td>{{ item.table_code }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.station_id }}</td>
                    <td>{{ item.cashier_id }}</td>
                    <td>{{ item.payment_id }}</td>
                    <td>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-success btn-sm table-button-size"
                        @click="editItem(item)"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteItem(item)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="cashDesk.list.total"
              :per-page="cashDesk.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <cash-desk-form
          v-model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import CashDeskForm from './CashDeskForm';
import CashDeskService from '@/services/cash-desk-service';

export default {
  name      : 'CashDesk',
  components: { CashDeskForm },
  data () {
    return {
      currentPage: 1,
      addItem    : { type: 'rkeeper' },
      showAddForm: false,
    };
  },
  computed: {
    ...mapState(['cashDesk']),
    ...mapGetters(['can']),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.list(this.currentPage);
    },
  },
  mounted () {
    this.list(this.currentPage);
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.CASH_DESK_LIST,
      delete: VuexTypes.CASH_DESK_DELETE,
    }),
    editItem (item) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, item);
    },
    deleteItem (item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.addItem = { type: 'rkeeper' };
      }
    },
    formSaved () {
      this.showAddForm = false;
      this.addItem = { type: 'rkeeper' };
    },
    check (item) {
      const data = { id: item.id };
      CashDeskService.check(data).then(() => {
        this.$notify({
          type : 'success',
          title: this.$t('cash_desk.check_success', { ip: item.host, port: item.port }),
        });
      }).catch(() => {
        this.$notify({
          type : 'error',
          title: this.$t('global.error'),
          text : this.$t('cash_desk.check_fail', { ip: item.host, port: item.port }),
        });
      });
    },
    openAddForm () {
      this.showAddForm = true;
      this.addItem = { type: 'rkeeper' };
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
