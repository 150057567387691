import { defaultMutations } from 'vuex-easy-access';
import { VuexTypes } from '../types';
import UserService from '@/services/user-service';
import RoleService from '@/services/role-service';

const state = {
  list: {
    data        : [],
    current_page: 1,
    total       : 1,
  },

  permissions: [],
  roles      : { data: [] },
  status     : 'success',
  role_status: 'success',
};

const getters = {};

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  ...defaultMutations(state),
  [VuexTypes.USERS_LOAD]: (state, status) => {
    state.status = status;
  },
  [VuexTypes.USERS_LIST]: (state, list) => {
    state.list = list;
    state.status = 'success';
  },
  [VuexTypes.ROLES_LOAD]: (state, status) => {
    state.role_status = status;
  },
  [VuexTypes.ROLES_LIST]: (state, list) => {
    state.roles = list;
    state.role_status = 'success';
  },

  [VuexTypes.LIST_PERMISSIONS]: (state, response) => {
    Vue.set(state, 'permissions', response.data);
  },
};

const actions = {
  [VuexTypes.USERS_LIST]: ({ commit, _dispatch }, currentPage) => {
    commit(VuexTypes.USERS_LOAD, 'loading');
    return new Promise((resolve, reject) => {
      UserService.list(currentPage)
        .then((resp) => {
          commit(VuexTypes.USERS_LIST, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(VuexTypes.USERS_LOAD, 'error');
          reject(error);
        });
    });
  },
  [VuexTypes.USERS_ADD]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.USERS_LOAD, 'loading');
      UserService.store(data)
        .then((resp) => {
          dispatch(VuexTypes.USERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.USERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.USERS_UPDATE]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.USERS_LOAD, 'loading');
      UserService.update(data)
        .then((resp) => {
          dispatch(VuexTypes.USERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.USERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.USERS_DELETE]: ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.USERS_LOAD, 'loading');
      UserService.delete(id)
        .then((resp) => {
          dispatch(VuexTypes.USERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.USERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },

  [VuexTypes.ROLES_LIST]: ({ commit, _dispatch }, currentPage) => {
    commit(VuexTypes.ROLES_LOAD, 'loading');
    return new Promise((resolve, reject) => {
      RoleService.list(currentPage)
        .then((resp) => {
          commit(VuexTypes.ROLES_LIST, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(VuexTypes.ROLES_LOAD, 'error');
          reject(error);
        });
    });
  },
  [VuexTypes.ROLES_ADD]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ROLES_LOAD, 'loading');
      RoleService.store(data)
        .then((resp) => {
          dispatch(VuexTypes.ROLES_LIST).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ROLES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.ROLES_UPDATE]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ROLES_LOAD, 'loading');
      RoleService.update(data)
        .then((resp) => {
          dispatch(VuexTypes.ROLES_LIST).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ROLES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.ROLES_DELETE]: ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ROLES_LOAD, 'loading');
      RoleService.delete(id)
        .then((resp) => {
          dispatch(VuexTypes.ROLES_LIST).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ROLES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },

  [VuexTypes.LIST_PERMISSIONS]: function ({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.listPermissions()
        .then((resp) => {
          commit(VuexTypes.LIST_PERMISSIONS, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
