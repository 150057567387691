import CashDeskService from '@/services/cash-desk-service';
import { VuexTypes } from '../../types';

const state = {
  list: [],
  loading: false,
};

const getters = {
  byId: (state) => (id) => {
    return state.list.find(e => e.id === id);
  },
};

const mutations = {
  [VuexTypes.CASHIER_LOAD_CASH_DESKS_IN_PROGRESS]: (state, value) => {
    state.loading = value ? true : false;
  },
  [VuexTypes.CASHIER_SET_CASH_DESKS]: (state, cashDesks) => {
    state.list.splice(0);
    
    cashDesks.forEach((c) => {
      state.list.push(c);
    });
  },
};

const actions = {
  [VuexTypes.CASH_DESK_SET_THEME]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.CASHIER_LOAD_CASH_DESKS_IN_PROGRESS, true);
      CashDeskService.setTheme(data)
        .then(() => {
          dispatch(VuexTypes.CASHIER_LOAD_CASH_DESKS);
        })
        .catch((error) => {
          commit(VuexTypes.CASHIER_LOAD_CASH_DESKS_IN_PROGRESS, false);
          reject(error.response.data);
        });
    });
  },

  [VuexTypes.CASHIER_LOAD_CASH_DESKS]: ({ commit, dispatch }) => {
    commit(VuexTypes.CASHIER_LOAD_CASH_DESKS_IN_PROGRESS, true);

    CashDeskService.cashierList().then((response) => {
      commit(VuexTypes.CASHIER_SET_CASH_DESKS, response.data);
      commit(VuexTypes.CASHIER_LOAD_CASH_DESKS_IN_PROGRESS, false);
    }).catch((err) => {
      console.log('err', err);
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
