import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/model_classes?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/model_classes/${id}`);
  },
  findByName (name, limit) {
    return Api().get(`/model_classes/find?name=${name}&limit=${limit}`);
  },
  store (data) {
    return Api().post('/model_classes', data);
  },
  update (data) {
    return Api().put(`/model_classes/${data.id}`, data);
  },
  attachType (data) {
    return Api().post('/model_classes/attach_product_type', data);
  },
  detachType (data) {
    return Api().post('/model_classes/detach_product_type', data);
  },
  attachModel (data) {
    return Api().post('/model_classes/attach_model', data);
  },
  delete (id) {
    return Api().delete(`/model_classes/${id}`);
  },
  importClasses (data) {
    return Api().post('/model_classes/import_classes', data);
  },
};
