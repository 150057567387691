import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/cash_desk_products_list?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/cash_desk_products_list/${id}`);
  },
  store (data) {
    return Api().post('/cash_desk_products_list', data);
  },
  update (data) {
    return Api().put(`/cash_desk_products_list/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/cash_desk_products_list/${id}`);
  },
};
