<template>
  <b-form-select
    v-model="selected"
    size="lg"
    class="mt-3"
    :options="options"
  />
</template>

<script>

import RaspberryService from '@/services/raspberry-service';

export default {
  name: 'RaspberrySelect',
  data() {
    return {
      selected: null,
      options: [ ],
    };
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
  },
  mounted () {
    RaspberryService.list().then((resp) => {
      this.options.splice(0);

      resp.data.forEach((el) => {
        this.options.push({
          value: el,
          text: `${el.id}, ${el.name}, ${el.hardware_id}, ${el.description}`,
        });
      });
    });
  },
  methods: {
  },
};
</script>