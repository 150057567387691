<template>
  <b-card :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }" header-tag="header">
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $t('global.add_new') }} </span>
      <!-- <b-btn v-if="!onlyNextModelEdit" size="sm" class="pull-right" @click="$emit('create')">
        New
      </b-btn> -->
      <div class="card-actions">
        <a href="#" class="btn btn-setting" @click="closeClick">
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group :label="$t('models.class_name')" :state="!errors.class_name" :invalid-feedback="errors.class_name">
        <b-form-input v-model="modelData.class_name" :disabled="onlyNextModelEdit" type="text" />
      </b-form-group>
      <b-form-group :label="$t('models.next_model_id')" :state="!errors.next_model_id"
        :invalid-feedback="errors.next_model_id">
        <b-form-select v-model="modelData.next_model_id" :options="modelsOptions" />
      </b-form-group>
      <b-form-group v-if="view !== 'modelCreate'" :label="$t('models.product_types')" :state="!errors.product_type_id"
        :invalid-feedback="errors.product_type_id">
        <vue-tags-input v-model="tag" :tags="inputTags" :placeholder="$t('models.add_product_type')"
          :autocomplete-items="autocompleteItems" :add-only-from-autocomplete="true" :disabled="onlyNextModelEdit"
          :class="{ 'custom-disabled': onlyNextModelEdit }" @tags-changed="tagsChanged" @before-adding-tag="attachTag"
          @before-deleting-tag="detachTag" />
      </b-form-group>
      <div>
        <b-btn v-if="showCancel || isEditing" variant="outline-danger" @click="closeClick">
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn class="pull-right" type="submit" variant="success">
          {{ $t('global.save') }}
          <b-spinner v-if="loading" small type="grow" />
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';
import ModelClassService from '@/services/model-classes-service';
import VueTagsInput from '@johmun/vue-tags-input';
export default {
  name: 'ModelClassesForm',
  components: { VueTagsInput },
  props: {
    model: {
      type: Object,
      required: false,
      default() {
        return {
          product_types: [],
        };
      },
    },
    view: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    showCancel: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    modelStatus: {
      type: String,
      required: false,
      default() { return ''; },
    },
  },
  data() {
    return {
      modelData: _.cloneDeep(this.model),
      errors: {},
      tag: '',
      autocompleteItems: [],
      loading: false,
      allModels: [],
    };
  },
  computed: {
    ...mapState(['productTypes', 'models']),
    isEditing() {
      return typeof this.modelData.id !== 'undefined' && this.modelData.id > 0;
    },
    inputTags() {
      return this.modelData.product_types && this.modelData.product_types.map((a) => {
        return {
          text: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
          id: a.id,
        };
      }) || [];
    },
    modelId() {
      return !_.isUndefined(this.$route.params.id) ? this.$route.params.id : false;
    },
    onlyNextModelEdit() {
      return this.modelDataStatus === 'ready';
    },
    modelsOptions() {
      if (!this.models.list?.length) {
        return [];
      }

      const array = this.models.list.map((a) => {
        return { text: `${a.id} - ${a.name}`, value: a.id };
      });
      array.unshift({ value: null, text: this.$t('global.none') });
      return array;
    },
  },
  watch: {
    modelData: function () {
      this.errors = {};
    },
    model: function () {
      this.modelData = _.cloneDeep(this.model);
    },
    tag: 'initItems',
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    ...mapActions({
      add: VuexTypes.MODEL_CLASSES_ADD,
      update: VuexTypes.MODEL_CLASSES_UPDATE,
      getTypes: VuexTypes.PRODUCT_TYPES_ALL,
    }),
    closeClick() {
      this.$emit('canceled', {});
    },
    save() {
      this.loading = true;
      if (this.view === 'modelCreate') {
        this._add();
      } else {
        this._save();
      }
    },
    _add() {
      if (this.modelData.id) {
        ModelClassService.update(this.modelData).then(() => {
          this.errors = {};
          this.$emit('add', Object.assign({}, this.modelData));
        }).catch((error) => {
          this.errors = {};
          if (typeof error.response.data.data !== 'undefined') {
            this.errors = error.response.data.data;
          }
          for (const item in error.response.data.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
      } else {
        this.errors = {};
        if (this.modelData.class_name) {
          this.$emit('add', Object.assign({}, this.modelData));
        } else {
          this.$set(this.errors, 'class_name', 'The class_name path field is required.');
        }
      }
      this.loading = false;
    },
    _save() {
      let data = Object.assign({}, this.modelData);
      if (this.modelId) {
        data.model_id = this.modelId;
      }
      if (!this.isEditing) {
        data = Object.assign({}, data, {
          product_types: this.modelData.product_types.map(item => item.id),
        });
      }
      if (this.onlyNextModelEdit) {
        data = Object.assign({}, data, { only_next_model: true });
      }
      const action = this.isEditing ? this.update(data) : this.add(data);
      action
        .then((response) => {
          this.loading = false;
          this.errors = {};
          this.$emit('saved', Object.assign({}, response.data));
        }).catch((error) => {
          this.loading = false;
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    initItems() {
      if (this.tag.length < 1) {
        this.autocompleteItems = [];
        return;
      }
      this.autocompleteItems = this.productTypes.all.filter((a) => {
        if (a.name.toLowerCase().includes(this.tag.toLowerCase())
          || (a.product && a.product.code && a.product.code.toString().includes(this.tag.toLowerCase()))) {
          return true;
        }
      }).map((a) => {
        return {
          text: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
          id: a.id,
          name: a.name,
          code: a.product && a.product.code ? a.product.code : null,
        };
      });
    },
    tagsChanged() {
      this.autocompleteItems = [];
    },
    attachTag(object) {
      if (!this.isEditing) {
        const item = {
          id: object.tag.id,
          name: object.tag.name,
          product: {
            code: object.tag.code,
          },
        };
        this.tag = '';
        if (!this.modelData.product_types) {
          this.modelData.product_types = [item];
        } else {
          this.modelData.product_types.push(item);
        }
        return;
      }

      this.loading = true;
      const data = {
        model_class_id: this.modelData.id,
        product_type_id: object.tag.id,
      };
      ModelClassService.attachType(data).then(() => {
        this.loading = false;
        this.errors = {};
        this.$emit('saved', Object.assign({}, this.modelData));
        this.tag = '';
      }).catch((error) => {
        this.loading = false;
        this.errors = {};
        this.tag = '';
        if (typeof error.response.data.data !== 'undefined') {
          this.errors = error.response.data.data;
        }
        for (const item in error.response.data.data) {
          this.errors[item] = this.errors[item].join(' ');
        }
      });
    },
    detachTag(object) {
      if (object.index === -1) { return; }

      if (!this.isEditing) {
        this.tag = '';
        this.modelData.product_types.splice(object.index, 1);

        return;
      }

      this.loading = true;
      const data = {
        model_class_id: this.modelData.id,
        product_type_id: object.tag.id,
      };
      ModelClassService.detachType(data).then(() => {
        this.loading = false;
        this.errors = {};
        this.$emit('saved', Object.assign({}, this.modelData));
        this.tag = '';
      }).catch((error) => {
        this.loading = false;
        this.errors = {};
        this.tag = '';
        if (typeof error.data !== 'undefined') {
          this.errors = error.data;
        }
        for (const item in error.data) {
          this.errors[item] = this.errors[item].join(' ');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-disabled ::v-deep .ti-input {
  background-color: #c2cfd6;

  .ti-actions,
  .ti-new-tag-input {
    display: none;
  }

  .ti-tag {
    background-color: #ecebeb;
    color: #3e515b;
  }

}
</style>
