import { mapState } from 'vuex';
import { io } from 'socket.io-client';

export const socketio = {
  data () {
    return {
      socket: {
        client: null,
        reconnectTM: 1000,
        reconnectP: -1,
      },
    };
  },
  computed: {
    ...mapState(['auth']),
    socketUrl () {
      let url = this.auth.env.MIX_WEBSOCKET_HOST;
      if (this.auth.env.MIX_WEBSOCKET_PORT) {
        url += `:${this.auth.env.MIX_WEBSOCKET_PORT}`;
      }
      url += '/ui';
      return url;
    },
    socketQuery () {
      return {
        token: this.auth.token,
      };
    },
  },
  mounted () {
    this.socketConnect();
  },
  beforeDestroy () {
    if (this.socket.client) {
      this.socket.client.close();
      delete this.socket.client;
    }
  },
  methods: {
    socketConnect () {
      this.socketDisconnect();

      if (this.socketReconnect()) {
        return false;
      }

      this.socketClient(this.socketQuery);
      this.socketHandler();
    },
    socketReconnect () {
      if (this.socketUrl.includes('undefined')) {
        clearTimeout(this.socket.reconnectP);
        this.socket.reconnectP = setTimeout(() => { this.socketConnect(); }, this.socket.reconnectTM);
        return true;
      }
    },
    socketDisconnect() {
      if (this.socket.client) {
        this.socket.client.close();
        this.socket.client = null;
      }
    },
    socketClient (query) {
      this.socket.client = io(this.socketUrl, {
        //path : '/ws',
        query: query,
      });
      return this.socket.client;
    },
    socketHandler () {},
  },
};
