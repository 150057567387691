import Api from '@/services/api';

export default {
  login (email, password) {
    return Api().post('/login', { email: email, password: password });
  },
  cacheDesk (hardwareId) {
    return Api().post('/cache_desk/init', { hardwareId: hardwareId });
  },
  cacheDeskLogin (token) {
    return Api().post('/cache_desk/login', { token: token });
  },
  user () {
    return Api().get('/user');
  },
  changePassword (data) {
    return Api().post('/change_password', data);
  },
  register (data) {
    return Api().post('/register', data);
  },
};
