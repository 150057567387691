<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('global.name') }}</th>
            <th>{{ $t('global.description') }}</th>
            <th>{{ $t('raspberry.hardware_id') }}</th>
            <th>{{ $t('global.enabled') }}</th>
            <th v-if="actions.enabled">
              {{ $t('global.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in raspberry.list"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.hardware_id }}</td>
            <td>{{ item.enabled }}</td>
            <td v-if="actions.enabled">
              <button
                v-if="can('data-grab') && actions.select"
                type="button"
                class="btn btn-success btn-sm table-button-size"
                @click="selectItem(item)"
              >
                <i class="fa icon-pencil" /> {{ $t('global.select') }}
              </button>
              <button
                v-if="can('super-admin', 'cvision-admin') && actions.edit"
                type="button"
                class="btn btn-success btn-sm table-button-size"
                @click="editItem(item)"
              >
                <i class="fa icon-pencil" /> {{ $t('global.edit') }}
              </button>
              <button
                v-if="can('super-admin', 'cvision-admin') && actions.delete"
                type="button"
                class="btn btn-warning btn-sm table-button-size"
                @click="deleteItem(item)"
              >
                <i class="fa fa-trash" /> {{ $t('global.delete') }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="search.currentPage"
      :total-rows="raspberry.list.total"
      :per-page="raspberry.list.per_page"
      class="mt-4"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';

export default {
  name : 'RaspberryTable',
  props: {
    actions: {
      type    : Object,
      required: true,
      default () {
        return {
          enabled: true,
          edit   : true,
          delete : true,
          select : true,
        };
      },
    },
    enabledOnly: {
      type    : Boolean,
      required: true,
      default () { return false; },
    },
  },
  data () {
    return {
      search: {
        currentPage: 1,
        enabledOnly: this.enabledOnly,
      },
      addItem: {},
    };
  },
  computed: {
    ...mapState(['raspberry']),
    ...mapGetters(['can']),
  },
  watch: {
    'roles'             : 'onRolesLoaded',
    'search.currentPage': function () {
      this.list(this.search);
    },
  },
  mounted () {
    this.list(this.search);
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.RASPBERRY_LIST,
      delete: VuexTypes.RASPBERRY_DELETE,
    }),
    selectItem (item) {
      this.$emit('select', item);
    },
    editItem (item) {
      this.$emit('edit', item);
    },
    deleteItem (item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.$emit('delete', true);
      }
    },
    formSaved () {
      this.addItem = {};
    },
    updateList () {
      this.list(this.search);
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
