<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <img
        v-if="product.image"
        :src="product.image"
        class="img-thumbnail mx-auto d-block mb-2"
        alt="Product image"
      >
      <b-form-group
        :label="$t('global.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="product.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        v-if="activeLanguages.length > 0"
        :label="$t('global.name_translations')"
        :state="!errors.name_translations"
        :invalid-feedback="errors.name_translations"
      >
        <div class="border px-3 pt-3">
          <b-form-group
            v-for="language in activeLanguages"
            :key="language.key"
            :label="language.key"
            label-cols="2"
          >
            <b-form-input
              v-model="product.translations.name[language.key]"
              type="text"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group
        :label="$t('global.image')"
        :state="!errors.image"
        :invalid-feedback="errors.image"
        :valid-feedback="newImage && $t('human_products.new_image_ready_for_upload')"
      >
        <b-input-group>
          <b-form-file
            ref="imageFileInput"
            accept="image/jpeg, image/png"
            @input="parseNewImageFile"
          />
          <b-input-group-append v-if="newImage">
            <b-btn
              variant="danger"
              @click="clearNewImage"
            >
              <i class="fa fa-trash" />
            </b-btn>
          </b-input-group-append>
        </b-input-group>
        <img
          v-if="newImage"
          :src="newImage"
          class="img-fluid mt-2"
          alt="Product image to upload"
        >
      </b-form-group>
      <b-form-group
        :label="$t('global.description')"
        :state="!errors.description"
        :invalid-feedback="errors.description"
      >
        <b-form-input
          v-model="product.description"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('human_products.code')"
        :state="!errors.code"
        :invalid-feedback="errors.code"
      >
        <b-form-input
          v-model.trim="product.code"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('human_products.price_in_cents')"
        :state="!errors.price"
        :invalid-feedback="errors.price"
      >
        <b-form-input
          v-model="product.price"
          type="number"
        />
      </b-form-group>
      <b-form-group
        :label="$t('human_products.age_validation')"
      >
        <b-form-checkbox
          v-model="product.age_validation"
          value="1"
          unchecked-value="0"
        />
      </b-form-group>
      
      <b-form-group
        :label="$t('human_products.category')"
        :state="!errors.category_id"
        :invalid-feedback="errors.category_id"
      >
        <b-select-2
          v-model="category"
          :options="opt"
        />
      </b-form-group>
      <b-form-group
        v-if="isEditing"
        :label="$t('human_products.types')"
      >
        <vue-tags-input
          v-model="tag"
          :tags="typeTags"
          :placeholder="$t('human_products.add_type_placeholder')"
          :autocomplete-items="filteredItems()"
          :add-only-from-autocomplete="true"
          @tags-changed="newTags => typeTags = newTags"
        />
      </b-form-group>
      <b-form-group
        :label="$t('human_products.visible_for_customer')"
      >
        <b-form-checkbox
          v-model="product.visible_for_customer"
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
          :disabled="saving"
        >
          <b-spinner
            v-if="saving"
            label="Spinning"
            small
          />
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
          :disabled="saving"
        >
          <b-spinner
            v-if="saving"
            label="Spinning"
            small
          />
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>

<script>
import { VuexTypes } from '@/store/types';
import { mapActions, mapGetters, mapState } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';
import HumanProductsService from '@/services/human-products-service';
import CategoriesService from '@/services/categories-service';
import ProductTypesService from '@/services/product-types-service/';

export default {
  name      : 'HumanProductsForm',
  components: { VueTagsInput },
  props     : {
    value: {
      type    : Object,
      required: false,
      default () {
        return {
          enabled : false,
          category: { name: '' },
          product_types: [],
        };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      product: this.cloneAndNormalize(this.value),

      tag          : '',
      errors       : {},
      category     : this.value.category_id || '',
      allCategories: [],
      newImage     : null,

      possibleTags: [],
      saving      : false,
    };
  },
  computed: {
    ...mapState(['humanProducts']),
    ...mapGetters(['activeLanguages']),
    isEditing () {
      return this.product?.id > 0;
    },
    opt () {
      const array = this.allCategories.map((a) => {
        return { text: a.name, id: a.id };
      });
      array.unshift({ id: '', text: '' });
      return array;
    },
    typeTags: {
      get: function () {
        return this.product.product_types.map((a) => {
          return { text: a.name, id: a.id };
        });
      },
      set: function (value) {
        this.product.product_types = value.map((a) => {
          return { name: a.text, id: a.id };
        });
      },
    },
  },
  watch: {
    value: function (newVal) {
      this.product = newVal;
      this.refresh();
    },
  },
  mounted () {
    this.fetchTags();
    this.refresh();
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.HUMAN_PRODUCTS_ADD,
      update: VuexTypes.HUMAN_PRODUCTS_UPDATE,
    }),
    cloneAndNormalize (product) {
      let cloned = _.cloneDeep(product);
      // Sometimes PHP decides to convert empty objects to arrays.
      if (cloned?.translations?.name?.length === 0) {
        cloned.translations.name = {};
      }

      return cloned;
    },
    refresh() {
      this.errors = {};
      if (this.product.visible_for_customer === undefined) {
        this.product.visible_for_customer = true;
      }
      if (this.product.category_id) {
        this.category = parseInt(this.product.category_id);
      } else {
        this.category = '';
      }
      CategoriesService.all().then((response) => {
        this.allCategories = response.data;
      }).catch(() => {
        this.loading.restaurants = false;
        this.$notify({
          type : 'error',
          title: this.$t('global.error'),
          text : this.$t('global.connection_lost_service', { service: 'CategoriesService.all' }),
        });
      });
      if (this.isEditing) {
        HumanProductsService.getImage(this.product.id).then((response) => {
          this.product.image = response.data;
          this.$forceUpdate();
        });
      }
      this.clearNewImage();
    },
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      this.saving = true;
      this.product.category_id = this.category;
      const prevImage = this.product.image;
      this.product.image = this.newImage;
      const action = this.isEditing ? this.update(this.product) : this.add(this.product);

      action
        .then((data) => {
          this.errors = {};

          this.$notify({
            type : 'success',
            title: this.$t('global.success'),
            text : this.$t('human_products.save_success'),
          });

          this.saving = false;

          this.$emit('saved', data.data.id);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }

          this.saving = false;
          this.product.image = prevImage;
        });
    },
    tagsChanged () {},
    fetchTags () {
      ProductTypesService.getAll()
        .then((resp) => {
          const possibleTags = resp.data.map((type) => {
            return { text: type.name, id: type.id };
          });

          this.possibleTags = possibleTags;
        });
    },
    filteredItems() {
      return this.possibleTags.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
    inputTags () {},
    parseNewImageFile(file) {
      if (!file) { return; }

      const reader = new FileReader();
      reader.onload = function (e) {
        this.newImage = e.target.result;
      }.bind(this);
      reader.onerror = function (error) {
        console.error('Got error while parsing image file: ', error);
        this.clearNewImage();
      }.bind(this);
      reader.readAsDataURL(file);
    },
    clearNewImage() {
      this.$refs.imageFileInput.reset();
      this.newImage = null;
    },
  },
};
</script>
<style>
</style>
