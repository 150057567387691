import Api from '@/services/api';

export default {
  list (currentPage) {
    if (!currentPage) { currentPage = ''; }
    return Api().get(`/restaurant?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/restaurant/${id}`);
  },
  store (data) {
    return Api().post('/restaurant', data);
  },
  update (data) {
    return Api().put(`/restaurant/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/restaurant/${id}`);
  },
};
