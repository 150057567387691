<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $dt('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $dt('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$dt('themes.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="modelData.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('themes.icon_image')"
        :state="!errors.icon_image"
        :invalid-feedback="errors.icon_image"
        :valid-feedback="iconImage && $dt('themes.icon_image_ready_to_upload')"
      >
        <b-input-group>
          <b-form-file
            ref="iconImage"
            :placeholder="$dt('global.choose_or_drop_in_file')"
            :drop-placeholder="$dt('global.drop_in_file')"
            accept="image/jpeg, image/png"
            @input="parseImageFile($event, 'iconImage')"
          />
          <b-input-group-append v-if="iconImage">
            <b-btn
              variant="danger"
              @click="clearImage('iconImage')"
            >
              <i class="fa fa-trash" />
            </b-btn>
          </b-input-group-append>
        </b-input-group>
        <img
          v-if="iconImage"
          :src="iconImage"
          class="img-fluid mt-2"
          alt="Icon image to upload"
        >
      </b-form-group>
      <b-form-group
        :label="$dt('themes.background_image')"
        :state="!errors.background_image"
        :invalid-feedback="errors.background_image"
        :valid-feedback="backgroundImage && $dt('themes.background_image_ready_to_upload')"
      >
        <b-input-group>
          <b-form-file
            ref="backgroundImage"
            :placeholder="$dt('global.choose_or_drop_in_file')"
            :drop-placeholder="$dt('global.drop_in_file')"
            accept="image/jpeg, image/png"
            @input="parseImageFile($event, 'backgroundImage')"
          />
          <b-input-group-append v-if="backgroundImage">
            <b-btn
              variant="danger"
              @click="clearImage('backgroundImage')"
            >
              <i class="fa fa-trash" />
            </b-btn>
          </b-input-group-append>
        </b-input-group>
        <img
          v-if="backgroundImage"
          :src="backgroundImage"
          class="img-fluid mt-2"
          alt="Product image to upload"
        >
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $dt('global.cancel') }}
        </b-btn>
        <b-btn
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $dt('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ThemeForm',

  props: {
    model: {
      type    : Object,
      required: false,
      default () {
        return { enabled: false };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      modelData: _.cloneDeep(this.model),
      iconImage: null,
      backgroundImage: null,
      errors: {},
    };
  },
  computed: {
    ...mapState(['themes']),
    isEditing () {
      return typeof this.modelData.id !== 'undefined' && this.modelData.id > 0;
    },
  },
  watch: {
    model: function () {
    },
  },
  mounted () {
    this.iconImage = this.modelData.icon_image;
    this.backgroundImage = this.modelData.background_image;
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.THEMES_ADD,
      update: VuexTypes.THEMES_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      this.modelData.icon_image = this.iconImage;
      this.modelData.background_image = this.backgroundImage;
      const action = this.isEditing ? this.update(this.modelData) : this.add(this.modelData);
      
      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    parseImageFile(file, image) {
      if (!file) { return; }

      const reader = new FileReader();
      reader.onload = function (e) {
        this[image] = e.target.result;
      }.bind(this);
      reader.onerror = function (error) {
        console.error('Got error while parsing image file: ', error);
        this.clearImage(image);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    clearImage(image) {
      this.$refs[image].reset();
      this[image] = null;
    },
  },
};
</script>
<style>
</style>
