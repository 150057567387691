import Api from '@/services/api';
import { makeQuery } from '@/helper';

export default {
  list(page, search, filters = {}) {
    const queryParams = { page, search };
    for (const [key, value] of Object.entries(filters)) {
      queryParams[key] = value.join(',');
    }

    return Api().get('/human_products' + makeQuery(queryParams));
  },
  listAll() {
    return Api().get('/human_products');
  },
  forCashier() {
    return Api().get('/cashier/human_products');
  },
  forProductSelect(cashDeskId) {
    return Api().get('cashier/human_products/for_product_select/' + cashDeskId);
  },
  get(id) {
    return Api().get(`/human_products/${id}`);
  },
  getByCategory(id) {
    return Api().get(`/human_products?category_id=${id}`);
  },
  store(data) {
    return Api().post('/human_products', data);
  },
  update(data) {
    return Api().put(`/human_products/${data.id}`, data);
  },
  attachType(data) {
    return Api().post('/human_products/attach_product_type', data);
  },
  detachType(data) {
    return Api().post('/human_products/detach_product_type', data);
  },
  delete(id) {
    return Api().delete(`/human_products/${id}`);
  },
  getImage(id) {
    return Api().get(`/human_products/img/${id}`);
  },
  getImportInfo(formData) {
    return Api().post('/human_products/get_import_info', formData);
  },
};
