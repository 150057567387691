<template>
  <b-card
    class="bg-light-green"
    header-tag="header"
  >
    <div slot="header">
      <i class="fa icon-pencil" /> {{ $t('models.add_model_class') }}
      <!-- <b-btn size="sm" class="pull-right" @click="$emit('create')">
        New
      </b-btn> -->
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="$emit('cancel', true)"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="attach">
      <b-form-group :label="$t('global.search')">
        <b-input-group>
          <template #prepend>
            <b-input-group-text><i class="fas fa-search" /></b-input-group-text>
          </template>
          <b-form-input
            v-model.trim="className"
            autocomplete="off"
          />
          <ul
            role="menu"
            tabindex="-1"
            class="dropdown-menu model-class-menu"
            :class="{show: showDropDown}"
          >
            <li
              v-for="modelClass in modelClasses"
              :key="modelClass.id"
            >
              <a
                role="menuitem"
                target="_self"
                href="#"
                class="dropdown-item"
                @click.prevent="chooseModelClass(modelClass)"
              >{{ modelClass.id }}: {{ modelClass.class_name }}</a>
            </li>
          </ul>
        </b-input-group>
      </b-form-group>
      <b-form-group
        v-if="showSelectedModelClassInfo"
        :state="!errors.model_id"
        :invalid-feedback="errors.model_id"
      >
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <div>
            <b>{{ modelClassSelected.class_name }}</b><br>
            <span class="small">{{ modelClassSelected.id }}</span>
          </div>
        </b-list-group-item>
      </b-form-group>

      <b-alert
        v-if="errors.model_class_id"
        show
        variant="danger"
      >
        {{ errors.model_class_id }}
      </b-alert>

      <div>
        <b-btn
          v-if="showSelectedModelClassInfo"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.add') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>
import ModelClassesService from '@/services/model-classes-service';
export default {
  name : 'ModelClassesAttach',
  props: {
    showCreate: {
      type: Boolean,
      default () {
        return false;
      },
    },
    campaign: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {
      errors                    : {},
      className                 : '',
      modelClassSelected        : {},
      modelClasses              : {},
      showDropDown              : false,
      showSelectedModelClassInfo: false,
    };
  },
  computed: {
    modelId () {
      return !_.isUndefined(this.$route.params.id) ? this.$route.params.id : false;
    },
  },
  watch: {
    'className' (value) {
      ModelClassesService.findByName(value, 15).then((response) => {
        this.modelClasses = response.data;
        if (this.modelClasses.length > 0) {
          this.showDropDown = true;
        } else {
          this.showDropDown = false;
        }
      });
    },
    modelClassSelected (item) {
      if (item.id) {
        this.showSelectedModelClassInfo = true;
      } else {
        this.showSelectedModelClassInfo = false;
      }
    },
  },
  methods: {
    chooseModelClass (item) {
      this.className = '';
      this.showDropDown = false;
      this.modelClassSelected = item;
    },
    attach () {
      if (this.modelId) {
        this._attach();
      } else {
        this._add();
      }
    },
    _add () {
      this.$emit('add', Object.assign({}, this.modelClassSelected));
    },
    _attach () {
      const data = {
        model_class_id: this.modelClassSelected.id,
        model_id      : this.modelId,
      };
      ModelClassesService.attachModel(data)
        .then(() => {
          this.errors = {};

          this.showSelectedModelClassInfo = false;
          this.modelClassSelected = {};
          this.$emit('attached', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.response.data.data !== 'undefined') {
            this.errors = error.response.data.data;
          }
          for (const item in error.response.data.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
      this.$emit('saved', true);
    },
  },
};
</script>

<style>
  .clips-menu {
    width: 100%;
  }
</style>
