<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <b-card header-tag="header">
          <div slot="header">
            <span><i class="fa icon-pencil" /> {{ $t('class_groups.class_list') }}</span>
          </div>
          <form style="padding-bottom: 5px;">
            <b-form-group
              :label="$t('global.name')"
              :state="!!errors.name ? false : null"
              :invalid-feedback="errors.name"
            >
              <b-form-input
                v-model="classGroup.name"
                type="text"
                :state="!!errors.name ? false : null"
              />
            </b-form-group>
            <b-form-group
              :label="$t('global.description')"
              :state="!!errors.description ? false : null"
              :invalid-feedback="errors.description"
            >
              <b-form-input
                v-model="classGroup.description"
                type="text"
                :state="!!errors.description ? false : null"
              />
            </b-form-group>
            <b-button
              size="sm"
              variant="primary"
              @click.prevent="save"
            >
              <i class="fa fa-dot-circle-o" /> {{ $t('global.save') }}
              <b-spinner
                v-if="loading"
                small
                label="Small Spinner"
                type="grow"
              />
            </b-button>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClassGroupService from '@/services/class-group-service';

export default {
  name: 'ClassGroupCreate',
  data () {
    return {
      loading: false,
      errors: {},
      classGroup: {
        name: '',
        description: '',
      },
    };
  },
  computed: {},
  watch: {},
  mounted () {},
  methods: {
    save() {
      this.loading = true;
      this.errors = {};

      ClassGroupService.store(this.classGroup)
        .then((response) => {
          this.$router.push({ name: 'ClassListsEdit', params: { id: response.data.id } });
        })
        .catch((error) => {
          if (typeof error.response.data.data !== 'undefined') {
            this.errors = error.response.data.data;
          }
          for (const item in error.response.data.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
