import { defaultMutations } from 'vuex-easy-access';
import { VuexTypes } from '../../types';
import CashDeskService from '@/services/cash-desk-service';
import RaspberryService from '@/services/raspberry-service';
import { CASH_DESK_STATES } from '@/constants';
import CashierConnectionHandler from '@/connection/CashierConnectionHandler';

const state = {
  list: [],
  loading: false,
  currentRaspberry: null,
};

const getters = {
  byId: (state) => (id) => {
    return state.list.find(e => e.id === id);
  },
};

const mutations = {
  [VuexTypes.CASHIER_LOAD_RASPBERRIES_IN_PROGRESS]: (state, value) => {
    state.loading = value ? true : false;
  },
  [VuexTypes.CASHIER_SET_RASPBERRIES]: (state, raspberries) => {
    state.list.splice(0);

    raspberries.forEach((raspberry) => {
      state.list.push(raspberry);
    });
  },

  [VuexTypes.CASHIER_SET_CURRENT_RASPBERRY]: (state, id) => {
    state.currentRaspberry = id;
  },
};

const actions = {
  [VuexTypes.CASHIER_LOAD_RASPBERRIES]: ({ commit, dispatch }) => {
    commit(VuexTypes.CASHIER_LOAD_RASPBERRIES_IN_PROGRESS, true);

    RaspberryService.list({ enabledOnly: true, has_cash_desk: true, with: ['current_order'] }).then((response) => {
      response.data.forEach(el => initConnections(el, dispatch));
      commit(VuexTypes.CASHIER_SET_RASPBERRIES, response.data);
      commit(VuexTypes.CASHIER_LOAD_RASPBERRIES_IN_PROGRESS, false);
    }).catch((err) => {
      console.log('err', err);
    });
  },

  [VuexTypes.CASHIER_SET_CURRENT_RASPBERRY]: ({ commit, dispatch }, id) => {
    commit(VuexTypes.CASHIER_SET_CURRENT_RASPBERRY, id);
  },
};

async function initConnections(raspberry, dispatch) {
  raspberry.connection = CashierConnectionHandler(raspberry);

  raspberry.connection.on('state', (data) => {
    if (!('raspberry_id' in data)) {
      data.raspberry_id = raspberry.id;
    }

    dispatch('cashierOrders/' + VuexTypes.CASHIER_ORDERS_ACTION_UPDATE, data, { root: true });
  });

  raspberry.connection.on('need_age_verification', (data) => {
    console.log('need_age_verification', data, raspberry);
  });

  raspberry.connection.on('tray_detected', (data) => {
    data.tray.raspberry_id = raspberry.id;
    dispatch('cashierTrays/' + VuexTypes.CASHIER_ADD_TRAY, data.tray, { root: true });
  });

  raspberry.connection.on('tray_processed', (tray) => {
    tray.raspberry_id = raspberry.id;
    dispatch('cashierTrays/' + VuexTypes.CASHIER_UPDATE_TRAY, tray, { root: true });
  });

  raspberry.connection.on('tray_update', (tray) => {
    tray.raspberry_id = raspberry.id;
    dispatch('cashierTrays/' + VuexTypes.CASHIER_UPDATE_TRAY, tray, { root: true });
  });

  raspberry.connection.on('tray_delete', (trayId) => {
    dispatch('cashierTrays/' + VuexTypes.CASHIER_TRAYS_ACTION_DELETE_TRAY, trayId, { root: true });
  });
}

export default {
  state,
  mutations,
  actions,
  getters,
};
