<template>
  <div class="container-fluid cash-desk-statuses-bar">
    <div :class="['col-2', 'my-2 p-0', 'statuses-free', { active: free }]">
      <p>{{ free }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.free') }}</p>
    </div>
    <div :class="['col-2', 'my-2 p-0', 'statuses-occupied', { active: occupied }]">
      <p>{{ occupied }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.occupied') }}</p>
    </div>
    <div :class="['col-2', 'my-2 p-0', 'statuses-help-needed', { active: helpNeeded }]">
      <p>{{ helpNeeded }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.helpNeeded') }}</p>
    </div>
    <div :class="['col-2', 'my-2 p-0', 'statuses-someone-helping', { active: someoneHelping }]">
      <p>{{ someoneHelping }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.someoneHelping') }}</p>
    </div>
    <div :class="['col-2', 'my-2 p-0', 'statuses-verify-age', { active: verifyAge }]">
      <p>{{ verifyAge }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.verifyAge') }}</p>
    </div>
    <!-- <div :class="['col-2', 'my-2', 'statuses-out-of-service', { active: outOfService }]">
      <p>{{ outOfService }}</p>
      <p>{{ $t('cashier.grid.cashDeskStatusesBar.outOfService') }}</p>
    </div> -->
  </div>
</template>

<script>

import { CASH_DESK_STATES } from '@/constants';
import { mapState } from 'vuex';

export default {
  name      : 'CashDeskStatusesBar',
  components: {
  },
  data () {
    return {
      free: null,
      occupied: null,
      helpNeeded: null,
      someoneHelping: null,
      verifyAge: null,
      // outOfService: null,
    };
  },
  computed: {
    ...mapState('cashierRaspberries', {
      rasberryList: 'list',
    }),
    ...mapState('cashierOrders', {
      ordersByRaspberry: 'byRaspberry',
    }),
  },
  watch: {
    rasberryList: {
      immediate: true,
      deep: true,
      handler: function () {
        this.recalculate();
      },
    },
    ordersByRaspberry: { 
      deep: true,
      handler: function () {
        this.recalculate();
      },
    },
  },
  methods: {
    recalculate () {
      // let outOfService = 0;
      let free = 0;
      let verifyAge = 0;
      let occupied = 0;
      let helpNeeded = 0;
      let someoneHelping = 0;

      this.rasberryList.forEach(raspberry => {
        // if (!raspberry.connection) {
        //   outOfService++;
        //   return;
        // }

        let order = this.ordersByRaspberry[raspberry.id];
        if (!order || order.state === CASH_DESK_STATES.VOIDED || order.state === CASH_DESK_STATES.PAID) {
          free++;
          return;
        }

        if (order.state === CASH_DESK_STATES.VERIFY_AGE) {
          verifyAge++;
          return;
        }

        if (order.state === CASH_DESK_STATES.IN_PROGRESS) {
          occupied++;
          return;
        }

        if (order.state === CASH_DESK_STATES.ASSISTANCE) {
          helpNeeded++;
          return;
        }
        if (order.state === CASH_DESK_STATES.LOCKED) {
          someoneHelping++;
          return;
        }
      });

      // this.outOfService = outOfService;
      this.free = free;
      this.verifyAge = verifyAge;
      this.occupied = occupied;
      this.helpNeeded = helpNeeded;
      this.someoneHelping = someoneHelping;
    },
  },
};
</script>

<style lang="scss" scoped>
  .cash-desk-statuses-bar {
    display: flex;
    justify-content: space-around;
    border: 1px solid #DEDEDE;
    border-radius: 10px;

    >div {
      text-align: center;
      color: #DEDEDE;
      font-weight: 800;
      font-size: 30px;
      white-space: nowrap;

      &.statuses {
        &-free.active {
          color: #759F01;
        }
        &-occupied.active {
          color: #000000;
        }
        &-help-needed.active {
          color: #B91F31;
        }
        &-someone-helping.active {
          color: #000000;
        }
        &-verify-age.active {
          color: #2F5466;
        }
        // &-out-of-service.active {
        //   color: #777777;
        // }
      }

      p {
        margin: 0;
      }

      >p:first-child {
        font-size: 30px;
        line-height: 41px;
      }

      >p:nth-child(2) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
</style>
