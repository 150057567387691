const processEnv = typeof process !== 'undefined' ? process.env : {};
const injectedEnv = window && window.injectedEnv ? window.injectedEnv : {};

const ENV = {
  /* Put defaults here */

  VUE_APP_ENV: 'test', // test or prod

  /* environment will override defaults here */
  ...processEnv,
  ...injectedEnv,

  /* Put overrides here */
};

window.ENV = ENV;

export default ENV;
