import Api from '@/services/api';

export default {
  list(currentPage, extraParams = {}) {
    const params = Object.assign({ page: currentPage }, extraParams);
    return Api().get('/product_types', {
      params: params,
    });
  },
  listAll() {
    return Api().get('/product_types');
  },
  getAll() {
    return Api().get('/product_types');
  },
  get(id) {
    return Api().get(`/product_types/${id}`);
  },
  store(data) {
    return Api().post('/product_types', data);
  },
  update(data) {
    return Api().put(`/product_types/${data.id}`, data);
  },
  delete(id) {
    return Api().delete(`/product_types/${id}`);
  },
  segmentedImages(id, filters = {}) {
    return Api().post(`/product_types/${id}/segmented_images`, { filters }, { params: { page: filters.currentPage } });
  },
  classGroups(id) {
    return Api().get(`/product_types/${id}/class_groups`);
  },
  relatedModels(id) {
    return Api().get(`/product_types/${id}/related_models`);
  },
  exportXlsx() {
    return Api().get('/product_types/export/xlsx', {
      responseType: 'blob',
    });
  },
  exportCsv() {
    return Api().get('/product_types/export/csv', {
      responseType: 'blob',
    });
  },
};
