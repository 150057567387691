<template>
  <b-card
    class="bg-light-green"
    header-tag="header"
  >
    <div slot="header">
      <span>{{ $t('grab_data.tray_segmentations') }}</span> <span :class="{'bg-danger': tray && traySent && traySent !== tray.id}">{{ tray ? tray.id : '' }}</span>
    </div>
    <form v-if="tray">
      <b-form-checkbox
        v-model="tray.segmentation_status"
        value="good"
        class="mb-2"
        unchecked-value="not_validated"
      >
        {{ $dt('ai.segmentation_status', 'Segmentation status') }} 
        {{ $dt('ai.good', 'OK') }} 
      </b-form-checkbox>
      <b-form-group
        :label="$t('ai.annotation_status')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-select
          v-if="tray"
          v-model="tray.annotation_status"
          :options="annotationStatuses"
        />
      </b-form-group>
      <table
        v-if="tray && tray.segmented_images"
        class="table table-sm table-bordered"
      >
        <thead>
          <tr>
            <th scope="col">
              ID
            </th>
            <th scope="col">
              {{ $t('global.product_types') }}
            </th>
            <th>{{ $t('global.actions') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in tray.segmented_images"
            :key="item.id"
          >
            <th :style="{backgroundColor: item.color}">
              {{ item.id }}
            </th>
            <td>
              <vue-tags-input
                v-model="tag[item.id].text"
                :tags="SIType[item.id]"
                :placeholder="''"
                :add-only-from-autocomplete="true"
                :autocomplete-items="autocompleteItems"
                :max-tags="1"
                @focus="$emit('itemSelected', item.id)"
                @blur="$emit('selectionLost')"
                @tags-changed="(newTags) => tagsChanged(newTags, item.id)"
              />
            </td>
            <td>
              <b-button-group>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="$emit('removeSi', item)"
                >
                  <i class="fa fa-times" />
                </b-button>
                <b-button
                  v-if="!drawing"
                  variant="info"
                  size="sm"
                  @click="$emit('draw', item)"
                >
                  <i class="fa fa-draw-polygon" />
                </b-button>
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
    <b-button
      v-if="tray"
      :title="$t('grab_data.add_segmentation')"
      @click="createNewSegmentedImage"
    >
      <i class="fas fa-plus" />
    </b-button>
    <b-row v-if="tray">
      <b-col>
        <b-button
          id="dropdown-dropright"
          split
          dropright
          variant="primary"
          class="m-2"
          @click="$emit('next', nextStatus)"
        > 
          {{ $t('grab_data.next_with_status', { status: $t(`ai.${nextStatus}`) }) }}
        </b-button>
      </b-col>
      <b-col>
        <b-btn
          class="pull-right"
          variant="success"
          style="margin: 0.5rem;"
          @click="save(true)"
        >
          {{ $t('global.save') }}
          <b-spinner
            v-if="isLoading || parentLoading"
            small
            type="grow"
            style="margin-top: 0;"
          />
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import TraysService from '@/services/trays-service.js';
import ProductTypesService from '@/services/product-types-service';
import SegmentedImagesService from '@/services/segmented-images-service';

export default {
  name : 'GrabDataForm',
  props: {
    model: {
      type    : Object,
      required: false,
      default () {
        return null;
      },
    },
    traySent: {
      type    : Number,
      required: false,
      default () {
        return null;
      },
    },
    drawing: {
      type    : Boolean,
      required: true,
      default () {
        return false;
      },
    },
    parentLoading: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      tray             : null,
      SIType           : [],
      errors           : {},
      types            : [],
      autocompleteItems: [],
      tag              : [],
      oldTag           : [],
      loading          : {
        update     : false,
        listAll    : false,
        assignTypes: false,
        store      : false,
      },
      // this is ugly and sad - too bad!
      trayAnnotationStatuses: [ 'not_annotated', 'being_annotated', 'is_annotated' ],
    };
  },
  computed: {
    annotationStatuses() {
      return this.trayAnnotationStatuses.map(status => ({ value: status, text: this.$t(`ai.${status}`)}));
    },
    nextStatus() {
      return this.$route.query?.nextStatus || this.tray.annotation_status;
    },
    isLoading () {
      let isLoading = false;
      _.forEach(this.loading, (item) => {
        if (item) {
          isLoading = true;
          return false;
        }
      });
      return isLoading;
    },
  },
  watch: {
    model: {
      handler () {
        if (!this.model) {
          if (this.tray && this.tray.status === 'processing') {
            this.tray.status = 'created';
            TraysService.update(this.tray);
          }
          this.tray = null;
          return false;
        }
        if (this.tray && this.tray.id !== this.model.id && this.tray.status === 'processing') {
          this.tray.status = 'created';
          TraysService.update(this.tray);
        }
        _.forEach(this.model.segmented_images, (item) => {
          this.$set(this.oldTag, item.id, { id: item.id, text: '' });
          this.$set(this.tag, item.id, { id: item.id, text: '' });
          if (item.product_type) {
            this.$set(this.SIType, item.id, [
              {
                id  : item.product_type.id,
                text: item.product_type.product && item.product_type.product.code ? `${item.product_type.product.code} - ${item.product_type.name}` : `${item.product_type.name}`,
              },
            ]);
          } else if (this.SIType[item.id]) {
            this.$set(this.SIType, item.id, this.SIType[item.id]);
          } else {
            this.$set(this.SIType, item.id, []);
          }
        });
        this.tray = this.model;
        if (this.model.status === 'created') {
          this.loading.update = true;
          TraysService.update(this.tray).then(() => {
            this.loading.update = false;
          }).catch(() => {
            this.loading.update = false;
          });
        }
      },
      deep: true,
    },
    tag: {
      handler (after) {
        const changed = after.filter((p, idx) => {
          return Object.keys(p).some((property) => {
            return p[property] !== this.oldTag[idx][property];
          });
        });
        this.oldTag = _.cloneDeep(this.tag);
        if (changed.length > 0 && this.SIType[changed[0].id].length === 0) {
          this.prepareAutoComplete(changed[0].text);
        } else {
          this.autocompleteItems = [];
        }
      },
      deep: true,
    },
  },
  mounted () {
    this.loading.listAll = true;
    ProductTypesService.listAll().then((response) => {
      this.loading.listAll = false;
      this.types = response.data;
    }).catch(() => {
      this.$notify({
        type : 'error',
        title: this.$t('global.error'),
        text : this.$t('global.connection_lost_service', { service: 'ProductTypesService.listAll' }),
      });
      this.loading.listAll = false;
    });
  },
  methods: {
    saveAnnotationStatus() {
      TraysService.update({
        id              : this.tray.id,
        annotation_status: this.tray.annotation_status,
      }).catch(() => {
        this.$notify({
          type : 'error',
          title: this.$t('global.error'),
          text : this.$t('global.connection_lost_service', { service: 'TraysService.update' }),
        });
        this.loading.update = false;
      });
    },
    save () {
      if (this.isLoading || this.parentLoading) {
        setTimeout(() => {
          this.save();
        }, 200);
        return false;
      }
      _.forEach(this.model.segmented_images, (item, index) => {
        if (this.SIType[item.id].length > 0) {
          this.tray.segmented_images[index].product_type_id = this.SIType[item.id][0].id;
        } else {
          this.tray.segmented_images[index].product_type_id = null;
        }
      });
      this.loading.assignTypes = true;
      SegmentedImagesService.assignTypes({ segmented_images: this.model.segmented_images }).then(() => {
        this.loading.assignTypes = false;
        this.loading.update = true;
        TraysService.update(this.tray).then(() => {
          this.loading.update = false;
          this.$notify({
            type : 'success',
            title: 'Saved',
          });
          this.$emit('saved', this.model.id);
        }).catch(() => {
          this.$notify({
            type : 'error',
            title: this.$t('global.error'),
            text : this.$t('global.connection_lost_service', { service: 'TraysService.update' }),
          });
          this.loading.update = false;
        });
      }).catch(() => {
        this.$notify({
          type : 'error',
          title: this.$t('global.error'),
          text : this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.assignTypes' }),
        });
        this.loading.assignTypes = false;
      });
    },
    prepareAutoComplete (value) {
      this.autocompleteItems = this.types.filter((a) => {
        if (a.name.toLowerCase().includes(value.toLowerCase())
          || (a.product && a.product.code && a.product.code.toString().includes(value.toLowerCase()))) {
          return true;
        }
      }).map((a) => {
        return {
          text: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
          id: a.id,
        };
      });
    },
    tagsChanged (newTags, itemId) {
      this.$set(this.SIType, itemId, newTags);
    },
    createNewSegmentedImage () {
      if (!this.tray) {
        return false;
      }
      this.loading.store = true;
      SegmentedImagesService.store({ tray_image_id: this.tray.id }).then(() => {
        this.loading.store = false;
        this.$emit('saved', this.tray.id);
      }).catch(() => {
        this.$notify({
          type : 'error',
          title: this.$t('global.error'),
          text : this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.store' }),
        });
        this.loading.store = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .vue-tags-input ul li.ti-new-tag-input-wrapper {
    display: none !important;
  }
  .vue-tags-input ul li.ti-new-tag-input-wrapper:first-child {
    display: flex !important;
  }
</style>
