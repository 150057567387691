import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/class_group_items?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/class_group_items/${id}`);
  },
  store (data) {
    return Api().post('/class_group_items', data);
  },
  update (data) {
    return Api().put(`/class_group_items/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/class_group_items/${id}`);
  },
};
