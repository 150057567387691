import svgJs from 'svg.js';
// do NOT delete these imports - they add several functions
// to svgJs at the time of importing them. the authors of
// 'svg.*.js' deserve a medal for peak stupidity.
/* eslint-disable no-unused-vars */
import svgDraw from 'svg.draw.js';
import svgSelect from 'svg.select.js';
import svgResize from 'svg.resize.js';
/* eslint-enable no-unused-vars */

export default {
  install: (Vue) => {
    Vue.prototype.$svg = svgJs;
  },
};
