import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/orders?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/orders/${id}`);
  },
  store (data) {
    return Api().post('/orders', data);
  },
  update (data) {
    return Api().put(`/orders/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/orders/${id}`);
  },
  getActiveStates(raspberryIds) {
    return Api().get('/orders/states', { params: {raspberry_id: raspberryIds} });
  },
  changeState(orderId, state) {
    return Api().put('/cashier/orders/change_state', { order_id: orderId, state });
  },
  voidOrder(orderId) {
    return Api().delete(`/cashier/orders/${orderId}/void`);
  },
  verifyAge(raspberryId) {
    return Api().put('/cashier/orders/verify_age', { raspberry_id: raspberryId });
  },
  getCurrent(raspberryIds) {
    return Api().get('/orders/current', { params: { raspberry_ids: raspberryIds }});
  },
};
