<template>
  <div>
    <b-img
      v-if="product.image"
      class="product-img"
      :src="product.image"
    />
    <b-img
      v-else
      class="product-img"
      :src="require('@/static/img/human_product_placeholder.jpg')"
    />
    <div class="product-description pb-2 align-self-center">
      <div class="my-1 product-name-container">
        <span class="product-name">{{ getProductName(product) }}</span>
      </div>
      <p class="mb-0 product-price">
        {{ product.price | toPrice }}
      </p>
    </div>
  </div>
</template>

<script>
import { getProductName } from '@/helpers/tray';

export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      getProductName,
    };
  },
};


</script>

<style lang="scss" scoped>
.product-img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .product-description {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: #ebebeb;
    border-style: ridge;
    border-width: 1px;
    font-size: 14px;
    height: 80px;
    margin-bottom: 15px;
  }

  .product-name-container {
    display: flex;
    width: auto;
    height: 38px;
    margin: 20px;    
    justify-content: center;
    align-items: center;
  }

  .product-name {
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-price {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-family: "Avenir";
  }

</style>