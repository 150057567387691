<template>
  <b-nav-item-dropdown
    right
    no-caret
  >
    <template slot="button-content">
      <span class="profile-menu-btn text-uppercase">
        {{ i18n.locale }}
      </span>
    </template>
    <b-dropdown-header
      tag="div"
      class="text-center"
    >
      <strong>{{ $t('global.language') }}</strong>
    </b-dropdown-header>

    <b-dropdown-item 
      v-for="activeLanguage in activeLanguages"
      :key="activeLanguage.key"
      @click="setLanguage(activeLanguage.key)"
    >
      {{ activeLanguage.name }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { i18n, loadLang, setI18nLanguage } from '@/_translate';
import { mapGetters, mapActions } from 'vuex';
import { VuexTypes } from '@/store/types';

export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      i18n: i18n,
    };
  },
  computed: {
    ...mapGetters([
      'activeLanguages',
    ]),
  },
  mounted() {
    this[VuexTypes.LANGUAGES_CLASSIFICATOR]();
  },
  methods: {
    ...mapActions([
      VuexTypes.LANGUAGES_CLASSIFICATOR,
    ]),
    setLanguage(lang) {
      setI18nLanguage(lang);
      loadLang(lang, true);
    },
  },
};
</script>

<style>
.profile-menu-btn {
  padding: 0 1em;
}
</style>
