<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('users.username')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="user.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.email')"
        :state="!errors.email"
        :invalid-feedback="errors.email"
      >
        <b-form-input
          v-model="user.email"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.password')"
        :state="!errors.password"
        :invalid-feedback="errors.password"
      >
        <b-form-input
          v-model="user.password"
          type="password"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.password_confirmation')"
        :state="!errors.password_confirmation"
        :invalid-feedback="errors.password_confirmation"
      >
        <b-form-input
          v-model="user.password_confirmation"
          type="password"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.roles')"
        :state="!errors.roles"
        :invalid-feedback="errors.roles"
      >
        <b-form-radio-group
          id="checkbox-group-1"
          v-model="role"
          :options="users.roles.data"
          name="roles"
          value-field="id"
          text-field="name"
          stacked
        />
      </b-form-group>
      <b-form-group
        v-if="showCashDeskInput"
        :label="$t('users.cash_desks')"
        :state="!errors.cash_desks"
        :invalid-feedback="errors.cash_desks"
      >
        <cash-desk-select 
          v-model="user.cash_desks"
          :multiple="canHaveManyCashDesks" 
        />
      </b-form-group>
      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';
import CashDeskSelect from './CashDeskSelect.vue';

export default {
  name: 'UserForm',
  components: { CashDeskSelect },

  props: {
    value: {
      type    : Object,
      required: false,
      default () {
        return { enabled: false };
      },
    },
    showCancel: {
      type    : Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },
  data () {
    return {
      user: _.cloneDeep(this.value),
      errors: {},
      role  : null,
      showCashDeskInput   : false,
      canHaveManyCashDesks: true,
    };
  },
  computed: {
    ...mapState(['users']),
    isEditing () {
      return typeof this.user.id !== 'undefined' && this.user.id > 0;
    },
  },
  watch: {
    value(newVal) {
      this.user = newVal;
    },
    'user.roles': {
      immediate: true,
      handler() {
        this.role = this.user.roles && this.user.roles[0] ? this.user.roles[0].id : null;
      },
    },
    role: {
      immediate: true,
      handler() {
        this.needCashDeskInput();
      },
    },
    'users.roles.data': {
      immediate: true,
      handler() {
        this.needCashDeskInput();
      },
    },
  },
  mounted () {
    this.loadRoles(); // Required to list all available roles in form
  },
  methods: {
    ...mapActions({
      loadRoles    : VuexTypes.ROLES_LIST,
      add          : VuexTypes.USERS_ADD,
      update       : VuexTypes.USERS_UPDATE,
    }),
    closeClick () {
      this.$emit('saved', false);
    },
    save () {
      let params = {
        ...this.user, 
        cash_desks: this.user.cash_desks ? this.user.cash_desks.map(el => el.id) : [],
        roles: this.role ? [this.role] : [],
      };
   
      const action = this.isEditing ? this.update(params) : this.add(params);
      
      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    needCashDeskInput() {
      const rolesWithCahsDesks = ['CASHIER','CUSTOMER'];
      const role = this.users.roles.data.find(el => el.id === this.role);

      if (!role) {
        this.showCashDeskInput = false;
        return;
      }

      this.showCashDeskInput = rolesWithCahsDesks.includes(role.name);
      this.canHaveManyCashDesks = role.name === 'CASHIER';
    },
  },
};
</script>
<style>
</style>
