import io from 'socket.io-client';

export default {
  async getConnection({ auth, channel }) {
    return io(window.ENV.VUE_APP_SOCKET_URL + channel, {
      path: window.ENV.VUE_APP_SOCKET_PATH,
      secure: false,
      auth: auth,
    });
  },
};