<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col :sm="isShowingForm ? '6' : '12'" :md="isShowingForm ? '8' : '12'" :lg="isShowingForm ? '9' : '12'">
        <b-card header-tag="header" class="mb-m1">
          <div slot="header">
            <span><i class="fa icon-pencil" /> {{ $t('class_groups.class_list') }}</span>
          </div>
          <form style="padding-bottom: 5px;">
            <b-form-group :label="$t('global.name')" :state="!!errors.name ? false : null"
              :invalid-feedback="errors.name">
              <b-form-input v-model="classGroup.name" type="text" :state="!!errors.name ? false : null" />
            </b-form-group>
            <b-form-group :label="$t('global.description')" :state="!!errors.description ? false : null"
              :invalid-feedback="errors.description">
              <b-form-input v-model="classGroup.description" type="text" :state="!!errors.description ? false : null" />
            </b-form-group>
            <b-button size="sm" variant="primary" @click.prevent="save">
              <i class="fa fa-dot-circle-o" /> {{ $t('global.save') }}
              <b-spinner v-if="loading" small label="Small Spinner" type="grow" />
            </b-button>
          </form>
        </b-card>
        <b-card header-tag="header">
          <div slot="header">
            <span>{{ $t('class_groups.classes') }}</span>
            <b-button class="pull-right" size="sm" variant="success"
              @click.prevent="changeActiveForm('classForm', true)">
              <i class="fa fa-plus" /> {{ $t('global.add_new') }}
            </b-button>
          </div>
          <b-alert v-if="!classGroup.class_group_items.length" show variant="secondary" class="mb-0 text-center">
            {{ $t('global.no_data') }}
          </b-alert>
          <b-table v-else striped responsive bordered head-variant="light" :items="classGroup.class_group_items"
            :fields="tableFields.classGroupItems" class="table-custom">
            <template #cell(next_model)="row">
              <navigation-badge v-if="row.value" :to="{ name: 'ModelsEdit', params: { id: row.value.id } }"
                variant="badge-purple">
                {{ row.value.id }} - {{ row.value.name }}
              </navigation-badge>
            </template>
            <template #cell(product_types)="row">
              <navigation-badge v-for="productType in row.value"
                :key="`class-group-item-${productType.pivot.class_group_item_id}-product-type-${productType.pivot.product_type_id}`"
                :to="{ name: 'ProductTypesView', params: { id: productType.id } }" variant="badge-blue">
                {{ productType.product && productType.product.code ? `${productType.product.code} - ` : `` }}{{
                productType.name }}
              </navigation-badge>
            </template>
            <template #cell(actions)="row">
              <button v-if="can('super-admin', 'cvision-admin')" type="button"
                class="btn btn-success btn-sm table-button-size" @click="editGroupItem(row.item)">
                <i class="fa icon-pencil" /> {{ $t('global.edit') }}
              </button>
              <button v-if="can('super-admin', 'cvision-admin')" type="button"
                class="btn btn-warning btn-sm table-button-size" @click="deleteGroupItem(row.item)">
                <i class="fa fa-trash" /> {{ $t('global.delete') }}
              </button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col v-if="isShowingForm" ref="activeForm" sm="6" md="4" lg="3">
        <classes-form :model="addItem" :show-cancel="true" @saved="classesFormSaved" @canceled="classesFormCanceled" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClassGroupService from '@/services/class-group-service';
import ClassGroupItemService from '@/services/class-group-item-service';
import ClassesForm from './components/ClassesForm';
import NavigationBadge from '@/components/NavigationBadge';

export default {
  name: 'ClassGroupEdit',
  components: { ClassesForm, NavigationBadge },
  data() {
    return {
      activeForm: null,
      loading: false,
      errors: {},
      addItem: {
        product_types: [],
      },
      classGroup: {
        name: '',
        description: '',
        class_group_items: [],
      },
      tableFields: {
        classGroupItems: [
          { key: 'id', label: '#' },
          { key: 'name', label: this.$t('global.name') },
          { key: 'next_model', label: this.$t('class_groups.next_model') },
          { key: 'product_types', label: this.$t('global.product_types') },
          { key: 'actions', label: this.$t('global.actions'), thClass: 'fit', tdClass: 'fit' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['can']),
    showClassForm() {
      return this.activeForm === 'classForm';
    },
    isShowingForm() {
      return this.showClassForm;
    },
  },
  watch: {},
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.$route.params.id) {
        ClassGroupService.get(this.$route.params.id)
          .then((response) => {
            this.classGroup = response.data;
          })
          .catch((error) => {
            console.error(error.response);
          });
      }
    },
    save() {
      this.loading = true;
      this.errors = {};

      ClassGroupService.update(this.classGroup)
        .then((response) => {
          this.classGroup = response.data;
        })
        .catch((error) => {
          if (typeof error.response.data.data !== 'undefined') {
            this.errors = error.response.data.data;
          }
          for (const item in error.response.data.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    classesFormSaved() {
      this.load();
      this.changeActiveForm(null);
      this.addItem = { product_types: [] };
    },
    classesFormCanceled() {
      this.changeActiveForm(null);
      this.addItem = { product_types: [] };
    },
    editGroupItem(item) {
      this.changeActiveForm('classForm');
      this.addItem = JSON.parse(JSON.stringify(item));
    },
    deleteGroupItem(item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        ClassGroupItemService.delete(item.id)
          .then(() => {
            this.load();
            if (this.addItem.id && this.addItem.id === item.id) {
              this.changeActiveForm(null, true);
            }
          })
          .catch((error) => {
            console.error(error.response);
            this.load();
          });
      }
    },
    changeActiveForm(formName = null, clearData = false) {
      if (clearData) {
        this.addItem = { product_types: [] };
      }

      this.activeForm = formName;

      if (formName) {
        this.$nextTick(() => {
          this.$refs.activeForm.scrollIntoView({ behavior: 'smooth', alignToTop: true });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-m1 {
  margin-bottom: -1px;
}

.table-custom ::v-deep .table {

  td,
  th {
    &.fit {
      white-space: nowrap;
      width: 1%;
    }
  }

  th {
    white-space: nowrap;
  }
}
</style>
