import Vue from 'vue';

export default {
  items: [
    {
      name: Vue.prototype.$tk('nav.home_title'),
      url: '/home',
      icon: 'fa fa-home',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.raspberry_title'),
      url: '/raspberry',
      icon: 'fa fa-video',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.cash_desk_title'),
      url: '/cash_desk',
      icon: 'fas fa-cash-register',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.human_products_title'),
      url: '/human_products',
      icon: 'fas fa-hamburger',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.combo'),
      url: '/combo',
      icon: 'fas fa-box',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.categories'),
      url: '/categories',
      icon: 'fas fa-folder-open',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.trays_title'),
      url: '/trays/list',
      icon: 'far fa-object-group',
      hasAny: ['super-admin', 'cvision-admin'],
    },
    {
      name: Vue.prototype.$tk('nav.raspberry_emulator_title'),
      url: '/raspberry_emulator',
      icon: 'icon-speedometer',
      hasAny: ['raspberry'],
    },
    {
      name: Vue.prototype.$tk('nav.ai'),
      icon: 'fas fa-magic',
      class: 'group-5',
      hasAny: ['super-admin', 'cvision-admin'],
      children: [
        {
          name: Vue.prototype.$tk('nav.models_title'),
          url: '/models/list',
          icon: 'fas fa-brain',
          hasAny: ['super-admin', 'cvision-admin'],
        },
        {
          name: Vue.prototype.$tk('nav.product_types_title'),
          url: '/product_types',
          icon: 'fas fa-hamburger',
          hasAny: ['super-admin', 'cvision-admin', 'data-grab'],
        },
        {
          name: Vue.prototype.$tk('nav.segmented_images_title'),
          url: '/segmented_images',
          icon: 'fas fa-images',
          hasAny: ['super-admin', 'cvision-admin'],
        },
        {
          name: Vue.prototype.$tk('nav.grab_data_title'),
          url: '/grab_data',
          icon: 'fab fa-connectdevelop',
          hasAny: ['super-admin', 'cvision-admin', 'data-grab'],
        },
        {
          name: Vue.prototype.$tk('nav.class_lists_title'),
          url: '/class_lists',
          icon: 'fas fa-list',
          hasAny: ['super-admin', 'cvision-admin', 'data-grab'],
        },
      ],
    },
    {
      name: Vue.prototype.$tk('nav.system_title'),
      icon: 'icon-settings',
      class: 'group-5',
      hasAny: ['super-admin'],
      children: [
        {
          name: Vue.prototype.$tk('nav.users_title'),
          url: '/users',
          icon: 'fa fa-user',
          hasAny: ['super-admin'],
        },
        {
          name: Vue.prototype.$tk('nav.languages_title'),
          url: '/system/languages',
          icon: 'fas fa-globe',
          hasAny: ['super-admin'],
        },
        {
          name: Vue.prototype.$tk('nav.translations_title'),
          url: '/system/translations',
          icon: 'fa fa-language',
          hasAny: ['super-admin'],
        },
        {
          name: Vue.prototype.$tk('nav.themes_title'),
          url: '/system/themes',
          icon: 'fas fa-image',
          hasAny: ['super-admin'],
        },
      ],
    },
    {
      name: 'Debug',
      icon: 'fas fa-wrench',
      class: 'group-5',
      hasAny: ['super-admin'],
      children: [
        {
          name: Vue.prototype.$tk('nav.cashier_title'),
          url: '/cashier/list',
          icon: 'fa fa-tablet',
          hasAny: ['super-admin', 'cashier'],
        },
        {
          name: 'Orders',
          url: '/orders',
          icon: 'fas fa-circle',
          hasAny: ['super-admin'],
        },
        {
          name: 'Segmented Images',
          url: '/segmented_images',
          icon: 'fas fa-circle',
          hasAny: ['super-admin'],
        },
        {
          name: 'HumanProducts',
          url: '/human_products',
          icon: 'fas fa-circle',
          hasAny: ['super-admin'],
        },
        {
          name: 'Tray products',
          url: '/tray_products',
          icon: 'fas fa-circle',
          hasAny: ['super-admin'],
        },
      ],
    },
  ],
};
