<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="isShowingForm ? '6' : '12'"
        :md="isShowingForm ? '8' : '12'"
        :lg="isShowingForm ? '9' : '12'"
      >
        <b-card header-tag="header">
          <div slot="header">
            <span><i class="fa fa-bullhorn" /> {{ $t('models.model') }} </span>
          </div>

          <b-alert
            variant="success"
            dismissible
          >
            {{ $t('models.model_created') }}
          </b-alert>

          <b-alert
            variant="success"
            :show="updated"
            dismissible
            @dismissed="updated=false"
          >
            {{ $t('models.model_updated') }}
          </b-alert>

          <models-form
            v-if="item"
            v-model="item"
            :errors="errors"
            :percent="modelPercent"
            @saved="loadModels"
          />
          <models-components-form
            v-if="item"
            v-model="item"
            :errors="errors"
            :can-edit-classes="canEditClasses"
            :can-delete-classes="canDeleteClasses"
            @fillModelClass="setModelClass"
            @fillModelTraining="setModelTraining"
            @showModelClassForm="changeActiveForm('classesForm', true)"
            @showModelClassAttach="changeActiveForm('classesAttach', true)"
            @showTrainingForm="changeActiveForm('trainingForm', true)"
            @showClassListsForm="changeActiveForm('classesListsForm', true)"
            @aggregate="aggregate"
          />

          <div slot="footer" />
        </b-card>
      </b-col>
      <b-col
        v-if="modelId && isShowingForm"
        ref="modelClassForm"
        sm="6"
        md="4"
        lg="3"
      >
        <model-classes-form
          v-if="showModelClassesForm"
          :model="modelClass"
          :view="modelId ? 'modelEdit' : 'modelCreate'"
          :show-cancel="true"
          :model-status="item.status"
          @add="addModelClass"
          @saved="addModelClass"
          @canceled="setModelClass"
          @create="clearModelClassForm"
        />
        <model-classes-attach
          v-if="showModelClassesAttach"
          :show-create="true"
          @add="addModelClass"
          @attached="classAttached"
          @create="showModelClassForm = true"
          @cancel="activeForm = null"
        />
        <model-training-form
          v-if="showModelTrainingForm"
          :model="item"
          :training="modelTraining"
          :all-trainings="item.model_training"
          :model-id="modelId"
          :show-cancel="true"
          @cancel="activeForm = null"
          @saved="addModelTraining"
        />
        <classes-lists-form
          v-if="showClassesListsForm"
          :model-id="modelId"
          @cancel="activeForm = null"
          @saved="importedClasses"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';
import ModelsForm from './components/ModelsForm';
import ModelClassesAttach from '../CRUD/ModelClasses/components/ModelClassesAttach';
import ModelClassesForm from '../CRUD/ModelClasses/components/ModelClassesForm';
import ModelsService from '@/services/models-service';
import ModelTrainingForm from '../CRUD/ModelTrainings/components/ModelTrainingForm';
import ModelsComponentsForm from './components/ModelsComponentsForm';
import ClassesListsForm from './components/ClassesListsForm';

export default {
  name      : 'ModelsCreate',
  components: {
    ModelsForm, ModelClassesAttach, ModelClassesForm, ModelTrainingForm, ModelsComponentsForm, ClassesListsForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {
      modelClass   : {},
      modelTraining: { config: {} },
      activeForm           : null,
      errors               : {},
      updated              : false,
      loading              : false,
      modelPercent         : '0 %',
    };
  },
  computed: {
    ...mapState(['auth', 'models']),
    modelId () {
      return this.id;
    },
    item() {
      if (this.models?.list?.find) {
        return this.models.list.find(m => m.id === this.modelId);
      }
      return null;
    },
    canAttachClasses () {
      const allowed = ['created', 'failed'];
      if (!allowed.includes(this.item?.status)) {
        return false;
      }
      return true;
    },
    canEditClasses () {
      const allowed = ['created', 'failed', 'ready'];
      if (!allowed.includes(this.item?.status)) {
        return false;
      }
      return true;
    },
    canDeleteClasses () {
      const allowed = ['created', 'failed', 'ready'];
      if (!allowed.includes(this.item?.status)) {
        return false;
      }
      return true;
    },
    showModelClassesForm () {
      return this.canEditClasses && this.activeForm === 'classesForm';
    },
    showModelClassesAttach () {
      return this.canAttachClasses && this.activeForm === 'classesAttach';
    },
    showModelTrainingForm () {
      return this.activeForm === 'trainingForm';
    },
    showClassesListsForm () {
      return this.activeForm === 'classesListsForm';
    },
    isShowingForm () {
      return this.showModelClassesForm || this.showModelClassesAttach || this.showModelTrainingForm || this.showClassesListsForm;
    },
  },
  watch: {
    item(val) {
      if (val?.status === 'ready') {
        this.modelPercent = '100 %';
      }
    },
  },
  mounted () {
    this.loadModels();
  },
  methods: {
    ...mapActions({
      add   : VuexTypes.MODELS_ADD,
      update: VuexTypes.MODELS_UPDATE,
      loadModels: VuexTypes.MODELS_LIST,
    }),
    closeClick () {
      this.$emit('saved', false);
    },

    addModelClass () {
      this.loadModels();
    },
    addModelTraining () {
      this.loadModels();
    },
    setModelClass (modelClass) {
      this.changeActiveForm(null, true);
      this.modelClass = !_.isUndefined(modelClass) ? Object.assign({}, modelClass) : { product_types: [] };
      if (this.modelClass.class_name) {
        this.changeActiveForm('classesForm');
      }
      
    },
    clearModelClassForm () {
      this.changeActiveForm('classesForm');
    },
    setModelTraining (modelTraining) {
      this.changeActiveForm(null, true);
      this.modelTraining = !_.isUndefined(modelTraining) ? Object.assign({}, modelTraining) : { config: {} };
      if (this.modelTraining.name) {
        this.changeActiveForm('trainingForm');
      }
    },
    changeActiveForm (formName = null, clearData = false) {
      if (clearData) {
        this.modelClass = Object.assign({}, { product_types: [] });
        this.modelTraining = Object.assign({}, { config: {} });
      }

      this.activeForm = formName;

      if (formName) {
        this.$nextTick(() => {
          this.$refs.modelClassForm.scrollIntoView({ behavior: 'smooth', alignToTop: true });
        });
      }
    },
    async aggregate () {
      try {
        await ModelsService.aggregate(this.modelId);
      }
      catch (error) {
        console.error(error);
      }

      this.loadModels();
    },
    importedClasses () {
      this.changeActiveForm(null, true);
      this.loadModels();
    },
    classAttached () {
      this.changeActiveForm(null, true);
      this.loadModels();
    },
  },
};
</script>
<style>
</style>
