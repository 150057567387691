<template>
  <select>
    <slot />
  </select>
</template>

<script>

export default {
  name: 'Select2',
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    textKey: {
      type: String,
      default: null,
    },
    valueKey: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    settings: { // not used at all?
      type: Object,
      default: () => {},
    },
    dropdownParent: {
      type: String,
      default: '',
    },
    value: null,
    max: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      select2: null,
    };
  },
  watch: {
    options() {
      this.setOption(this.parseOptions());
    },
    value(val) {
      this.setValue(val);
    },
  },
  mounted() {
    this.select2 = $(this.$el)
      .select2({
        placeholder: this.placeholder,
        dropdownParent: this.dropdownParent ? $('.modal-body', this.dropdownParent) : null,
        data: this.parseOptions(),
      })
      .on('select2:select select2:unselect', ev => {
        this.$emit('change', this.select2.val());
        this.$emit('select', ev['params']['data']);
      });
    this.setValue(this.value);
  },
  beforeDestroy() {
    this.select2.select2('destroy');
  },
  methods: {
    parseOptions() {
      this.optionsParsed = [];

      const textKey = this.textKey || 'text';
      const valueKey = this.valueKey || 'id';
      
      return this.options.map((a) => {
        return { text: a[textKey], id: a[valueKey] };
      });
    },
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        dropdownParent: this.dropdownParent ? $('.modal-body', this.dropdownParent) : null,
        data: val,
        allowClear: true,
      });
      this.setValue(this.value);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger('change');
    },
  },
};
</script>
