<template>
  <div class="animated fadeIn overlay-container">
    <b-row>
      <b-col v-if="!CDID" sm="12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('grab_data.cash_desk_select') }}
          </div>
          <div class="card-block">
            <raspberry-table :actions="actions" :enabled-only="true" @select="raspberrySelected" />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="CDID">
      <b-col lg="8">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-camera" />
            {{ $t('grab_data.grabber') }} (ID: {{ CDID }})
          </div>
          <div class="card-block">
            <stream-view ref="previewImg" class="w-100" @open="onopen" @configsuccess="onconfigsuccess"
              @frame="onframe" @error="onerror" @close="onclose" />
            <div v-if="preview.delay !== null">
              {{ $t('grab_data.delay') }} {{ preview.delay }}ms
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="4">
        <b-button size="lg" variant="info" class="w-100" @click="send">
          {{ $t('grab_data.send_on_backend') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TraysService from '@/services/trays-service.js';
import RaspberryTable from '../Raspberry/components/RaspberryTable';
import { STREAM_URL } from '@/constants';
import { mapState } from 'vuex';


export default {
  name: 'GrabData',
  components: {
    RaspberryTable,
  },
  data() {
    return {
      CDID: null,
      stream: null,
      preview: {
        delay: null,
      },
      actions: {
        enabled: true,
        select: true,
      },
    };
  },
  beforeUnmount() {
    this.disconnect();
  },
  computed: {
    ...mapState(['auth']),
  },
  methods: {
    onopen() {
      this.$notify({
        type: 'info',
        title: this.$t('global.connecting'),
        text: this.$t('grab_data.connecting_to_stream'),
        duration: 1000,
      });
    },
    onconfigsuccess() {
      this.$notify({
        type: 'success',
        title: this.$t('global.success'),
        text: this.$t('grab_data.connected'),
        duration: 1500,
      });
    },
    onframe(data) {
      this.preview.delay = Date.now() - data.timestamp;
    },
    onerror(error) {
      if (error?.Error) {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: error.Error.map((err) => {
            return `> ${err}`;
          }).join('<br>'),
          duration: -1,
        });
        return;
      }
      else {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: `${JSON.stringify(error)}`,
          duration: -1,
        });
      }
    },
    onclose() {
      this.$notify({
        type: 'info',
        title: this.$t('global.info'),
        text: this.$t('grab_data.disconnected'),
        duration: 1500,
      });

      this.CDID = null;
    },
    raspberrySelected(item) {
      this.CDID = item.id;
      // Make sure the component is rendered, otherwise we can't call connect
      this.$nextTick(() => {
        this.connect(this.CDID);
      });
    },
    connect(id) {
      this.disconnect();

      this.$refs.previewImg.connect(
        STREAM_URL,
        this.auth.token,
        {
          use_ack: true,
          kind: {
            Listen: {
              cash_desk_id: id,
            },
          },
        });
    },
    disconnect() {
      this.$refs.previewImg.disconnect();
      this.preview.delay = null;
    },
    async send() {
      try {
        let imgSrc = this.$refs.previewImg.getCurrentFrame();
        let bytes = await fetch(imgSrc).then((response) => {
          return response.arrayBuffer();
        });

        // Make sure we don't use the ellipsis operator, because it can overflow
        // the JS engine's max argument count.
        let string = '';
        for (let byte of new Uint8Array(bytes)) {
          string += String.fromCharCode(byte);
        }
        let base64 = `data:image/jpeg;base64,${btoa(string)}`;

        await TraysService.uploadBase64({
          image: base64,
          CDID: this.CDID,
          workflow: 'training',
        });

        this.$notify({
          type: 'success',
          title: this.$t('global.success'),
          text: this.$t('grab_data.sent_on_backend'),
          duration: 1500,
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: `${this.$t('grab_data.error_on_send')}: ${error}`,
          duration: -1,
        });
      }
    },
  },
};
</script>

<style lang="scss" scope>
.overlay-container .spinner-grow {
  margin-top: 20%;
}

.overlay-container {
  height: 100%;
  position: relative;
  width: 100%;
}

.overlay {
  background-color: rgba(17, 17, 17, 0.2);
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 11;
}
</style>
