<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <b-card header-tag="header">
          <div slot="header">
            <span><i class="fa fa-bullhorn" /> {{ $t('models.model') }} </span>
          </div>
          <models-form :value="item" :errors="errors" :percent="modelPercent" @saved="modelSaved" />
          <div slot="footer" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import ModelsForm from './components/ModelsForm';

export default {
  name: 'ModelsCreate',
  components: { ModelsForm },
  data() {
    return {
      socket: null,
      reconnectTM: 1000,
      reconnectP: -1,
      modelClass: {},
      modelTraining: { config: {} },
      item: {
        name: '',
        status: 'created',
        description: '',
        model_classes: [],
        model_training: [],
      },
      showModelClassForm: false,
      showModelTrainingForm: false,
      errors: {},
      updated: false,
      loading: false,
      modelPercent: '0 %',
    };
  },
  computed: { ...mapState(['auth']) },
  watch: {},
  mounted() { },
  beforeDestroy() { },
  methods: {
    modelSaved(item) {
      this.$router.push({ name: 'ModelsEdit', params: { id: item.id } });
    },
  },
};
</script>
<style></style>
