<template>
  <router-link :to="{...to}">
    <span :class="['badge mr-1', variant]">
      <slot />
    </span>
  </router-link>
</template>
<script>
export default {
  name: 'NavigationBadge',
  props: {
    to: {type: Object, required: true},
    variant: {type: String, required: true},
  },
};
</script>