<template>
  <b-modal
    id="lock-modal"
    v-model="show"
    @change="toggle"
  >
    <template #modal-header>
      <img
        class="logo-icon"
        src="@/static/img/trash.svg"
      >
    </template>
    
    <p class="modal-title">
      {{ $t('cashier.cashDesk.modal.void.title') }}
    </p>
    <p class="modal-text">
      {{ $t('cashier.cashDesk.modal.void.text') }}
    </p>

    <template #modal-footer="{ cancel }">
      <b-button
        class="confirm-lock-btn-cancel"
        @click="cancel()"
      >
        {{ $t('cashier.cashDesk.modal.void.btn.cancel') }}
      </b-button>  
      <b-button
        :disabled="order._loading"
        class="confirm-lock-btn-ok"
        @click="confirm"
      >
        {{ $t('cashier.cashDesk.modal.void.btn.confirm') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapActions } from 'vuex';
import { VuexTypes } from '@/store/types';
import ordersService from '@/services/orders-service';

export default {
  name      : 'ModalConfirmVoidOrder',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.show = newVal;
      },
    },
    'order._loading': {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.close();
      },
    },
  },
  methods: {
    ...mapActions('cashierOrders', {
      setOrderLoding: VuexTypes.CASHIER_ORDERS_ACTION_SET_ORDER_LOADING,
    }),
    toggle() {
      this.$emit('input', this.show);
    },
    close() {
      this.show = false;
      this.toggle();
    },
    confirm() {
      const order = this.order;
      this.setOrderLoding({ order, loading: true });

      ordersService.voidOrder(order.id)
        .then(resp => {
          this.$router.push('Cashier');
        }).finally(() => {
          this.setOrderLoding({ order, loading: false });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    .btn {
       font-weight: 800;
        font-size: 14px;
        line-height: 19px;
    }
    .logo-icon {
        margin-left: auto;
        margin-right: auto;
        width: 33px;  
    }
    .modal-title{
        text-align: center;
        font-size: 30px;
        font-weight: 800;
    }
    .modal-text{
        text-align: center;
        font-size: 18px;
        font-weight: 400;
    }
    .confirm-lock-btn-cancel {
        color: #003C7E;
        background-color: #fff;
        border: 2px solid #003C7E;
        
    }
    .confirm-lock-btn-ok {
        color: #fff;
        background-color: #B91F31;
    }
</style>
