<template>
  <b-nav-item-dropdown
    right
    no-caret
  >
    <template slot="button-content">
      <span class="profile-menu-btn">
        <i class="fa fa-user" /> {{ auth.user.name }}
      </span>
    </template>
    <b-dropdown-header
      tag="div"
      class="text-center"
    >
      <!-- <strong>Account</strong>
    </b-dropdown-header>
    <b-dropdown-item>
      <i class="fa fa-bell-o" /> Updates
      <b-badge variant="info">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <i class="fa fa-envelope-o" /> Messages
      <b-badge variant="success">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <i class="fa fa-tasks" /> Tasks
      <b-badge variant="danger">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <i class="fa fa-comments" /> Comments
      <b-badge variant="warning">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-header
      tag="div"
      class="text-center"
    > -->
      <strong>{{ $t('global.settings') }}</strong>
    </b-dropdown-header>
    <b-dropdown-item>
      <b-link to="/profile">
        <i class="fa fa-user" /> {{ $t('global.profile') }}
      </b-link>
    </b-dropdown-item>
    <!-- <b-dropdown-item>
      <i class="fa fa-wrench" /> Settings
    </b-dropdown-item>
    <b-dropdown-item>
      <i class="fa fa-usd" /> Payments
      <b-badge variant="secondary">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-item>
      <i class="fa fa-file" /> Projects
      <b-badge variant="primary">
        {{ itemsCount }}
      </b-badge>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item>
      <i class="fa fa-shield" /> Lock Account
    </b-dropdown-item> -->
    <b-dropdown-item @click="logout()">
      <i class="fa fa-lock" /> {{ $t('global.logout') }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
import { VuexTypes } from '../../store/types';
import { mapState } from 'vuex';

export default {
  name: 'HeaderDropdown',
  data: () => {
    return { itemsCount: 42 };
  },
  computed: { ...mapState(['auth']) },
  methods : {
    logout: function () {
      this.$store.dispatch(VuexTypes.AUTH_LOGOUT).then(() => {
        this.$router.push('/pages/login').catch(()=>{});
      });
    },
  },

};

</script>
<style>
.profile-menu-btn {
  padding: 0 1em;
}
</style>
