<template>
  <header class="app-header navbar">
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      <span class="navbar-toggler-icon " />
    </button>
    <b-link
      class="navbar-brand d-md-down-none"
      to="#"
    />
    <button
      class="navbar-toggler sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <b-navbar-nav class="ml-auto mx-auto">
      <!-- <b-nav-item class="d-md-down-none">
        <i class="icon-bell" />
        <b-badge
          pill
          variant="danger"
        >
          5
        </b-badge>
      </b-nav-item>
      <b-nav-item class="d-md-down-none">
        <i class="icon-list" />
      </b-nav-item>
      <b-nav-item class="d-md-down-none">
        <i class="icon-location-pin" />
      </b-nav-item> -->
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <language-switcher />
      <header-dropdown />
    </b-navbar-nav>
    <!-- <button
      class="navbar-toggler aside-menu-toggler d-md-down-none"
      type="button"
      @click="asideToggle"
    >
      <span class="navbar-toggler-icon" />
    </button> -->
  </header>
</template>
<script>
import { mapGetters } from 'vuex';
import HeaderDropdown from './HeaderDropdown.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  name      : 'CHeader',
  components: {
    LanguageSwitcher,
    HeaderDropdown,
  },
  props     : {
    fixed: {
      type   : Boolean,
      default: true,
    },
  },
  data () {
    return {};
  },
  computed: { ...mapGetters(['can']) },
  mounted () {
    if (this.fixed) { $('body').addClass('header-fixed'); }
  },
  beforeDestroy () {
    $('body').removeClass('header-fixed');
  },
  methods: {
    sidebarToggle (event) {
      event.preventDefault();
      document.body.classList.toggle('sidebar-hidden');
    },
    sidebarMinimize (event) {
      event.preventDefault();
      document.body.classList.toggle('sidebar-minimized');
    },
    mobileSidebarToggle (event) {
      event.preventDefault();
      document.body.classList.toggle('sidebar-mobile-show');
    },
    asideToggle (event) {
      event.preventDefault();
      document.body.classList.toggle('aside-menu-hidden');
    },
  },
};

</script>
<style scoped>
</style>
