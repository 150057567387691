export function makeQuery(params) {
  let query = '';
  let first = true;
  for (const [key, value] of Object.entries(params)) {
    if (value && `${value}`) {
      query += first ? '?' : '&';
      query += `${key}=${value}`;
      first = false;
    }
  }
  return query;
}
