<template>
  <router-view />
</template>

<script>
// import { VuexTypes } from '@/store/types'
// import { mapActions } from 'vuex'

export default { name: 'App' }; 
</script>

<style lang="scss">
  @import '@/sass/app.scss';
</style>