<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        sm="12"
        md="12"
      >
        <models-training-table ref="models-training-table" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="12"
        md="12"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('models.header') }}
            <b-button
              :to="{ name: 'ModelsCreate' }"
              size="sm"
              variant="success"
              class="float-right mr-1 d-print-none"
            >
              <i class="fa icon-pencil" /> {{ $t('models.create_model') }}
            </b-button>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('global.name') }}</th>
                    <th>{{ $t('global.description') }}</th>
                    <th>{{ $t('global.status') }}</th>
                    <th>{{ $t('global.created') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in models.list.data"
                    :key="item.id"
                  >
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.created_at | dateUtcToLocal }}</td>
                    <td>
                      <b-button
                        class="btn btn-success btn-sm table-button-size"
                        :to="{ name: 'ModelsEdit' , params: { id: item.id } }"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </b-button>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteItem(item)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="models.list.total"
              :per-page="models.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import { socketio as socketioMixin } from '@/mixins/socketio';
import ModelsTrainingTable from './components/ModelsTrainingTable';

export default {
  name: 'Models',
  components: {
    ModelsTrainingTable,
  },
  mixins: [socketioMixin],
  data () {
    return { currentPage: 1 };
  },
  computed: {
    ...mapState(['models']),
    ...mapGetters(['can']),
    socketQuery () {
      return {
        token: this.auth.token,
        place: 'models',
      };
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.list(this.currentPage);
    },
  },
  mounted () {
    this.list(this.currentPage);
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.MODELS_LIST,
      delete: VuexTypes.MODELS_DELETE,
    }),
    deleteItem (item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.addItem = {};
      }
    },
    socketHandler () {
      this.socket.client.on('cpp_models_status_train', (data) => {
        this.$refs['models-training-table'].updateItem(data.trainingId, {
          progress: data.percent,
          status: data.status,
        });
      });
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
