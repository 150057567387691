<template>
  <b-modal
    id="lock-modal"
    v-model="show"
    @change="toggle"
  >
    <template #modal-header>
      <img
        class="logo-icon"
        src="@/static/img/trash.svg"
      >
    </template>
      
    <p class="modal-title">
      {{ $t('cashier.modalConfirmTrayDelete.title') }}
    </p>
    <p class="modal-text">
      {{ $t('cashier.modalConfirmTrayDelete.text') }}
    </p>

    <template #modal-footer="{ cancel }">
      <b-button
        class="confirm-lock-btn-cancel"
        @click="cancel()"
      >
        {{ $t('cashier.modalConfirmTrayDelete.btn.cancel') }}
      </b-button>  
      <b-button
        :disabled="tray._loading"
        class="confirm-lock-btn-ok"
        @click="deleteTray"
      >
        {{ $t('cashier.modalConfirmTrayDelete.btn.confirm') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapActions } from 'vuex';
import { VuexTypes } from '@/store/types';
import traysService from '@/services/trays-service';

export default {
  name      : 'ModalConfirmTrayDelete',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tray: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.show = newVal;
      },
    },
    'tray._loading': {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.close();
      },
    },
  },
  methods: {
    ...mapActions('cashierTrays', {
      setTrayLoading: VuexTypes.CASHIER_TRAYS_ACTION_SET_TRAY_LOADING,
      trayDelete: VuexTypes.CASHIER_TRAYS_ACTION_DELETE_TRAY,
    }),
    toggle() {
      this.$emit('input', this.show);
    },
    close() {
      this.show = false;
      this.toggle();
    },
    deleteTray() {
      this.setTrayLoading({ trayId: this.tray.id, loading: true });
      traysService.cashierDelete(this.tray.id);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
  }
  .logo-icon {
      margin-left: auto;
      margin-right: auto;
      width: 33px;
  }
  .modal-title{
      text-align: center;
      font-size: 30px;
      font-weight: 800;
  }
  .modal-text{
      text-align: center;
      font-size: 18px;
      font-weight: 400;
  }
  .confirm-lock-btn-cancel {
      color: #003C7E;
      background-color: #fff;
      border: 2px solid #003C7E;
      
  }
  .confirm-lock-btn-ok {
      color: #fff;
      background-color: #B91F31;
  }
</style>
