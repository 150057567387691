<template>
  <div class="app">
    <app-header :fixed="fixedHeader || fixedSidebar" />
    <div class="app-body">
      <sidebar
        :nav-items="navItems"
        :fixed="fixedSidebar"
      />
      <!-- :style="{ 'margin-top': offset }" -->
      <notifications
        position="top right"
        :style="{ 'margin-top': offset }"
      />
      <notifications
        group="system-errors"
        position="top right"
        class="system-error-notifications"
        :style="{ 'margin-top': offset }"
      />
      <main class="main">
        <ol class="breadcrumb">
          <li
            v-for="(routeObject, index) in routeRecords"
            :key="index"
            class="breadcrumb-item"
          >
            <span
              v-if="index === routeRecords.length - 1"
              class="active"
            >{{ routeObject.label }}</span>
            <router-link
              v-else
              :to="routeObject.to"
            >
              {{ routeObject.label }}
            </router-link>
          </li>
        </ol>

        <div class="container-fluid">
          <router-view />
        </div>
      </main>
    </div>
    <app-footer />
  </div>
</template>

<script>
import nav from '../_nav';
import { mapGetters, mapState } from 'vuex';
import { Header as AppHeader, Sidebar, Footer as AppFooter } from '../components';

export default {
  name      : 'Full',
  components: {
    AppHeader,
    Sidebar,
    AppFooter,
  },
  data () {
    return {
      navItems    : [],
      offset      : true,
      fixedHeader : true,
      fixedSidebar: true,
    };
  },
  computed: {
    ...mapGetters(['can']),
    ...mapState(['auth']),
    name () {
      return this.$route.name;
    },
    routeRecords() {
      return this.$route.matched.filter((route) => route.name || route.meta.label).map((route) => {
        return {
          label: this.$t(route.meta.label),
          to: route.path,
        };
      });
    },
    title() {
      let global = this.$dt('route.title.default', null);
      let sub = this.$dt(this.$route.meta.titleKey, null);
      if (global) {
        if (sub) {
          return `${global} - ${sub}`;
        }
        else {
          return global;
        }
      }
      return null;
    },
  },
  watch: {
    'auth.permissions': function () {
      this.navItems = this.navItemsFiltered();
    },
    title(newTitle) {
      if (newTitle) {
        document.title = newTitle;
      }
    }, 
  },
  mounted () {
    this.navItems = this.navItemsFiltered();
    $('body').addClass('app sidebar-lg-show pace-done');
    $(window).on('scroll', this.setPosNotify);
    this.setPosNotify();
  },
  beforeDestroy () {
    $('body').removeClass('app sidebar-lg-show pace-done');
    $(window).off('scroll', this.setPosNotify);
  },
  methods: {
    setPosNotify () {
      const top = $(document).scrollTop();
      const height = $('.app-header').height();
      const offset = top < height ? height - top : 0;

      this.offset = `${offset}px`;
    },

    navItemsFiltered () {
      const hasAccess = (o) => {
        let hasAccess = true;
        if (hasAccess && 'hasAny' in o) {
          hasAccess = o.hasAny.reduce((v, x) => {
            return v || this.can(x);
          }, false);
        }
        return hasAccess;
      };

      const mapChildred = (o) => {
        o = Object.assign({}, o);
        if ('children' in o) {
          o.children = o.children.map(mapChildred).filter(hasAccess);
        }
        return o;
      };

      return nav.items.map(mapChildred).filter(hasAccess);
    },

  },
};
</script>

<style lang="scss">
.system-error-notifications {
  .notification-content {
    padding: 0.5rem;
    font-family: monospace;
    overflow-wrap: break-word;
    font-size: 0.7rem;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>