<template>
  <b-card :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }" header-tag="header">
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a href="#" class="btn btn-setting" @click="closeClick">
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group :label="$t('global.name')" :state="!errors.name" :invalid-feedback="errors.name">
        <b-form-input v-model="raspberry.name" type="text" />
      </b-form-group>
      <b-form-group :label="$t('global.description')" :state="!errors.description" :invalid-feedback="errors.description">
        <b-form-input v-model="raspberry.description" type="text" />
      </b-form-group>
      <b-form-group :label="$t('raspberry.hardware_id')" :state="!errors.hardware_id"
        :invalid-feedback="errors.hardware_id">
        <b-form-input v-model="raspberry.hardware_id" disabled type="text" />
      </b-form-group>
      <b-form-group :label="$t('raspberry.jwt_pk')" :state="!errors.jwt_pk" :invalid-feedback="errors.jwt_pk">
        <b-input-group>
          <b-form-input v-model="raspberry.jwt_pk" type="text" />
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('global.enabled')" :state="!errors.enabled" :invalid-feedback="errors.enabled">
        <b-form-input v-model="raspberry.enabled" type="text" />
      </b-form-group>
      <b-form-group :label="$t('raspberry.cash_desk_id')" :state="!errors.cash_desk_id"
        :invalid-feedback="errors.cash_desk_id">
        <b-form-select v-model="raspberry.cash_desk_id" :options="cacheDesks" />
      </b-form-group>
      <b-form-group :label="$t('raspberry.model_id')" :state="!errors.model_id" :invalid-feedback="errors.model_id">
        <b-form-select v-model="raspberry.model_id" :options="models" />
      </b-form-group>
      <b-form-group :label="$t('raspberry.pixels_per_cm')" :state="!errors.pixels_per_cm"
        :invalid-feedback="errors.pixels_per_cm">
        <b-form-input v-model="raspberry.pixels_per_cm" type="text" />
      </b-form-group>
      <div>
        <b-btn v-if="isEditing" variant="outline-danger" @click="closeClick">
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn v-if="isEditing" class="pull-right" type="submit" variant="success">
          {{ $t('global.save') }}
        </b-btn>
        <b-btn v-else class="pull-right" type="submit" variant="success">
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions } from 'vuex';
import CashDeskService from '@/services/cash-desk-service';
import ModelsService from '@/services/models-service';

export default {
  name: 'RaspberryForm',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  emits: ['input'],
  data() {
    return {
      raspberry: _.cloneDeep(this.value),
      errors: {},
      allCashDesks: [],
      allModels: [],
    };
  },
  computed: {
    isEditing() {
      return this.raspberry && typeof this.raspberry.id !== 'undefined' && this.raspberry.id > 0;
    },
    cacheDesks() {
      const array = this.allCashDesks.map((a) => {
        return { text: `${a.id} - ${a.inventory_number} - ${a.host}`, value: a.id };
      });
      array.unshift({ value: null, text: this.$t('global.none') });
      return array;
    },
    models() {
      const array = this.allModels.map((a) => {
        return { text: `${a.id} - ${a.name}`, value: a.id };
      });
      array.unshift({ value: null, text: this.$t('global.none') });
      return array;
    },
  },
  watch: {
    value: function (newVal) {
      this.raspberry = _.cloneDeep(newVal);
    },
  },
  mounted() {
    CashDeskService.list().then((response) => {
      this.allCashDesks = response.data.data;
    }).catch(() => {
      this.$notify({
        type: 'error',
        title: this.$t('global.error'),
        text: this.$t('global.connection_lost_service', { service: 'CashDeskService.getPrlistoducts' }),
      });
    });
    ModelsService.list().then((response) => {
      this.allModels = response.data;
    }).catch(() => {
      this.$notify({
        type: 'error',
        title: this.$t('global.error'),
        text: this.$t('global.connection_lost_service', { service: 'ModelsService.list' }),
      });
    });
  },
  methods: {
    ...mapActions({
      add: VuexTypes.RASPBERRY_ADD,
      update: VuexTypes.RASPBERRY_UPDATE,
    }),
    setField(fieldname, value) {
      this.raspberry[fieldname] = value;
      this.$emit('input', this.raspberry);
    },
    closeClick() {
      this.$emit('saved');
    },
    save() {
      const action = this.isEditing ? this.update(this.raspberry) : this.add(this.raspberry);
      action
        .then(() => {
          this.errors = {};
          this.$emit('saved');
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>
<style></style>
