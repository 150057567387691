import Api from '@/services/api';

export default {
  list (currentPage) {
    let params = {};
    if (currentPage) {
      params = Object.assign(params, { page: currentPage });
    }
    return Api().get('/models', {
      params: params,
    });
  },
  get (id) {
    return Api().get(`/models/${id}`);
  },
  store (data) {
    return Api().post('/models', data);
  },
  update (data) {
    return Api().put(`/models/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/models/${id}`);
  },
  aggregate (id) {
    return Api().post(`/models/aggregate/${id}`);
  },
};
