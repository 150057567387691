<template>
  <div class="canvas-container">
    <img
      ref="img"
      style="width: 100%"
      :src="imgSrc"
    >
    <div id="svg" />
  </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
  name : 'GrabDataDraw',
  props: {
    model: {
      type    : Object,
      required: false,
      default () {
        return null;
      },
    },
    imgSrc: {
      type    : String,
      required: true,
      default () {
        return '';
      },
    },
    removeable: {
      type    : Boolean,
      required: true,
      default () {
        return false;
      },
    },
    place: {
      type    : String,
      required: false,
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      errors  : {},
      canvas  : null,
      draw    : null,
      size    : 20,
      oWidth  : null,
      oHeight : null,
      group   : null,
      polygons: {},
      stroke  : { color: 'blue', width: 2 },
      resizeTimeout: null,
      ro: null,
    };
  },
  computed: { ...mapState(['trays']) },
  watch   : {
    model: function () {
      if (this.imgSrc) {
        this.drawPath();
      }
    },
    imgSrc: function () {
      this.canvas.size(this.$refs.img.clientWidth, this.$refs.img.clientHeight);
      this.getImgOriginalSize();
    },
    removeable: function () {
      this.drawPath();
    },
  },
  created () {
    window.addEventListener('resize', this.onResize);
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize);
    this.ro && this.ro.disconnect();
  },
  mounted () {
    this.canvas = this.$svg('svg');

    // Not supported: Internet Explorer, Firefox for Android
    this.ro = new ResizeObserver(_ => {
      this.onResize();
    });
    this.ro.observe(this.$refs.img);
  },
  methods: {
    onResize () {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.canvas.size(this.$refs.img.clientWidth, this.$refs.img.clientHeight);
        this.drawPath();
      }, 250);
    },
    getImgOriginalSize () {
      const newImg = new Image();
      newImg.addEventListener('load', () => {
        this.oHeight = newImg.height;
        this.oWidth = newImg.width;
        this.drawPath();
      });
      newImg.src = this.imgSrc;
    },
    drawClear () {
      _.forEach(this.polygons, (polygonArray) => {
        _.forEach(polygonArray, (polygon) => {
          polygon.selectize(false, { deepSelect: true });
          polygon.off('click');
          polygon.remove();
        });
      });
      this.polygons = {};
      if (this.group) {
        this.group.remove();
        this.group = null;
      }
    },
    highlightItem(item_id) {
      let segimg = this.model.segmented_images.find(si => si.id == item_id);
      if(segimg){
        segimg.highlight = true;
        this.drawPath();
      }
    },
    removeHighlight(){
      _.forEach(this.model.segmented_images, (segimg) => {
        segimg.highlight = false;
      });
      this.drawPath();
    },
    drawPath () {
      this.drawClear();
      this.canvas.size(this.$refs.img.clientWidth, this.$refs.img.clientHeight);
      if (!this.model) {
        return false;
      }
      this.group = this.canvas.group();

      const wk = this.oWidth / this.$refs.img.clientWidth;
      const hk = this.oHeight / this.$refs.img.clientHeight;
      _.forEach(this.model.segmented_images, (segimg, key) => {
        if (this.place === 'cashier' && this.model.products) {
          let contain = false;
          _.forEach(this.model.products, (prd) => {
            if (prd.segmented_image_id === segimg.id) {
              contain = true;
              segimg.prdCode = prd.human_product.code;
            }
          });
          if (!contain) {
            return;
          }
        }
        if (segimg.data && segimg.data.rect) {
          const cW = segimg.data.rect.width / wk;
          const cH = segimg.data.rect.height / hk;
          const cX = segimg.data.rect.x / wk;
          const cY = segimg.data.rect.y / hk;

          let strokeStyle = {};
          if (this.place === 'cashier') {
            strokeStyle = { color: 'rgb(0, 255, 0)', width: 2 };
          } else {
            strokeStyle = { color: segimg.color, width: 2 };
          }
          if(segimg.highlight)
            strokeStyle.width += 3;
          const rect = this.canvas.rect(cW, cH)
            .fill('none').stroke(strokeStyle)
            .move(cX, cY);
          const index = key + 1;
          let textString = `${index}(${segimg.id})`;
          let fontStyle = { fill: 'cyan', family: 'Inconsolata' };
          if (this.place === 'cashier') {
            textString = `${segimg.prdCode}`;
            fontStyle = {
              'fill'       : 'cyan',
              'family'     : 'Inconsolata',
              'font-size'  : '20px',
              'font-weight': 900,
            };
          }
          const text = this.canvas.text(textString).font(fontStyle).move(cX + 10, cY + 10);
          this.group.add(rect).add(text);
        }
        if (segimg.data && segimg.data.contours && segimg.data.contours.length > 0) {
          _.forEach(segimg.data.contours, (contour) => {
            const pts = this.getCounturPts(contour);
            let strokeStyle = {};
            if (this.place === 'cashier') {
              strokeStyle = { color: 'rgb(0, 255, 0)', width: 1 };
            } else {
              strokeStyle = { color: segimg.color, width: 2 };
            }
            if(segimg.highlight)
              strokeStyle.width += 3;

            let fill = 'none';
            if (this.removeable) {
              fill = 'rgba(0,0,0,0.1)';
            }
            const polygon = this.canvas.polygon(pts).fill(fill).stroke(strokeStyle);
            polygon.segimid = segimg.id;
            if (this.place !== 'cashier') {
              polygon.selectize({ deepSelect: true, classPoints: 'svg_select_points_custom' }).resize();
            }
            if (!this.polygons[segimg.id]) {
              this.polygons[segimg.id] = [];
            }
            polygon.arrayIndex = this.polygons[segimg.id].length;
            polygon.on('click', this.removePolygon);
            this.polygons[segimg.id].push(polygon);
          });
        }
      });
    },
    removePolygon (data) {
      if (!this.removeable) {
        return false;
      }
      const arrayIndex = data.target.instance.arrayIndex;
      const segimid = data.target.instance.segimid;
      const polygon = this.polygons[segimid][arrayIndex];
      polygon.selectize(false, { deepSelect: true });
      polygon.off('click');
      polygon.remove();
      this.polygons[segimid].splice(arrayIndex, 1);
      const ps = this.polygons[segimid];
      _.forEach(ps, (pp, index) => {
        this.polygons[segimid][index].arrayIndex = index;
      });
    },
    updateAllPoints () {
      const dataArray = [];
      _.forEach(this.polygons, (polygonArray, segimid) => {
        const pointsArray = [];
        _.forEach(polygonArray, (polygon) => {
          pointsArray.push(Object.assign({}, polygon.array().value));
        });
        const data = {
          pointsArray: pointsArray,
          wk         : this.oWidth / this.$refs.img.clientWidth,
          hk         : this.oHeight / this.$refs.img.clientHeight,
          oWidth     : this.oWidth,
          oHeight    : this.oHeight,
          segimid    : segimid,
        };
        dataArray.push(data);
      });
      this.$emit('updatePts', dataArray);
    },
    getCounturPts (contour) {
      const wk = this.oWidth / this.$refs.img.clientWidth;
      const hk = this.oHeight / this.$refs.img.clientHeight;
      if (!wk || !hk) {
        return [];
      }
      let pts = '';
      _.forEach(contour, (point) => {
        const x = point.x / wk;
        const y = point.y / hk;
        pts += `${x},${y} `;
      });
      return pts;
    },
    startDraw (item) {
      this.draw = this.canvas.polygon().fill('none').stroke(this.stroke).draw();
      this.draw.segmented_image = item;
      this.draw.on('drawstart', () => {
        document.addEventListener('keydown', this.keyPress);
      });
      this.canvas.on('mouseup', (e) => {
        this.draw.draw('point', e);
      }, false);
    },
    keyPress (e) {
      if (e.key === 'Enter') {
        this.doneDraw();
      } else if (e.key === 'Escape') {
        this.cancelDraw();
      }
    },
    doneDraw () {
      this.draw.draw('done');
      this.draw.off('drawstart');
      this.canvas.off('mouseup');
      document.removeEventListener('keydown', this.keyPress);

      const polygonArray = this.polygons[this.draw.segmented_image.id];
      const pointsArray = [];
      _.forEach(polygonArray, (polygon) => {
        pointsArray.push(Object.assign({}, polygon.array().value));
      });
      pointsArray.push(Object.assign({}, this.draw.array().value));
      const data = {
        pointsArray: pointsArray,
        wk         : this.oWidth / this.$refs.img.clientWidth,
        hk         : this.oHeight / this.$refs.img.clientHeight,
        oWidth     : this.oWidth,
        oHeight    : this.oHeight,
        segimid    : this.draw.segmented_image.id,
      };
      this.$emit('doneDraw', data);
      this.draw.remove();
    },
    cancelDraw () {
      document.removeEventListener('keydown', this.keyPress);
      this.draw.draw('cancel');
      this.draw.off('drawstart');
      this.$emit('cancelDraw', true);
    },
  },
};
</script>
<style>
.canvas-container {
  position: relative;
}
.canvas-container #svg {
  position: absolute;
  top: 0;
  left: 0;
}
.svg_select_points_custom {
  fill: #00bf8f;
  stroke: #fff;
}
</style>
