import { CASH_DESK_STATES } from '@/constants';

/**
 * 
 * @param {Object} order 
 * @returns {boolean}
 */
export function canLockOrder(order) {
  return order.state === CASH_DESK_STATES.IN_PROGRESS;
}

/**
 * 
 * @param {Object} order 
 * @returns {boolean}
 */
export function canUnlockOrder(order) {
  return [CASH_DESK_STATES.ASSISTANCE, CASH_DESK_STATES.LOCKED].includes(order.state);
}

/**
 * 
 * @param {Object} order 
 * @returns {boolean}
 */
export function canVerifyAgeOrder(order) {
  return CASH_DESK_STATES.VERIFY_AGE || !order.age_verified && order.need_age_verification;
}

/**
 * 
 * @param {Object} order 
 * @returns {boolean}
 */
export function canEditOrderProducts(order) {
  return order.state === CASH_DESK_STATES.LOCKED;
}

/**
 * 
 * @param {Object} order 
 * @returns {boolean}
 */
export function canVoidOrder(order) {
  return order.state === CASH_DESK_STATES.LOCKED || order.state === CASH_DESK_STATES.VERIFY_AGE;
}

export default {
  canLockOrder,
};