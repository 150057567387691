<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <div class="card">
          <div class="card-header">
            <b-row class="mb-3">
              <b-col>
                <i class="fa fa-align-justify" /> {{ $t('trays.header') }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label for="search">{{ $t('global.select_by_id') }}</label>
                  <multiselect id="search" v-model="filter.ids" :placeholder="$t('global.search')" open-direction="bottom"
                    :options="search.options" :multiple="true" :searchable="true" :loading="search.loading"
                    :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="5"
                    :limit="10" :limit-text="limitText" :show-no-results="false" :show-no-options="false"
                    :hide-selected="true" @search-change="optionsRequest" :show-labels="false"
                    @close="search.options = []" />
                </b-form-group>
                <b-form-group>
                  <b-row class="m-0">
                    <b-col lg="4" md="6" sm="12" class="p-0">
                      <label for="start-date">{{ $t('global.start_date') }}</label>
                      <b-form-datepicker id="start-date" v-model="dateTime.startDate" :locale="locale"
                        :placeholder="$t('global.no_date_selected')"
                        :label-no-date-selected="$t('global.no_date_selected')"
                        :label-help="$t('global.date_picker_help')" 
                        :label-reset-button="$t('global.unset')"
                        reset-button />
                    </b-col>
                    <b-col lg="2" md="6" sm="12" class="p-0 pr-2 align-baseline mb-2">
                      <label for="start-time">{{ $t('global.start_time') }}</label>
                      <b-form-timepicker id="start-time" v-model="dateTime.startTime" class="align-baseline"
                        :locale="locale" :hour12="false" show-seconds no-close-button
                        :placeholder="$t('global.no_time_selected')"
                        :label-no-time-selected="$t('global.no_time_selected')" :label-reset-button="$t('global.reset')"
                        :label-now-button="$t('global.current_time')" now-button />
                    </b-col>
                    <b-col lg="4" md="6" sm="12" class="p-0">
                      <label for="end-date">{{ $t('global.end_date') }}</label>
                      <b-form-datepicker id="end-date" v-model="dateTime.endDate" :locale="locale"
                        :placeholder="$t('global.no_date_selected')"
                        :label-no-date-selected="$t('global.no_date_selected')"
                        :label-help="$t('global.date_picker_help')" 
                        :label-reset-button="$t('global.unset')"
                        reset-button
                        />
                    </b-col>
                    <b-col lg="2" md="6" sm="12" class="p-0 pr-2 mb-2">
                      <label for="end-time">{{ $t('global.end_time') }}</label>
                      <b-form-timepicker id="end-time" v-model="dateTime.endTime" class="align-baseline" :locale="locale"
                        :hour12="false" show-seconds no-close-button :placeholder="$t('global.no_time_selected')"
                        :label-no-time-selected="$t('global.no_time_selected')" :label-reset-button="$t('global.reset')"
                        :label-now-button="$t('global.current_time')" now-button />
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group>
                  <b-form-select v-model="filter.status" :options="statusOptions" style="width: 35%;" />
                  <b-form-select v-model="filter.annotationStatus" :options="annotationStatusOptions"
                    style="width: 35%;" />
                  <b-form-select id="segmentation-status" v-model="filter.segmentationStatus"
                    :options="segmentationStatusOptions" style="width: 30%;" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('trays.order_id') }}</th>
                    <th>{{ $t('global.ai_status') }}</th>
                    <th>{{ $t('ai.annotation_status') }}</th>
                    <th>{{ $t('ai.segmentation_status') }}</th>
                    <th>{{ $t('global.created') }}</th>
                    <th>{{ $t('global.last_modified_by') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in trays.list.data" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.order_id }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ $t(`ai.${item.annotation_status}`) }}</td>
                    <td>{{ $t(`ai.${item.segmentation_status}`) }}</td>
                    <td>{{ item.created_at | dateUtcToLocal }}</td>
                    <td>{{ item.last_modified_user?.name || "-" }}</td>
                    <td>
                      <b-button v-b-modal.modal-preview variant="info" size="sm" @click="initPreview(item)">
                        {{ $t('global.preview') }}
                      </b-button>
                      <b-button
                        :to="{ name: 'TraysPlotter', params: { id: item.id }, query: { nextStatus: item.annotation_status } }"
                        size="sm">
                        <i class="fab fa-connectdevelop" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col cols="12" sm="6" class="m-0">
                <b-pagination class="m-0" v-model="filter.currentPage" :total-rows="totalRows" :per-page="perPage" />
              </b-col>

              <b-col cols="12" sm="6" class="d-flex justify-content-end align-items-center">
                <span class="text-muted text-capitalize">{{ $t('global.total') }}: {{ totalRows }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-preview" hide-footer :title="$t('global.preview')">
      <div v-if="loading.getImg">
        <b-spinner small type="grow" style="margin-top: 0;" />
      </div>
      <div v-if="!loading.getImg">
        <img ref="img" style="width: 100%" :src="preview.imageSrc">
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import TraysService from '@/services/trays-service.js';
import { BFormDatepicker, BFormTimepicker } from 'bootstrap-vue';


export default {
  name: 'Trays',
  components: { BFormDatepicker, BFormTimepicker },
  props: {
    currentPage: {
      type: String,
      default: '1',
    },
    workflow: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    annotationStatus: {
      type: String,
      default: null,
    },
    segmentationStatus: {
      type: String,
      default: null,
    },
    ids: {
      default() {
        return [];
      },
    },
    fromCreatedAt: {
      type: String,
      default: null,
    },
    toCreatedAt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      filter: this.parseQuery(),
      inflightRequest: null,
      search: {
        options: [],
        loading: false,
        debounce: null,
      },
      dateTime: {
        startDate: this.fromCreatedAt ? this.fromCreatedAt.split(' ')[0] : null,
        startTime: this.fromCreatedAt ? this.fromCreatedAt.split(' ')[1] : '00:00:00',
        endDate: this.toCreatedAt ? this.toCreatedAt.split(' ')[0] : null,
        endTime: this.toCreatedAt ? this.toCreatedAt.split(' ')[1] : '00:00:00',
      },
      loading: { getImg: false },
      preview: {
        imageSrc: '/images/placeholder.png',
        placeHolder: '/images/placeholder.png',
      },
    };
  },
  computed: {
    ...mapState(['trays']),
    ...mapGetters(['can']),
    locale() {
      return this.$i18n.locale;
    },
    isLoading() {
      let isLoading = false;
      _.forEach(this.loading, (item) => {
        if (item) {
          isLoading = true;
          return false;
        }
      });
      return isLoading;
    },
    totalRows() {
      // Workaround for b-pagination resetting the currentPage to 
      // 1 when no total count/page size is provided
      if (!this.trays.list.total) {
        return this.currentPage;
      }
      return this.trays.list.total;
    },
    perPage() {
      // See above hack
      if (!this.trays.list.per_page) {
        return 1;
      }
      return this.trays.list.per_page;
    },
    statusOptions() {
      return [
        { value: null, text: this.$t('global.ai_status') },
        { value: 'created', text: this.$t('trays.ai_status.created') },
        { value: 'processing', text: this.$t('trays.ai_status.processing') },
        { value: 'done', text: this.$t('trays.ai_status.done') },
      ];
    },
    annotationStatusOptions() {
      return [
        { value: null, text: this.$t('ai.annotation_status') },
        { value: 'is_annotated', text: this.$t('trays.annotation_status.is_annotated') },
        { value: 'being_annotated', text: this.$t('trays.annotation_status.being_annotated') },
        { value: 'not_annotated', text: this.$t('trays.annotation_status.not_annotated') },
      ];
    },
    segmentationStatusOptions() {
      return [
        { value: null, text: this.$t('ai.segmentation_status') },
        { value: 'good', text: this.$t('trays.segmentation_status.good') },
        { value: 'not_validated', text: this.$t('trays.segmentation_status.not_validated') },
      ];
    },
  },
  watch: {
    roles: 'onRolesLoaded',
    '$route.query'() {
      this.filter = this.parseQuery();
      this.listTrays();
    },
    filter: {
      handler() {
        this.$router.push({ query: this.getQuery() }).catch(() => {});
      },
      deep: true,
    },
    dateTime: {
      handler() {
        if (this.dateTime.startDate && this.dateTime.startTime) {
          this.filter.fromCreatedAt = `${this.dateTime.startDate} ${this.dateTime.startTime}`;
        } else {
          this.filter.fromCreatedAt = null;
        }

        if (this.dateTime.endDate && this.dateTime.endTime) {
          this.filter.toCreatedAt = `${this.dateTime.endDate} ${this.dateTime.endTime}`;
        } else {
          this.filter.toCreatedAt = null;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$router.replace({ query: this.getQuery() }).catch(() => {});
    this.listTrays();
  },
  methods: {
    ...mapActions({
      list: VuexTypes.TRAYS_LIST,
    }),
    getQuery() {
      // Skip serializing null fields
      let query = {};
      for (const key in this.filter) {
        if (this.filter[key] !== null) {
          query[key] = this.filter[key];
        }
      }
      return query;
    },
    listTrays() {
      if (this.inflightRequest) {
        this.inflightRequest.abort();
        this.inflightRequest = null;
      }

      this.inflightRequest = new AbortController();
      this.list({filter: this.filter, signal: this.inflightRequest.signal});
    },
    parseQuery() {
      return {
        currentPage: Number(this.currentPage),
        workflow: this.workflow,
        status: this.status,
        annotationStatus: this.annotationStatus,
        segmentationStatus: this.segmentationStatus,
        ids: (this.ids instanceof Array) ? this.ids.map((item) => Number(item)) : Number(this.ids),
        fromCreatedAt: this.fromCreatedAt,
        toCreatedAt: this.toCreatedAt,
      };
    },
    formSaved() {
      this.listTrays();
    },
    initPreview(item) {
      this.loading.getImg = true;
      this.preview.imageSrc = this.preview.placeHolder;
      TraysService.getImg(item.id).then((response) => {
        this.loading.getImg = false;
        this.$set(this.preview, 'imageSrc', `data:image/jpeg;base64,${response.data.image}`);
      }).catch(() => {
        this.loading.getImg = false;
      });
    },
    optionsRequest(query) {
      if (this.search.debounce) {
        clearTimeout(this.search.debounce);
      }
      this.search.debounce = setTimeout(() => {
        this.search.loading = true;
        TraysService.searchOptions(query).then((response) => {
          this.$nextTick(() => {
            this.search.options = response.data.map((item) => item.id);
          });
        }).catch((e) => {
          console.error('Got error when listing languages.', e);
        }).finally(() => {
          this.search.loading = false;
        });
      }, 200);
    },
    limitText(count) {
      return `[+ ${count}]`;
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}
</style>

<style lang="scss">
.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}

.multiselect.multiselect--active {
  .multiselect__tags {
    border-color: #003C7E;
  }
}

.multiselect {
  .multiselect__input:focus {
    border: none !important;
  }

  .multiselect__input {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .multiselect__tags {
    border-radius: 0;
    padding-top: 0;
    min-height: 36px;
    border-color: #c2cfd6;
  }

  .multiselect__placeholder {
    margin-bottom: 0;
    padding-top: 7px;
  }

  .multiselect__select {
    height: 36px;
  }

  .multiselect__tag,
  .multiselect__strong {
    margin-bottom: 0;
    margin-top: 5px;
  }

}
</style>
