<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('categories.header') }}
            <b-btn
              class="pull-right"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
          </div>
          <div class="card-block">
            <table class="table table-striped table-condensed">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('global.name') }}</th>
                  <th>
                    {{ $t('global.name_translations') }}
                  </th>
                  <th>{{ $t('global.actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in categories.list.data"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td v-if="activeLanguages.length > 0">
                    <b-badge
                      v-for="translation, key in extractNameTranslations(item)"
                      :key="key"
                    >
                      {{ key }}: {{ translation }}
                    </b-badge>
                  </td>
                  <td>{{ item.parent ? item.parent.name : '' }}</td>
                  <td>
                    <button
                      v-if="can('super-admin', 'cvision-admin')"
                      type="button"
                      class="btn btn-success btn-sm table-button-size"
                      @click="editItem(item)"
                    >
                      <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                    </button>
                    <button
                      v-if="can('super-admin', 'cvision-admin')"
                      type="button"
                      class="btn btn-warning btn-sm table-button-size"
                      @click="deleteItem(item)"
                    >
                      <i class="fa fa-trash" /> {{ $t('global.delete') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-pagination
              v-model="currentPage"
              :total-rows="categories.list.total"
              :per-page="categories.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <category-form
          v-model="addItem"
          :all="all"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import CategoryForm from './CategoryForm';
import CategoriesService from '@/services/categories-service';

export default {
  name      : 'Categories',
  components: { CategoryForm },
  data () {
    return {
      currentPage: 1,
      addItem    : {},
      all        : [],
      showAddForm: false,
    };
  },
  computed: {
    ...mapState(['categories']),
    ...mapGetters([
      'activeLanguages',
      'can',
    ]),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.fetch();
    },
  },
  mounted () {
    this.fetch();
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.CATEGORIES_LIST,
      delete: VuexTypes.CATEGORIES_DELETE,
    }),
    extractNameTranslations(item) {
      const nameTranslations = {};
      if (item?.translations?.name) {
        this.activeLanguages.forEach(language => {
          const translation = item.translations.name[language.key];
          if (translation) { nameTranslations[language.key] = translation; }
        });
      }
      return nameTranslations;
    },
    setAddItem(addItem) {
      if (!addItem?.translations?.name) {
        addItem.translations = { name: {} };
      }
      this.addItem = addItem;
    },
    editItem (item) {
      this.showAddForm = false;
      this.setAddItem(Object.assign({}, item));
    },
    deleteItem (item) {
      if (confirm('Are you sure you want to delete?')) {
        this.delete(item.id);
        this.setAddItem({});
      }
    },
    formSaved () {
      this.showAddForm = false;
      this.setAddItem({});
      this.fetch();
    },
    fetch () {
      this.list(this.currentPage);
      CategoriesService.all().then((response) => {
        this.all = response.data;
      }).catch(() => {
        this.loading.restaurants = false;
        this.$notify({
          type : 'error',
          title: 'Error',
          text : 'Error on fetching data (CategoriesService.all)',
        });
      });
    },
    openAddForm () {
      this.showAddForm = true;
      this.setAddItem({});
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
