import Api from '@/services/api';

export default {
  list (search) {
    // if (!search) {
    //   search = {};
    //   search.currentPage = '';
    //   search.enabledOnly = '';
    // } else if (!search.currentPage || typeof search.currentPage === 'undefined') {
    //   search.currentPage = '';
    // } else if (!search.enabledOnly || typeof search.enabledOnly === 'undefined') {
    //   search.enabledOnly = '';
    // }

    // let hasCashDesk = '';
    // if (search.has_cash_desk) {
    //   hasCashDesk = '&has_cash_desk=true';
    // }

    return Api().get('/raspberry', { params: search });
  },
  get (id) {
    return Api().get(`/raspberry/${id}`);
  },
  store (data) {
    return Api().post('/raspberry', data);
  },
  update (data) {
    return Api().put(`/raspberry/${data.id}`, data);
  },
  assign (data) {
    return Api().post('/raspberry/assign', data);
  },
  delete (id) {
    return Api().delete(`/raspberry/${id}`);
  },
};
