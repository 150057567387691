<template>
  <div class="trays">
    <tray
      v-for="(tray, index) in trays?.slice().reverse()"
      :key="trays.length - index - 1"
      :tray="tray"
      :tray-index="trays.length - index - 1"
      :order="order"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import Tray from '@/views/Cashier/CashDesk/Tray/Tray.vue';

export default {
  name      : 'OrderTrays',
  components: {
    Tray,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { };
  },
  computed: {
    ...mapState('cashierTrays', {
      traysByOrder: 'byOrder',
    }),
    trays() {
      return this.traysByOrder[this.order.id];
    },
  },
  watch: { },
  mounted() {
    
  },  
  methods: { },
};
</script>

<style lang="scss" scoped>
  .tray {
    background: #FFFFFF;
    border-radius: 10px;padding: 20px;
    margin: 20px 0;

    .tray-header {
      display: flex;
      justify-content: space-between;

      >div:first-child {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
      }

      >div:nth-child(2) {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: #777777;
      }
    }

    .toggler {
      &.collapsed {
        img {
          transform: rotate(90deg);
        }
      }

      img {
        -webkit-transition: 0.1s ease-in-out;
        -moz-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
      }
    }

    /* Animācijas var bremzēt kasierim */
    .collapse-content {
      &.collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
      }
    }
  }

  .tray-img {
    background: url('@/static/img/board.jpg');
    background-repeat: no-repeat;
    height: 521px;

    img {
      height: 100%;
    }
  }
</style>
