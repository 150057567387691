import camelCase from 'lodash/camelCase';

const requireModule = require.context('.', false, /\.js$/);

const modules = {};

// Auto loader vuex modules
requireModule.keys().forEach((fileName) => {
  if (fileName === './index.js') { return; }

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));

  modules[moduleName] = {
    namespaced: false,
    ...requireModule(fileName).default,
  };
});

/* For cashier view */
const cashierModules = require.context('./Cashier', false, /\.js$/);
// Auto loader cashier vuex modules
cashierModules.keys().forEach((fileName) => {
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));
  
  modules[moduleName] = {
    namespaced: true,
    ...cashierModules( fileName).default,
  };
});


export default modules;
