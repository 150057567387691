<template>
  <b-card
    :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }"
    header-tag="header"
  >
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }} </span>
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="closeClick"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <b-form-group
        :label="$t('global.name')"
        :state="!errors.name"
        :invalid-feedback="errors.name"
      >
        <b-form-input
          v-model="product.name"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('global.description')"
        :state="!errors.description"
        :invalid-feedback="errors.description"
      >
        <b-form-input
          v-model="product.description"
          type="text"
        />
      </b-form-group>
      <b-form-group
        :label="$t('product_types.human_product')"
        :state="!errors.human_product_id"
        :invalid-feedback="errors.human_product_id"
      >
        <b-select-2
          v-model="product.human_product_id"
          :options="options.humanProducts"
        />
      </b-form-group>
      <b-form-group
        :label="$t('product_types.one_per_plate')"
        :state="!errors.one_per_plate"
        :invalid-feedback="errors.one_per_plate"
      >
        <b-form-checkbox
          v-model="product.one_per_plate"
        />
      </b-form-group>

      <b-form-group
        :label="$dt('product_types.reject', 'Reject')"
        :state="!errors.reject"
        :invalid-feedback="errors.reject"
      >
        <b-form-checkbox
          v-model="product.reject"
        />
      </b-form-group>

      <b-form-group
        v-if="activeLanguages.length > 0"
        :label="$dt('product_types.rejection_message', 'Rejection message')"
        :state="!errors.rejection_message"
        :invalid-feedback="errors.rejection_message"
      >
        <div class="border px-3 pt-3">
          <b-form-group
            v-for="({ key }) in activeLanguages"
            :key="key"
            :label="key"
            label-cols="2"
          >
            <b-form-input
              v-model="product.rejection_message[key]"
              type="text"
            />
          </b-form-group>
        </div>
      </b-form-group>

      <div>
        <b-btn
          v-if="showCancel || isEditing"
          variant="outline-danger"
          @click="closeClick"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          v-if="isEditing"
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
        <b-btn
          v-else
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapGetters, mapState } from 'vuex';
import HumanProductsService from '@/services/human-products-service';

export default {
  name: 'ProductTypesForm',

  props: {
    value: {
      type: Object,
      required: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default () {
        return false;
      },
    },
  },

  data () {
    return {
      product: {
        id: undefined,
        name: undefined,
        description: undefined,
        one_per_plate: undefined,
        human_product_id: undefined,
        created_at: undefined,
        updated_at: undefined,
        reject: undefined,
        rejection_message: {},
        segmented_images_count: undefined,
        segmented_images_unused_count: undefined,
        segmented_images_train_count: undefined,
        segmented_images_test_count: undefined,
        product: undefined,
      },
      errors: {},
      options: {
        humanProducts: [],
      },
    };
  },

  computed: {
    ...mapState(['productTypes']),
    ...mapGetters(['activeLanguages']),
    isEditing () {
      return this.product?.id > 0;
    },
  },

  watch: {
    value: function (newVal) {
      this.cloneAndNormalize(newVal);
    },
  },

  mounted() {
    this.loadHumanProducts();
    this.cloneAndNormalize(this.value);
  },

  methods: {
    ...mapActions({
      add   : VuexTypes.PRODUCT_TYPES_ADD,
      update: VuexTypes.PRODUCT_TYPES_UPDATE,
    }),
    closeClick() {
      this.$emit('saved', false);
    },
    save() {
      const action = this.isEditing ? this.update(this.product) : this.add(this.product);

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch(({ data }) => {
          this.errors = {};
          if (data !== undefined) {
            this.errors = data;
          }
          for (const item in data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    loadHumanProducts() {
      this.options.humanProducts = [];
      HumanProductsService.listAll()
        .then(({ data }) => {
          let humanProducts = data.map(({ id, code, name }) => {
            return {
              id: id,
              text: code ? `${code} - ${name}` : `${name}`,
            };
          });
          humanProducts.unshift({ id: '', text: '' });
          this.options.humanProducts = Object.assign([], humanProducts);
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('global.connection_lost_service', { service: 'HumanProductsService.listAll' }), {
            title  : this.$t('global.error'),
            variant: 'danger',
            solid  : true,
          });
        });
    },
    cloneAndNormalize(product) {
      const cloned = structuredClone(product);
      if (!cloned.rejection_message || (Array.isArray(cloned.rejection_message) && !cloned.rejection_message.length)) {
        cloned.rejection_message = {};
      }
      for (const { key } of this.activeLanguages) {
        if (!cloned.rejection_message[key]) {
          cloned.rejection_message[key] = '';
        }
      }
      this.product = cloned;
    },
  },
};
</script>

<style>
</style>
