import { defaultMutations } from 'vuex-easy-access';
import { VuexTypes } from '../types';
import OrdersService from '@/services/orders-service';

const state = {
  list: {
    data        : [],
    current_page: 1,
    total       : 1,
  },
  item  : {},
  status: 'success',
};

const getters = {};

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  ...defaultMutations(state),
  [VuexTypes.ORDERS_LOAD]: (state, status) => {
    state.status = status;
  },
  [VuexTypes.ORDERS_LIST]: (state, list) => {
    state.list = list;
    state.status = 'success';
  },
};

const actions = {
  [VuexTypes.ORDERS_LIST]: ({ commit, _dispatch }, currentPage) => {
    commit(VuexTypes.ORDERS_LOAD, 'loading');
    return new Promise((resolve, reject) => {
      OrdersService.list(currentPage)
        .then((resp) => {
          commit(VuexTypes.ORDERS_LIST, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(VuexTypes.ORDERS_LOAD, 'error');
          reject(error);
        });
    });
  },
  [VuexTypes.ORDERS_ADD]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ORDERS_LOAD, 'loading');
      OrdersService.store(data)
        .then((resp) => {
          dispatch(VuexTypes.ORDERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ORDERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.ORDERS_UPDATE]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ORDERS_LOAD, 'loading');
      OrdersService.update(data)
        .then((resp) => {
          dispatch(VuexTypes.ORDERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ORDERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.ORDERS_DELETE]: ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.ORDERS_LOAD, 'loading');
      OrdersService.delete(id)
        .then((resp) => {
          dispatch(VuexTypes.ORDERS_LIST, state.list.current_page).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.ORDERS_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
