<template>
  <b-card :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }" header-tag="header">
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-pencil" /> {{ $t('global.edit') }}</span>
      <span v-else><i class="fa icon-plus" /> {{ $t('global.add_new') }}</span>
      <div class="card-actions">
        <a href="#" class="btn btn-setting" @click="closeClick">
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form @submit.prevent="save">
      <img v-if="modelData.icon_base64" :src="modelData.icon_base64"
        class="img-thumbnail mx-auto d-block mb-2 bg-secondary" alt="Product image">
      <b-form-group :label="$t('categories.category_name')" :state="!errors.name" :invalid-feedback="errors.name">
        <b-form-input v-model="modelData.name" type="text" />
      </b-form-group>
      <b-form-group v-if="activeLanguages.length > 0" :label="$t('global.name_translations')"
        :state="!errors.name_translations" :invalid-feedback="errors.name_translations">
        <div class="border px-3 pt-3">
          <b-form-group v-for="language in activeLanguages" :key="language.key" :label="language.key" label-cols="2">
            <b-form-input v-model="modelData.translations.name[language.key]" type="text" />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group :label="$t('global.icon')" :state="!errors.image" :invalid-feedback="errors.image"
        :valid-feedback="newImage && $t('categories.new_image_ready_for_upload')">
        <b-input-group>
          <b-form-file ref="imageFileInput" accept="image/jpeg, image/png, image/svg+xml" @input="parseNewImageFile" />
          <b-input-group-append v-if="newImage">
            <b-btn variant="danger" @click="clearNewImage">
              <i class="fa fa-trash" />
            </b-btn>
          </b-input-group-append>
        </b-input-group>
        <img v-if="newImage" :src="newImage" class="img-fluid mt-2" alt="Product category image to upload">
      </b-form-group>
      <b-form-group :label="$t('categories.category_parent_label')" :state="!errors.category"
        :invalid-feedback="errors.category">
        <b-select-2 v-model="category" :options="opt" />
      </b-form-group>
      <div>
        <b-btn v-if="showCancel || isEditing" variant="outline-danger" @click="closeClick">
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn class="pull-right" type="submit" variant="success">
          {{ $t('global.save') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'CategoryForm',

  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return { enabled: false };
      },
    },
    all: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    showCancel: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      modelData: _.cloneDeep(this.value),
      errors: {},
      category: this.value?.parent?.id || '',
      newImage: null,
    };
  },
  computed: {
    ...mapState(['categories']),
    ...mapGetters([
      'activeLanguages',
    ]),
    isEditing() {
      return this.modelData?.id > 0;
    },
    opt() {
      const array = this.all.map((a) => {
        return { text: a.name, id: a.id };
      });
      array.unshift({ id: 'none', text: this.$t('global.none') });

      return array;
    },
  },
  watch: {
    value: function (newVal) {
      this.modelData = newVal;
      if (this.modelData.parent) {
        this.category = this.modelData.parent.id;
      } else {
        this.category = '';
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      add: VuexTypes.CATEGORIES_ADD,
      update: VuexTypes.CATEGORIES_UPDATE,
    }),
    closeClick() {
      this.$emit('saved', false);
    },
    save() {
      this.modelData.parent_id = this.category === 'none' ? null : this.category;
      const prevImage = this.modelData.icon_base64;
      this.modelData.icon_base64 = this.newImage;
      const action = this.isEditing ? this.update(this.modelData) : this.add(this.modelData);

      action
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
          this.modelData.icon_base64 = prevImage;
        });
    },
    parseNewImageFile(file) {
      if (!file) { return; }

      const reader = new FileReader();
      reader.onload = function (e) {
        this.newImage = e.target.result;
      }.bind(this);
      reader.onerror = function (error) {
        console.error('Got error while parsing image file: ', error);
        this.clearNewImage();
      }.bind(this);
      reader.readAsDataURL(file);
    },
    clearNewImage() {
      this.$refs.imageFileInput.reset();
      this.newImage = null;
    },
  },
};
</script>
<style></style>
