import { defaultMutations } from 'vuex-easy-access';

import { VuexTypes } from '../types';
import AuthService from '@/services/auth-service';
import Api from '@/services/api';
import router from '../../router';
import jwt from 'jsonwebtoken';

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  env: {},
  user: {},
  permissions: [],
  permissionsLoaded: false,
};

const getters = {
  isAuthenticated: (state) => {
    if (!state.token) {
      return false;
    }
    const decoded = jwt.decode(state.token);
    const unixNow = Math.floor(Date.now() / 1000);
    return decoded.exp > unixNow;
  },
  can: (state) => (...permissions) => permissions.some((permission) => state.permissions.includes(permission)),
};

function dispatchPermsRoute(userData) {
  if (router.currentRoute.name !== 'Root' && router.currentRoute.name !== 'Login') {
    return false;
  }
  if (userData.permissions.includes('super-admin', 'cvision-admin')) {
    router.push({ name: 'Home' });
    return true;
  } else if (userData.permissions.includes('data-grab')) {
    router.push({ name: 'GrabData' });
    return true;
  } else if (userData.permissions.includes('cashier')) {
    router.push({ name: 'Cashier' });
    return true;
  } else if (userData.permissions.includes('raspberry')) {
    router.push({ name: 'RaspberryEmulator' });
    return true;
  }
  return false;
}

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  ...defaultMutations(state),
  [VuexTypes.AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [VuexTypes.AUTH_SUCCESS]: (state, { token, userData, environment }) => {
    state.status = 'success';
    state.token = token;
    state.env = environment;
    Vue.set(state, 'user', userData.user);
    Vue.set(state, 'permissions', userData.permissions);
    state.permissionsLoaded = true;
  },
  [VuexTypes.AUTH_ERROR]: (state) => {
    state.status = 'error';
  },
  [VuexTypes.AUTH_LOGOUT]: (state) => {
    state.status = 'success';
    state.token = '';
  },
};

const actions = {
  [VuexTypes.AUTH_REQUEST]: ({ commit, _dispatch }, { username, password }) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(VuexTypes.AUTH_REQUEST);

      AuthService.login(username, password)
        .then((resp) => {
          const token = resp.data.token;
          const userData = resp.data.user;
          const environment = resp.data.env;

          localStorage.setItem('user-token', token); // store the token in localstorage
          Api().defaults.headers.common['Authorization'] = `Bearer ${token}`;

          commit(VuexTypes.AUTH_SUCCESS, {
            token, userData, environment,
          });
          if (!dispatchPermsRoute(userData)) {
            resolve(resp);
          }
          // you have your token, now log in your user :)
          // dispatch(VuexTypes.USER_REQUEST) // TODO
        })
        .catch((error) => {
          commit(VuexTypes.AUTH_ERROR, error);
          localStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
          reject(error);
        });
    });
  },
  [VuexTypes.AUTH_LOGOUT]: ({ commit, _dispatch }) => {
    return new Promise((resolve, _reject) => {
      commit(VuexTypes.AUTH_LOGOUT);
      localStorage.removeItem('user-token'); // clear your user's token from localstorage
      delete axios.defaults.headers.common['Authorization'];
      router.push({ path: '/pages/login' }); // redirect
      resolve();
    });
  },

  [VuexTypes.CHECK_AUTH_INFO]: ({ state, commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (!state.token) {
        resolve();
        return;
      }
      AuthService.user()
        .then((resp) => {
          const userData = resp.data.user;
          const environment = resp.data.env;
          const token = state.token;

          commit(VuexTypes.AUTH_SUCCESS, {
            token, userData, environment,
          });
          if (!dispatchPermsRoute(userData)) {
            resolve(resp);
          }
        })
        .catch((error) => {
          // Don't logout if the response is a server error
          if (error?.response?.status && error.response.status > 499 && error.response.status < 600) {
            reject(error);
            return;
          }

          dispatch(VuexTypes.AUTH_LOGOUT).then((_resp) => {
            reject(error);
          });
        });
    });
  },

  [VuexTypes.CHANGE_PASSWORD]: ({ _state, _commit, _dispatch }, data) => {
    return new Promise((resolve, reject) => {
      AuthService.changePassword(data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.REGISTER_USER]: ({ _state, _commit, _dispatch }, data) => {
    return new Promise((resolve, reject) => {
      AuthService.register(data)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
