<template>
  <b-card
    class="bg-light-yellow"
    header-tag="header"
  >
    <div slot="header">
      <i class="fa icon-plus" /> {{ $t('models.import_classes') }}
      <div class="card-actions">
        <a
          href="#"
          class="btn btn-setting"
          @click="$emit('cancel', true)"
        >
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form>
      <b-form-group :label="$t('models.class_list')">
        <b-select-2
          v-model="selectedClassList"
          :options="options"
        />
      </b-form-group>
      <b-form-group
        v-if="classes.length"
        :label="$t('models.classes_to_be_imported')"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in classes"
            :key="`class-group-item-${index}`"
            class="d-flex justify-content-between"
          >
            <span>
              {{ item.name }}<br>
              <small>{{ $t('models.next_model_id') }}: {{ item.next_model_id }}<br>{{ $t('models.product_types') }}: {{ item.product_types | formatProductTypes }}</small>
            </span>
            <i
              class="far fa-trash-alt text-danger"
              style="cursor: pointer;"
              @click.prevent="removeClass(item, index)"
            />
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
      <div>
        <b-btn
          variant="outline-danger"
          @click.prevent="$emit('cancel', true)"
        >
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn
          class="pull-right"
          variant="success"
          :disabled="!classes.length"
          @click.prevent="importClasses"
        >
          {{ $t('global.import') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>

<script>
import ClassGroupService from '@/services/class-group-service';
import ModelClassesService from '@/services/model-classes-service';

export default {
  name: 'ClassesListsForm',
  filters: {
    formatProductTypes: function (value) {
      if (!value) return '';
      return value.map(item => item.name).join(', ');
    },
  },
  props: {
    modelId: {
      type: [String, Number],
      required: false,
    },
  },
  data () {
    return {
      selectedClassList: null,
      classes: [],
      data: [],
    };
  },
  computed: {
    options () {
      return this.data.map((item) => {
        return { text: item.name, id: item.id };
      });
    },
  },
  watch: {
    selectedClassList: function (classGroupId) {
      if (classGroupId) {
        const classGroup = this.data.find((item) => {
          return item.id == classGroupId;
        });
        if (classGroup) {
          const classGroupItems = classGroup.class_group_items.filter((item) => {
            return this.classes.findIndex(c => c.id == item.id) === -1;
          });
          this.classes.push(...classGroupItems);
        }
        this.selectedClassList = null;
      }
    },
  },
  mounted () {
    ClassGroupService.list()
      .then((response) => {
        this.data = response.data;
      })
      .catch((error) => {
        console.error(error.response);
      });
  },
  methods: {
    importClasses () {
      const data = {
        model_id: this.modelId,
        classes: this.classes.map(item => item.id),
      };
      ModelClassesService.importClasses(data)
        .then(() => {
          this.$emit('saved');
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    removeClass (item, index) {
      this.classes.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
