import { VuexTypes } from '../types';
import LanguagesService from '@/services/languages-service';
import statuses from '@/store/statuses';

const state = {
  languagesList: { // Paginated list for crud
    data: [],
    status: statuses.EMPTY,
  },
  languagesClassifier: { // Global listing for dropdowns 
    data: [],
    status: statuses.EMPTY,
  },
};

const getters = {
  languagesList: (state) => state.languagesList,
  activeLanguages: (state) => state.languagesClassifier.data.filter((e) => e.is_active),
};

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  [VuexTypes.LANGUAGES_LIST]: (state, { data, status }) => {
    state.languagesList = { data, status };
  },
  [VuexTypes.LANGUAGES_CLASSIFICATOR]: (state, { data, status }) => {
    state.languagesClassifier = { data: data.data, status };
  },
};

const actions = {
  [VuexTypes.LANGUAGES_LIST]: ({ commit, state }, page = 1) => {
    state.languagesList['status'] = statuses.LOADING;
    LanguagesService.list(page).then((data) => {
      data.status = statuses.LOADED;
      commit(VuexTypes.LANGUAGES_LIST, data);
    }).catch((errResponse) => {
      commit(VuexTypes.LANGUAGES_LIST, errResponse);
    });
  },
  [VuexTypes.LANGUAGES_CLASSIFICATOR]: ({ commit, state }) => {
    state.languagesClassifier['status'] = statuses.LOADING;
    LanguagesService.classifier().then((data) => {
      data.status = statuses.LOADED;
      commit(VuexTypes.LANGUAGES_CLASSIFICATOR, data);
    }).catch((data) => {
      commit(VuexTypes.LANGUAGES_CLASSIFICATOR, data);
    });
  },
  [VuexTypes.LANGUAGE_CREATE_DATA]: () => {
    return new Promise((resolve, reject) => {
      LanguagesService.createData().then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.LANGUAGE_UPDATE_DATA]: ({}, id) => {
    return new Promise((resolve, reject) => {
      LanguagesService.editData(id).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.LANGUAGE_CREATE]: ({}, createData) => {
    return new Promise((resolve, reject) => {
      LanguagesService.create(createData).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.LANGUAGE_UPDATE]: ({}, updateData) => {
    return new Promise((resolve, reject) => {
      LanguagesService.update(updateData).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.LANGUAGE_DELETE]: ({}, id) => {
    return new Promise((resolve, reject) => {
      LanguagesService.delete(id).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
