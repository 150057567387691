<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col :sm="showForm ? '6' : '12'" :md="showForm ? '8' : '12'" :lg="showForm ? '9' : '12'">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('human_products.header') }}
            <div class="d-flex flex-column mt-2">
              <div class="d-flex flex-row justify-content-end flex-wrap mt-2" style="gap: 0.25rem">
                <b-btn size="sm" variant="success" @click.prevent="openAddForm()">
                  <i class="fas fa-plus" /> {{ $t('global.add_new') }}
                </b-btn>
                <pos-product-import @imported="listAndFilter" />
              </div>
              <!-- filters -->
              <div class="d-flex flex-row justify-content-end flex-wrap mt-2" style="gap: 0.25rem">
                <b-btn v-for="state, idx in [false, true]" :key="idx" size="sm"
                  :variant="filterPresent('has_image', state) ? 'primary' : ''" @click="toggleFilter('has_image', state)">
                  {{ state ? $t('human_products.has_image') : $t('human_products.no_image') }}
                </b-btn>
                <b-btn v-for="language in activeLanguages" :key="language.key" size="sm"
                  :variant="filterPresent('untranslated_name', language.key) ? 'primary' : ''"
                  @click="toggleFilterElements('untranslated_name', language.key)">
                  {{ $t('global.untranslated_name') }}: {{ language.name }}
                </b-btn>
                <b-btn size="sm" :variant="filtersInactive ? 'primary' : ''" @click="clearFilters()">
                  {{ $t('global.show_all') }}
                </b-btn>
              </div>
              <!-- end filters -->
              <div class="d-flex flex-row justify-content-end flex-wrap mt-1">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text><i class="fas fa-search" /></b-input-group-text>
                  </template>
                  <b-form-input v-model.trim="productName" />
                </b-input-group>
              </div>
            </div>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('global.name') }}</th>
                    <th v-if="activeLanguages.length > 0">
                      {{ $t('global.name_translations') }}
                    </th>
                    <th>{{ $t('human_products.has_image') }}</th>
                    <th>{{ $t('human_products.code') }}</th>
                    <th>{{ $t('human_products.types') }}</th>
                    <th>{{ $t('human_products.category') }}</th>
                    <th>{{ $t('human_products.visible_for_customer') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in humanProducts.list.data" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    <td v-if="activeLanguages.length > 0">
                      <b-badge v-for="translation, key in extractNameTranslations(item)" :key="key">
                        {{ key }}: {{ translation }}
                      </b-badge>
                    </td>
                    <td>
                      <b-form-checkbox :value="item.has_image" checked="true" disabled />
                    </td>
                    <td>{{ item.code }}</td>
                    <td>
                      <b-badge v-for="tag in item.product_types" :key="tag.id">
                        {{ tag.name }}
                      </b-badge>
                    </td>
                    <td>{{ getCategoryName(item.category) || $t('global.other') }}</td>
                    <td>
                      <b-form-checkbox :checked="item.visible_for_customer" disabled />
                    </td>
                    <td>
                      <button v-if="can('super-admin', 'cvision-admin')" type="button"
                        class="btn btn-success btn-sm table-button-size" @click="editItem(item)">
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button v-if="can('super-admin', 'cvision-admin')" type="button"
                        class="btn btn-warning btn-sm table-button-size" @click="deleteItem(item)">
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-model="currentPage" :total-rows="humanProducts.list.total"
              :per-page="humanProducts.list.per_page" class="mt-4" />
          </div>
        </div>
      </b-col>
      <b-col v-if="showForm" sm="6" md="4" lg="3">
        <human-products-form v-model="addItem" :show-cancel="true" @saved="formSaved" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import HumanProductsForm from './HumanProductsForm';
import HumanProductsService from '@/services/human-products-service';
import { getCategoryName } from '@/helpers/tray';
import PosProductImport from '@/components/PosProductImport';

export default {
  name: 'HumanProducts',
  components: { HumanProductsForm, PosProductImport },
  data() {
    return {
      productName: '',
      filters: this.makeDefaultFilters(),
      currentPage: 1,
      addItem: {},
      searchTimeout: null,
      showAddForm: false,
      getCategoryName,
    };
  },
  computed: {
    ...mapState(['humanProducts']),
    ...mapGetters([
      'activeLanguages',
      'can',
    ]),
    showForm() {
      return this.addItem.id || this.showAddForm;
    },
    filtersActive() {
      for (const value of Object.values(this.filters)) {
        if (value.length > 0) { return true; }
      }
      return false;
    },
    filtersInactive() {
      return !this.filtersActive;
    },
  },
  watch: {
    roles: 'onRolesLoaded',
    currentPage: function () {
      this.listAndFilter();
    },
    productName: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.listAndFilter();
      }, 250);
    },
    filters: {
      handler: function () {
        this.listAndFilter();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadActiveLanguages();
    this.listAndFilter();
  },
  methods: {
    ...mapActions({
      list: VuexTypes.HUMAN_PRODUCTS_LIST,
      delete: VuexTypes.HUMAN_PRODUCTS_DELETE,

      loadActiveLanguages: VuexTypes.LANGUAGES_CLASSIFICATOR,
    }),
    editItem(item) {
      this.showAddForm = false;
      this.setAddItem(Object.assign({}, item));
    },
    deleteItem(item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.setAddItem({});
      }
    },
    formSaved(id) {
      if (!id) {
        this.showAddForm = false;
        this.setAddItem({});
        return;
      }
      this.showAddForm = false;
      HumanProductsService.get(id).then((item) => {
        if (item.data) {
          this.setAddItem(Object.assign({}, item.data));
        } else {
          this.setAddItem({});
        }
      }).catch(() => {
        this.setAddItem({});
      });
      this.listAndFilter();
    },
    openAddForm() {
      this.showAddForm = true;
      this.setAddItem({});
    },
    listAndFilter() {
      this.list({
        currentPage: this.currentPage,
        productName: this.productName,
        filters: this.filters,
      });
    },
    extractNameTranslations(item) {
      const nameTranslations = {};
      if (item?.translations?.name) {
        this.activeLanguages.forEach(language => {
          const translation = item.translations.name[language.key];
          if (translation) { nameTranslations[language.key] = translation; }
        });
      }
      return nameTranslations;
    },
    setAddItem(addItem) {
      if (!addItem?.translations?.name) {
        addItem.translations = { name: {} };
      }
      this.addItem = addItem;
    },
    toggleFilter(key, value) {
      const index = this.filters[key].indexOf(value);
      if (index < 0) {
        // Set
        this.filters[key] = [value];
      } else {
        // Clear
        this.filters[key] = [];
      }
    },
    toggleFilterElements(key, value) {
      const index = this.filters[key].indexOf(value);
      if (index < 0) {
        // Add
        this.filters[key].push(value);
      } else {
        // Remove
        this.filters[key].splice(index, 1);
      }
    },
    filterPresent(key, value) {
      return this.filters[key].indexOf(value) > -1;
    },
    clearFilters() {
      this.filters = this.makeDefaultFilters();
    },
    makeDefaultFilters() {
      return {
        untranslated_name: [],
        has_image: [],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}

.badge {
  margin-right: 2px;
}
</style>
