import Api from '@/services/api';

export default {
  list(currentPage) {
    let params = {};
    if (currentPage) {
      params = Object.assign(params, { page: currentPage });
    }
    return Api().get('/class_groups', {
      params: params,
    });
  },
  get(id) {
    return Api().get(`/class_groups/${id}`);
  },
  store(data) {
    return Api().post('/class_groups', data);
  },
  clone(id) {
    return Api().post(`/class_groups/${id}/clone`);
  },
  update(data) {
    return Api().put(`/class_groups/${data.id}`, data);
  },
  delete(id) {
    return Api().delete(`/class_groups/${id}`);
  },
};
