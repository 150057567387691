<template>
  <div class="theme-settings">
    <section
      class="theme-header" 
      @click="extended = !extended"
    >
      <span
        class="toggler"
        :class="{ extended: extended }"
      >
        <img src="@/static/img/chevron-right.svg">
        {{ $dt('technicalSettings.themeHeader', 'Theme') }}
      </span>
    </section>
    <section
      v-if="extended"
      class="theme-grid"
    >
      <theme-card
        v-for="theme in themes"
        :key="theme.id"
        :theme="theme"
        :active="theme.id === activeTheme"
        @click="setCashDeskTheme({raspberryId: currentRaspberry, cashDeskId: cashDeskId, themeId: theme.id})"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VuexTypes } from '@/store/types';
import ThemeCard from './ThemeCard.vue';
import { integer } from 'vuelidate/lib/validators';

export default {
  name: 'ThemeSettings',
  components: {
    ThemeCard,
  },
  props: {
    themes: {
      type: Array,
      required: true,
    },
    cashDeskId: {
      type: integer,
      required: true,
    },
  },
  data() {
    return {
      extended: false,
    };
  },
  computed: {
    ...mapGetters('cashierCashDesks', {
      cashDeskById: 'byId',
    }),
    ...mapState('cashierRaspberries', {
      currentRaspberry: state => state.currentRaspberry,
    }),
    activeTheme() {
      return this.cashDeskById(this.cashDeskId)?.theme_id;
    },
  },
  methods: {
    ...mapActions('cashierCashDesks', {
      setTheme: VuexTypes.CASH_DESK_SET_THEME,
    }),
    setCashDeskTheme(data) {
      this.setTheme(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-settings {
  background: #FFFFFF;
  border-radius: 10px;padding: 20px;
  margin: 20px 0;
}

.theme-header {
  display: flex;
  justify-content: space-between;
}

.toggler {
  &.extended {
    img {
      transform: rotate(90deg);
    }
  }

  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #000000;

  img {
    -webkit-transition: 0.1s ease-in-out;
    -moz-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
  }
}

/* Animācijas var bremzēt kasierim */
.theme-grid {
  display: grid;
  margin-top: 1.5rem;
  border-top: 1px solid #a4b7c1;
  padding-top: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 3rem;
  justify-content: center;
  align-items: start;
}
</style>
