<template>
  <div class="app">
    <div class="container">
      <div class="logo-container">
        <img
          class="signuplogo"
          src="../../static/img/logo-lido.png"
        >
      </div>
      <b-row
        class="justify-content-center"
      >
        <b-col md="8">
          <b-card
            no-body
            class="p-4 login-container"
          >
            <b-card-body>
              <b-form
                @submit.prevent="login"
              >
                <h2 class="login-header">
                  {{ $t('login.header') }}
                </h2>
                <b-alert
                  :show="loginErrorCountdown"
                  dismissible
                  variant="danger"
                  @dismissed="loginErrorCountdown=0"
                >
                  {{ loginError }}
                </b-alert>
                <b-input-group
                  class="mb-4"
                  size="lg"
                >
                  <b-input
                    v-model="form.username"
                    :state="$v.form.username | state"
                    type="email"
                    class="form-control input-round"
                    :placeholder="$t('login.email')"
                  />
                </b-input-group>
                <b-input-group
                  class="mb-4"
                  size="lg"
                >
                  <b-input
                    v-model="form.password"
                    :state="$v.form.password | state"
                    type="password"
                    class="form-control input-round"
                    :placeholder="$t('login.password')"
                  />
                </b-input-group>
                <b-row>
                  <b-col>
                    <b-button
                      class="mb-4"
                      variant="primary"
                      block
                      pill
                      type="submit"
                      size="lg"
                      :disabled="formFilled()"
                    >
                      {{
                        $t('login.login')
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <b-row>
                <b-col class="text-center">
                  <p class="lido-trademark">
                    &copy; {{ new Date().getFullYear() }} LIDO
                  </p>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import { VuexTypes } from '../../store/types';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: '',
      },
      loginErrorCountdown: 0,
      loginError         : '',
    };
  },
  validations () {
    return {
      form: {
        username: { required },
        password: { required },
      },
    };
  },
  methods: {
    formFilled() {
      if (this.form.username != '' && this.form.password != '') return false;
      return true;
    },
    ...mapGetters(['isAuthenticated']),
    login: function () {
      const { username, password } = this.form;
      this.$store.dispatch(VuexTypes.AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push({ path: '/home' }).catch(() => {});
      }).catch((error) => {
        console.error(error.response.status);
        if (error.response.status){
          this.loginErrorCountdown = 60;
          return this.loginError = 'The login and password combination is incorrect. Try again or contact administrator.';
        }
        this.loginError = error;
        this.loginErrorCountdown = 60;
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.app {
  background-image: url("../../static/img/background-lido.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
  }

.logo-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

img.signuplogo {
  object-fit: contain;
  max-width: clamp(8rem, 15vw, 12rem);
}

.login-container{
  max-width: 100%; border-radius: 0.5rem;
}

.login-header{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  line-height: 3rem;
  padding-bottom: 1rem;
}
.lido-trademark{
  font-weight: 400;
  font-size: 0.9rem;
  color: #777777;
}

 @media (min-width: 767px) {
  /* Medium size tablet */
  .app{
    padding: 4rem;
  }
  }

  @media (min-width:1281px) {
    /* Desktop */
  .app {
    background: none;
  }
}

</style>