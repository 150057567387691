<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('users.header') }}
            <b-btn
              class="pull-right"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('users.username') }}</th>
                    <th>{{ $t('users.email') }}</th>
                    <th>{{ $t('users.roles') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in users.list.data"
                    :key="user.id"
                  >
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <span
                        v-for="role in user.roles"
                        :key="role.name"
                        class="badge badge-success"
                      >{{ role.name }}</span>
                    </td>
                    <td>
                      <button
                        v-if="can('super-admin')"
                        type="button"
                        class="btn btn-success btn-sm table-button-size"
                        @click="editUser(user)"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button
                        v-if="can('super-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteUser(user)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="users.list.total"
              :per-page="users.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <user-form
          :value="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import UserForm from './UserForm';

export default {
  name      : 'Users',
  components: { UserForm },
  data () {
    return {
      currentPage: 1,
      addItem    : {},
      showAddForm: false,
    };
  },
  computed: {
    ...mapState(['users']),
    ...mapGetters(['can']),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.list(this.currentPage);
    },
  },
  mounted () {
    this.list(this.currentPage);
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.USERS_LIST,
      delete: VuexTypes.USERS_DELETE,
    }),
    editUser (user) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, user);
    },
    deleteUser (user) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(user.id);
        this.addItem = {};
      }
    },
    formSaved () {
      this.showAddForm = false;
      this.addItem = {};
    },
    openAddForm () {
      this.showAddForm = true;
      this.addItem = {};
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
