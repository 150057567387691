<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('segmented_images.header') }}
            <b-btn
              class="pull-right mt-1"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
            <b-input-group
              class="pull-right custom-select2"
              style="width: 35%;"
            >
              <template #prepend>
                <b-input-group-text><i class="fas fa-search" /></b-input-group-text>
              </template>
              <b-select-2
                v-model="searchByProductType"
                :options="optionsProductTypes"
                :placeholder="$t('segmented_images.product_type')"
              />
            </b-input-group>
          </div>
          <div class="card-block">
            <b-btn
              class="mb-1"
              size="sm"
              variant="danger"
              :disabled="deleteSegImages.selected.length === 0"
              @click.prevent="deleteItems"
            >
              <i class="fas fa-trash" /> {{ $t('global.delete_selected') }}
            </b-btn>
            <div class="table-responsive">
              <table class="table table-striped table-condensed">
                <thead>
                  <tr>
                    <th>
                      <b-form-checkbox
                        v-model="deleteSegImages.allSelected"
                        :indeterminate="deleteSegImages.indeterminate"
                        @change="toggleDeleteAll"
                      />
                    </th>
                    <th>#</th>
                    <th>{{ $t('segmented_images.image_path') }}</th>
                    <th>{{ $t('segmented_images.proc_status') }}</th>
                    <th>{{ $t('segmented_images.learning_state') }}</th>
                    <th>{{ $t('segmented_images.product_type') }}</th>
                    <th>{{ $t('segmented_images.data') }}</th>
                    <th>{{ $t('segmented_images.tray_image_id') }}</th>
                    <th>{{ $t('global.actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in segmentedImages.list.data"
                    :key="item.id"
                  >
                    <td>
                      <b-form-checkbox
                        v-model="deleteSegImages.selected"
                        :value="item.id"
                      />
                    </td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.image_path }}</td>
                    <td>{{ item.processing_status }}</td>
                    <td>{{ item.learning_state }}</td>
                    <td>
                      <b-badge v-if="item.product_type">
                        {{ item.product_type.name }}
                      </b-badge>
                    </td>
                    <td>
                      <b-form-textarea
                        :ref="`textarea-data-${item.id}`"
                        :value="onTextareaDataFormat(item.data, !!textareaOptions[`textarea-data-${item.id}`])"
                        :max-rows="textareaOptions[`textarea-data-${item.id}`] ? textareaOptions[`textarea-data-${item.id}`].maxRows : 2"
                        rows="2"
                        no-resize
                        size="sm"
                        style="width: 300px;"
                        @focus="onTextareaDataFocus(`textarea-data-${item.id}`)"
                        @blur="onTextareaDataBlur(`textarea-data-${item.id}`)"
                      />
                    </td>
                    <td>{{ item.tray_image_id }}</td>
                    <td>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-success btn-sm table-button-size"
                        @click="editItem(item)"
                      >
                        <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                      </button>
                      <button
                        v-if="can('super-admin', 'cvision-admin')"
                        type="button"
                        class="btn btn-warning btn-sm table-button-size"
                        @click="deleteItem(item)"
                      >
                        <i class="fa fa-trash" /> {{ $t('global.delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-btn
              size="sm"
              variant="danger"
              :disabled="deleteSegImages.selected.length === 0"
              @click.prevent="deleteItems"
            >
              <i class="fas fa-trash" /> {{ $t('global.delete_selected') }}
            </b-btn>
            <b-pagination
              v-model="currentPage"
              :total-rows="segmentedImages.list.total"
              :per-page="segmentedImages.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <segmented-image-form
          :model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import SegmentedImageForm from './SegmentedImageForm';
import SegmentedImagesService from '@/services/segmented-images-service';
import ProductTypesService from '@/services/product-types-service';

export default {
  name: 'SegmentedImages',
  components: { SegmentedImageForm },
  filters: {
    formatData: function (value) {
      return JSON.stringify(value, null, 2);
    },
  },
  data () {
    return {
      currentPage: 1,
      addItem    : {},
      textareaOptions: {},
      showAddForm: false,
      searchByProductType: '',
      allProductTypes: [],
      searchTimeout: null,
      deleteSegImages: {
        selected: [],
        allSelected: false,
        indeterminate: false,
      },
    };
  },
  computed: {
    ...mapState(['segmentedImages']),
    ...mapGetters(['can']),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
    optionsProductTypes () {
      const array = this.allProductTypes.map((a) => {
        return { text: `${a.name}`, id: a.id };
      });
      array.unshift({ id: -1, text: `- ${this.$t('segmented_images.without_product_type')} -` });
      array.unshift({ id: '', text: '' });
      return array;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.deleteSegImages.selected = [];
      this.list({ currentPage: this.currentPage, extraParams: { search: this.searchByProductType } });
    },
    searchByProductType: function () {
      this.deleteSegImages.selected = [];
      this.list({ currentPage: this.currentPage, extraParams: { search: this.searchByProductType } });
    },
    'deleteSegImages.selected': function (newVal) {
      if (newVal.length === 0) {
        this.deleteSegImages.indeterminate = false;
        this.deleteSegImages.allSelected = false;
      } else if (newVal.length === this.segmentedImages.list.data.length) {
        this.deleteSegImages.indeterminate = false;
        this.deleteSegImages.allSelected = true;
      } else {
        this.deleteSegImages.indeterminate = true;
        this.deleteSegImages.allSelected = false;
      }
    },
  },
  mounted () {
    this.list({ currentPage: this.currentPage, extraParams: { search: this.searchByProductType } });
    this.loadProductTypes();
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.SEGMENTED_IMAGES_LIST,
      delete: VuexTypes.SEGMENTED_IMAGES_DELETE,
    }),
    editItem (item) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, item);
    },
    deleteItem (item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
        this.addItem = {};
      }
    },
    formSaved (id) {
      this.showAddForm = false;
      SegmentedImagesService.get(id).then((item) => {
        if (item.data) {
          this.addItem = Object.assign({}, item.data);
        } else {
          this.addItem = {};
        }
      }).catch(() => {
        this.addItem = {};
      });
      this.list({ currentPage: this.currentPage, extraParams: { search: this.searchByProductType } });
    },
    onTextareaDataFormat (value, pretty = false) {
      return (!value) ? '' : (pretty) ? JSON.stringify(value, null, 2) : JSON.stringify(value);
    },
    onTextareaDataFocus (refName) {
      this.$set(this.textareaOptions, refName, {
        maxRows: 12,
      });
    },
    onTextareaDataBlur (refName) {
      this.$delete(this.textareaOptions, refName);
    },
    openAddForm () {
      this.showAddForm = true;
      this.addItem = {};
    },
    loadProductTypes () {
      ProductTypesService.getAll()
        .then((response) => {
          this.allProductTypes = response.data;
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    toggleDeleteAll (checked) {
      this.deleteSegImages.selected = checked ? this.segmentedImages.list.data.flatMap(item => item.id) : [];
    },
    deleteItems () {
      const deleteCount = this.deleteSegImages.selected.length;
      if (confirm(this.$tc('segmented_images.multi_delete_confirm_text', deleteCount))) {
        const data = {
          segmented_images: Object.assign([], this.deleteSegImages.selected),
        };
        SegmentedImagesService.deleteMulti(data).then(() => {
          this.deleteSegImages.selected = [];
          this.list({ currentPage: this.currentPage, extraParams: { search: this.searchByProductType } });
        }).catch(() => {
          this.$bvToast.toast(this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.deleteMulti' }), {
            title  : this.$t('global.error'),
            variant: 'danger',
            solid  : true,
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select2 ::v-deep {
  .select2-container--bootstrap4,
  .select2-selection--multiple,
  .select2-selection__clear {
    margin-top: 0;
  }

  .select2-container {
    width: calc(100% - 42px) !important;
  }
}
</style>
