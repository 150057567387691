export const CASH_DESK_STATES = {
  'UNKNOWN': 'UNKNOWN', // FE nezin kāds status, vajag pieprasīt
  'IN_PROGRESS': 'IN_PROGRESS', // Pircējs darbojas
  'LOCKED': 'LOCKED',
  'TO_VOID': 'TO_VOID', // Printē čeku
  'VOIDED': 'VOIDED',
  'ASSISTANCE': 'ASSISTANCE', // Pircējs paprasija palīdzību
  'VERIFY_AGE': 'VERIFY_AGE', // Kasierim jāapstiprina vecums
  'INIT_PAYMENT': 'INIT_PAYMENT',
  'PAID': 'PAID',
};
export const API_URL = window.ENV.VUE_APP_WEB_BACKEND_URL ? window.ENV.VUE_APP_WEB_BACKEND_URL : '/api';
export const SOCKET_URL = window.ENV.VUE_APP_SOCKET_URL ? window.ENV.VUE_APP_SOCKET_URL : '';
export const SOCKET_PATH = window.ENV.VUE_APP_SOCKET_PATH ? window.ENV.VUE_APP_SOCKET_PATH : '/ws';
export const SOCKET_SECURE = window.ENV.VUE_APP_SOCKET_SECURE == 'false' ? false : true;
export const STREAM_URL = window.ENV.VUE_APP_STREAM_URL;

export default {
  CASH_DESK_STATES,
  API_URL,
  SOCKET_URL,
  SOCKET_PATH,
  SOCKET_SECURE,
  STREAM_URL,
};