<template>
  <router-link
    :to="url"
    tag="li"
    :class="classList"
    disabled
  >
    <div
      class="nav-link nav-dropdown-toggle"
      @click="handleClick"
    >
      <i :class="icon" /> {{ $dt(name) }}
    </div>
    <ul class="nav-dropdown-items">
      <slot />
    </ul>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type   : String,
      default: '',
    },
    url: {
      type   : String,
      default: '',
    },
    icon: {
      type   : String,
      default: '',
    },
    classes: {
      type   : String,
      default: '',
    },
  },
  computed: {
    classList () {
      return [
        'nav-item',
        'nav-dropdown',
        ...this.itemClasses,
      ];
    },
    itemClasses () {
      return this.classes ? this.classes.split(' ') : '';
    },
  },
  methods: {
    handleClick (event) {
      event.preventDefault();
      event.target.parentElement.classList.toggle('open');
    },
  },
};
</script>
