<template>
  <div
    class="theme-card"
    :class="{active: active}"
    @click="$emit('click')"
  >
    <section
      class="theme-name" 
      :class="{active: active}"
    >
      {{ theme.name }}
    </section>
    <section
      class="theme-images"
    >
      <img
        class="background-image"
        :src="theme.background_image"
      >
      <img
        class="icon-image"
        :src="theme.icon_image"
      >
    </section>
  </div>
</template>

<script>


export default {
  name: 'ThemeCard',
  props: {
    theme: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>
  .theme-name {
    background-color: #f0f0f0;
    font-size: 1.5rem;
    padding: 0.5rem;
    font-weight: bold;
    text-align: center;

    &.active {
      background-color: #759f01;
      color: #f0f0f0;
    }
  }
  
  .theme-card {
    border: 2px solid #82a088;
    border-radius: 1rem;
    overflow: hidden;
    height: fit-content;
    box-sizing: border-box;

    &.active {
      border: 2px solid #759f01;
    }
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .theme-images {
    height: fit-content;
    position: relative;
  }
  
  .icon-image {
    max-width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>