import Vue from 'vue';
import Router from 'vue-router';

// Containers
import Full from '@/containers/Full';

// Views - Pages
import Page403 from '@/views/pages/Page403';
import Page404 from '@/views/pages/Page404';
import Page500 from '@/views/pages/Page500';
import Login from '@/views/pages/Login';
import Register from '@/views/pages/Register';

import Users from '@/views/Users/Users';
import Roles from '@/views/Users/Roles';
import UserProfile from '@/views/Users/UserProfile';

import Trays from '../views/Trays/Trays';
import TraysPlotter from '../views/Trays/TraysPlotter';
import Themes from '../views/Themes/Themes';
import Orders from '../views/CRUD/Orders/Orders';
import Categories from '../views/Categories/Categories';
import HumanProducts from '../views/HumanProducts/HumanProducts';
import ModelClasses from '../views/CRUD/ModelClasses/ModelClasses';
import Models from '../views/Models/Models';
import TrayProducts from '../views/CRUD/TrayProducts/TrayProducts';
import Restaurant from '../views/CRUD/Restaurant/Restaurant';
import ModelCheckpoint from '../views/CRUD/ModelCheckpoint/ModelCheckpoint';
import SegmentationModel from '../views/CRUD/SegmentationModel/SegmentationModel';
import SegmentationModelCheckpoint from '../views/CRUD/SegmentationModelCheckpoint/SegmentationModelCheckpoint';
import CashDesk from '../views/CashDesk/CashDesk';
import CashDeskProductList from '../views/CRUD/CashDeskProductList/CashDeskProductList';

import Dashboards from '@/views/Dashboards/Dashboards';
import Raspberry from '../views/Raspberry/Raspberry';
import GrabData from '@/views/GrabData/GrabData';
import ModelCreate from '../views/Models/ModelCreate';
import ModelEdit from '../views/Models/ModelEdit';
import ProductTypes from '../views/ProductTypes/ProductTypes';
import ProductTypesView from '../views/ProductTypes/ProductTypesView';
import RaspberryEmulator from '@/views/dummy/RaspberryEmulator/RaspberryEmulator';
import ClassGroups from '../views/ClassGroups/ClassGroups';
import ClassGroupCreate from '../views/ClassGroups/ClassGroupCreate';
import ClassGroupEdit from '../views/ClassGroups/ClassGroupEdit';
import SegmentedImages from '../views/SegmentedImages/SegmentedImages';
import Combo from '@/views/Combo/Combo.vue';

const Translations = () => import('@/views/Translations/Translations');
const TranslationForm = () => import('@/views/Translations/TranslationForm');

const Languages = () => import('@/views/Languages/Languages');
const LanguageForm = () => import('@/views/Languages/LanguageForm');


import Cashier from '@/views/Cashier/Cashier';

import { VuexTypes } from '@/store/types';

import store from '../store';

Vue.use(Router);

function checkWhenHasPerms(to, next) {
  if (!store.state.auth.permissionsLoaded) {
    const unsub = store.subscribe((mutation, _state) => {
      if (VuexTypes.AUTH_SUCCESS !== mutation.type) { return; }
      unsub();
      if ('hasAny' in to.meta && !to.meta.hasAny.reduce((v, x) => { return v || store.getters.can(x); }, false)) {
        next('/pages/403');
      } else {
        next();
      }
    });
  } else if ('hasAny' in to.meta && !to.meta.hasAny.reduce((v, x) => { return v || store.getters.can(x); }, false)) {
    next('/pages/403');
  } else {
    next();
  }
}

const authCheck = (to, from, next) => {
  if ('public' in to.meta && to.meta.public) {
    next();
    return;
  } else if (!store.getters.isAuthenticated) {
    next('/pages/login');
    return;
  } else if ('hasAny' in to.meta) {
    checkWhenHasPerms(to, next);
    return;
  }
  next();
};

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Root',
      redirect: '/home',
    },
    {
      path: '/home',
      redirect: '/home/',
      name: 'Home',
      alias: '/dashboard',
      component: Full,
      meta: {
        label: Vue.prototype.$dt('breadcrumb.home'),
      },
      children: [
        {
          path: '/',
          component: Dashboards,
          meta: {
            titleKey: Vue.prototype.$tk('route.title.home'),
          },
        },
        {
          path: '/grab_data',
          name: 'GrabData',
          component: GrabData,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.grab_data'),
          },
        },
        {
          path: '/raspberry_emulator',
          name: 'RaspberryEmulator',
          component: RaspberryEmulator,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.raspberry_emulator'),
          },
        },
        {
          path: '/trays',
          redirect: '/trays/list',
          name: 'Trays',
          component: { render(c) { return c('router-view'); } },
          meta: {
            label: Vue.prototype.$dt('breadcrumb.trays'),
          },
          children: [
            {
              path: 'list',
              name: 'TraysList',
              component: Trays,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.trays_list'),
              },
              props: (route) => {
                return route.query;
              },
            },
            {
              path: ':id/plotter',
              name: 'TraysPlotter',
              component: TraysPlotter,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.trays_plotter'),
              },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                };
              },
            },
          ],
        },
        {
          path: '/orders',
          name: 'Orders',
          component: Orders,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.orders'),
          },
        },
        {
          path: '/categories',
          name: 'Categories',
          component: Categories,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.categories'),
          },
        },
        {
          path: '/segmented_images',
          name: 'SegmentedImages',
          component: SegmentedImages,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.segmented_images'),
          },
        },
        {
          path: '/product_types',
          redirect: { name: 'ProductTypesList' },
          name: 'ProductTypes',
          component: { render(c) { return c('router-view'); } },
          meta: {
            label: Vue.prototype.$dt('breadcrumb.product_types'),
          },
          children: [
            {
              path: '/',
              name: 'ProductTypesList',
              component: ProductTypes,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.product_types_list'),
              },
              props: (route) => {
                return route.query;
              },
            },
            {
              path: ':id',
              name: 'ProductTypesView',
              component: ProductTypesView,
              meta: {
                hasAny: ['super-admin', 'cvision-admin'],
                label: Vue.prototype.$dt('breadcrumb.product_types_view'),
              },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                  ...route.query,
                };
              },
            },
          ],
        },
        {
          path: '/human_products',
          name: 'HumanProducts',
          component: HumanProducts,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.human_products'),
          },
        },
        {
          path: '/combo',
          name: 'Combo',
          component: Combo,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.combo'),
          },
        },
        {
          path: '/model_classes',
          name: 'ModelClasses',
          component: ModelClasses,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.model_classes'),
          },
        },
        {
          path: '/models',
          redirect: '/models/list',
          name: 'Models',
          component: { render(c) { return c('router-view'); } },
          meta: {
            label: Vue.prototype.$dt('breadcrumb.models'),
          },
          children: [
            {
              path: 'list',
              name: 'ModelsList',
              component: Models,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.models_list'),
              },
            },
            {
              path: 'create',
              name: 'ModelsCreate',
              component: ModelCreate,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.models_create'),
              },
            },
            {
              path: ':id/edit',
              name: 'ModelsEdit',
              component: ModelEdit,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.models_edit'),
              },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                };
              },
            },
          ],
        },
        {
          path: '/tray_products',
          name: 'TrayProducts',
          component: TrayProducts,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.tray_products'),
          },
        },
        {
          path: '/restaurant',
          name: 'Restaurant',
          component: Restaurant,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.restaurant'),
          },
        },
        {
          path: '/raspberry',
          name: 'Raspberry',
          component: Raspberry,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.raspberry'),
          },
        },
        {
          path: '/segmentation_model',
          name: 'SegmentationModel',
          component: SegmentationModel,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.segmentation_model'),
          },
        },
        {
          path: '/segmentation_model_checkpoint',
          name: 'SegmentationModelCheckpoint',
          component: SegmentationModelCheckpoint,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.segmentation_model_checkpoint'),
          },
        },
        {
          path: '/cash_desk',
          name: 'CashDesk',
          component: CashDesk,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.cash_desk'),
          },
        },
        {
          path: '/cash_desk_products_list',
          name: 'CashDeskProductList',
          component: CashDeskProductList,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.cash_desk_products_list'),
          },
        },
        {
          path: '/class_lists',
          redirect: { name: 'ClassListsList' },
          name: 'ClassLists',
          component: { render(c) { return c('router-view'); } },
          meta: {
            label: Vue.prototype.$dt('breadcrumb.class_lists'),
          },
          children: [
            {
              path: '/',
              name: 'ClassListsList',
              component: ClassGroups,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.class_lists_list'),
              },
            },
            {
              path: 'create',
              name: 'ClassListsCreate',
              component: ClassGroupCreate,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.class_lists_create'),
              },
            },
            {
              path: ':id/edit',
              name: 'ClassListsEdit',
              component: ClassGroupEdit,
              meta: {
                label: Vue.prototype.$dt('breadcrumb.class_lists_edit'),
              },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                };
              },
            },
          ],
        },
      ],
    },
    {
      path: '/cashier/:view?/:raspberry_id?',
      name: 'Cashier',
      component: Cashier,
      meta: {
        label: Vue.prototype.$dt('breadcrumb.cashier'),
      },
    },
    {
      path: '/system',
      name: 'System',
      component: Full,
      meta: {
        label: Vue.prototype.$dt('breadcrumb.system'),
      },
      children: [
        {
          path: '/users',
          name: 'Users',
          component: Users,
          meta: {
            hasAny: ['super-admin'],
            label: Vue.prototype.$dt('breadcrumb.users'),
          },
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles,
          meta: {
            hasAny: ['super-admin'],
            label: Vue.prototype.$dt('breadcrumb.roles'),
          },
        },
        {
          path: '/profile',
          name: 'UserProfile',
          component: UserProfile,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.user_profile'),
          },
        },
        {
          path: '/model_checkpoint',
          name: 'ModelCheckpoint',
          component: ModelCheckpoint,
          meta: {
            label: Vue.prototype.$dt('breadcrumb.model_checkpoint'),
          },
        },
        {
          path: 'translations',
          name: null,
          meta: { label: Vue.prototype.$dt('route.translations_list_title') },
          redirect: { name: 'Translations', params: { page: 1 } },

          component: {
            render(c) { return c('router-view'); },
          },

          children: [
            {
              path: 'list/:page',
              name: 'Translations',
              meta: { label: Vue.prototype.$dt('route.translations_list') },
              props: (route) => {
                return {
                  page: Number(route.params.page),
                };
              },
              component: Translations,
            },
            {
              path: 'create',
              name: 'TranslationCreate',
              meta: { label: Vue.prototype.$dt('route.translation_create_title') },
              component: TranslationForm,
            },
            {
              path: 'update/:id',
              name: 'TranslationUpdate',
              meta: { label: Vue.prototype.$dt('route.translation_update_title') },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                };
              },
              component: TranslationForm,
            },
          ],
        },
        {
          path: 'themes',
          name: 'Themes',
          component: Themes,

          meta: {
            label: Vue.prototype.$dt('breadcrumb.themes'),
          },
        },
        {
          path: 'languages',
          name: 'LanguagesDefault',
          meta: { label: Vue.prototype.$dt('route.languages_list_title') },
          redirect: { name: 'Languages', params: { page: 1 } },

          component: {
            render(c) { return c('router-view'); },
          },

          children: [
            {
              path: 'list/:page',
              name: 'Languages',
              meta: { label: Vue.prototype.$dt('route.languages_list') },
              props: (route) => {
                return {
                  page: Number(route.params.page),
                };
              },
              component: Languages,
            },
            {
              path: 'create',
              name: 'LanguageCreate',
              meta: { label: Vue.prototype.$dt('route.language_create_title') },
              component: LanguageForm,
            },
            {
              path: 'update/:id',
              name: 'LanguageUpdate',
              meta: { label: Vue.prototype.$dt('route.language_update_title') },
              props: (route) => {
                return {
                  id: Number(route.params.id),
                };
              },
              component: LanguageForm,
            },
          ],
        },
      ],
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      meta: { public: true },
      component: { render(c) { return c('router-view'); } },
      children: [
        {
          path: '404',
          name: 'Page404',
          meta: { public: true },
          component: Page404,
        },
        {
          path: '403',
          name: 'Page403',
          meta: { public: true },
          component: Page403,
        },
        {
          path: '500',
          name: 'Page500',
          meta: { public: true },
          component: Page500,
        },
        {
          path: 'login',
          name: 'Login',
          meta: { public: true },
          component: Login,
        },
        {
          path: 'register',
          name: 'Register',
          meta: { public: true },
          component: Register,
        },
      ],
    },
    {
      meta: { public: true },
      path: '*',
      name: '404',
      component: Page404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // This only catches some redundant navigation cases..?
  // If someone finds a better way to deal with this in
  // combination with query parameters being updated by 
  // v-models, please implement it.
  let oldRoute = router.resolve(from);
  let newRoute = router.resolve(to);
  if (newRoute.href !== oldRoute.href) {
    next();
  }
});
router.beforeEach(authCheck);

export default router;
