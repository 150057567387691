<template>
  <div class="animated fadeIn overlay-container">
    <b-row v-if="trayId">
      <b-col sm="8">
        <div class="card">
          <div class="card-header">
            <b-spinner v-if="isLoading" small type="grow" style="margin-top: 0;" />
            {{ lastTray?.created_at | dateUtcToLocal }} {{ lastModifiedUser }}
          </div>
          <div class="card-block">
            <grab-data-draw ref="grabDataDraw" :model="lastTray" :img-src="preview.imageSrc" :removeable="removeable"
              @doneDraw="onDoneDraw" @updatePts="updatePts" @cancelDraw="drawing = false" />
          </div>
          <div class="card-footer">
            <div>
              <b-button-group v-if="drawing && lastTray && lastTray.id">
                <b-button variant="success" @click="drawDoneClick">
                  {{ $t('global.done') }}
                </b-button>
                <b-button variant="warning" @click="drawCancelClick">
                  {{ $t('global.cancel') }}
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="4">
        <grab-data-form ref="form" :model="lastTray" :drawing="drawing" :tray-sent="traySent" :parent-loading="isLoading"
          @saved="formSaved" @removeSi="removeSi" @draw="draw" @next="next" @itemSelected="highlightItem"
          @selectionLost="removeHighlight" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TraysService from '@/services/trays-service.js';
import SegmentedImagesService from '@/services/segmented-images-service.js';
import GrabDataForm from '@/views/GrabData/GrabDataForm';
import GrabDataDraw from '@/views/GrabData/GrabDataDraw';

export default {
  name: 'TraysPlotter',
  components: { GrabDataForm, GrabDataDraw },
  data() {
    return {
      drawing: false,
      CDSelectPromise: 0,
      traySent: null,
      removeable: false,
      loading: {
        get: false,
        getImg: false,
        plot: false,
        delete: false,
      },
      preview: { imageSrc: '/images/placeholder.png' },
      imageSrc: '/images/placeholder.png',
      actions: {
        enabled: true,
        select: true,
      },
      lastTray: null,
    };
  },
  computed: {
    ...mapState(['auth']),
    isLoading() {
      let isLoading = false;
      _.forEach(this.loading, (item) => {
        if (item) {
          isLoading = true;
          return false;
        }
      });
      return isLoading;
    },
    trayId() {
      return !_.isUndefined(this.$route.params.id) ? this.$route.params.id : false;
    },
    lastModifiedUser() {
      if (this.lastTray?.last_modified_user) {
        return `(${this.$dt('global.last_modified_by', 'Last modified')}: ${this.lastTray.last_modified_user.name})`;
      }
      return '';
    },
  },
  watch: {
    lastTray: function () {
      if (this.lastTray && this.lastTray.segmented_images) {
        _.forEach(this.lastTray.segmented_images, (segimg, key) => {
          this.lastTray.segmented_images[key].color = this.getRGB(key + 1);
        });
      }
    },
  },
  mounted() {
    this.getTray(this.trayId).then(() => {
      if (this.lastTray?.annotation_status !== 'is_annotated') {
        this.setAnnotationStatus('being_annotated');
      }
    });
  },
  methods: {
    setAnnotationStatus(status) {
      if (this.lastTray) {
        this.lastTray.annotation_status = status;
        this.$refs.form?.saveAnnotationStatus();
      }
    },
    getTray(trayId, getImg = true) {
      this.loading.get = true;
      this.removeable = false;
      if (getImg) {
        this.loading.getImg = true;
        TraysService.getImg(trayId).then((response) => {
          this.loading.getImg = false;
          this.$set(this.preview, 'imageSrc', `data:image/png;base64,${response.data.image}`);
        }).catch(() => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: this.$t('global.connection_lost_service', { service: 'TraysService.getImg' }),
          });
          this.loading.getImg = false;
        });
      }
      return TraysService.get(trayId).then((response) => {
        this.loading.get = false;
        this.raspberry_id = response.raspberry_id;
        if (typeof response.data === 'object') {
          this.lastTray = Object.assign({}, response.data);
        }
      }).catch(() => {
        this.loading.get = false;
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: this.$t('global.connection_lost_service', { service: 'TraysService.get' }),
        });
      });
    },
    formSaved() {
      this.removeable = false;
      this.$refs.grabDataDraw.updateAllPoints();
    },
    draw(item) {
      this.removeable = false;
      this.drawing = true;
      this.$refs.grabDataDraw.startDraw(item);
    },
    drawDoneClick() {
      this.removeable = false;
      this.$refs.grabDataDraw.doneDraw();
    },
    drawCancelClick() {
      this.removeable = false;
      this.$refs.grabDataDraw.cancelDraw();
    },
    onDoneDraw(data) {
      this.removeable = false;
      this.drawing = false;
      data.CDID = this.raspberry_id;
      data.trayId = this.lastTray.id;
      this.loading.plot = true;
      SegmentedImagesService.plot(data).then(() => {
        this.loading.plot = false;
        this.getTray(this.lastTray.id, false);
      }).catch(() => {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.plot' }),
        });
        this.loading.plot = false;
      });
    },
    updatePts(points) {
      this.removeable = false;
      const data = {
        CDID: this.raspberry_id,
        trayId: this.lastTray.id,
        points: points,
      };
      this.loading.plot = true;
      SegmentedImagesService.updatePoints(data).then(() => {
        this.loading.plot = false;
        this.getTray(this.lastTray.id, false);
      }).catch(() => {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.updatePoints' }),
        });
        this.loading.plot = false;
      });
    },
    removeSi(si) {
      if (confirm(this.$t('grab_data.segmented_image_delete_confirm_text'))) {
        this.removeable = false;
        this.loading.delete = true;
        SegmentedImagesService.delete(si.id).then(() => {
          this.loading.delete = false;
          this.getTray(si.tray_image_id, false);
        }).catch(() => {
          this.$notify({
            type: 'error',
            title: this.$t('global.error'),
            text: this.$t('global.connection_lost_service', { service: 'SegmentedImagesService.delete' }),
          });
        });
      }
    },
    getRGB(C) {
      C *= 2;
      C /= 5;
      const C1 = 255 * C;
      const C2 = 255 - C1;
      const C3 = 255 + C2 / 2;
      const R = Math.abs(Math.round(C1 % 256));
      const G = Math.abs(Math.round(C2 % 256));
      const B = Math.abs(Math.round(C3 % 256));
      return `rgb(${R},${G},${B})`;
    },
    highlightItem(item_id) {
      this.$refs.grabDataDraw.highlightItem(item_id);
    },
    removeHighlight() {
      this.$refs.grabDataDraw.removeHighlight();
    },
    next(annotation_status) {
      TraysService.getNext(this.lastTray.id, { workflow: this.lastTray.workflow, annotation_status: annotation_status }).then((response) => {
        if (response.data) {
          this.$set(this.preview, 'imageSrc', this.imageSrc);
          this.$router.push({ name: 'TraysPlotter', params: { id: response.data.id }, query: { nextStatus: annotation_status } });
          this.getTray(this.trayId);
        } else {
          this.$notify({
            type: 'warn',
            title: this.$t('global.warning'),
            text: this.$t('grab_data.next_tray_not_found'),
          });
        }
      }).catch(() => {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: this.$t('global.connection_lost_service', { service: 'TraysService.getNext' }),
        });
      });
    },
  },
};
</script>

<style lang="scss" scope>
.overlay-container .spinner-grow {
  margin-top: 20%;
}

.overlay-container {
  height: 100%;
  position: relative;
  width: 100%;
}

.overlay {
  background-color: rgba(17, 17, 17, 0.2);
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 11;
}
</style>
