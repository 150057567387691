import axios from 'axios';

import { VuexTypes } from '../store/types';
import store from '../store';
import Vue from 'vue';

import Constants from '@/constants';

const Api = axios.create({
  'baseURL': Constants.API_URL,
  'Accept': 'application/json',
  'Content-Type': 'application/json',
});

Api.interceptors.response.use(undefined, function (error) {
  return new Promise(function (_resolve, _reject) {
    // Don't do anything if the request was cancelled
    if (axios.isCancel(error)) {
      return;
    }

    if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
      // if we ever get an unauthorized, logout the user
      store.dispatch(VuexTypes.AUTH_LOGOUT);
    }
    throw error;
  });
});

Api.interceptors.response.use(undefined, function (error) {
  return new Promise(function (_resolve, _reject) {
    // Don't do anything if the request was cancelled
    if (axios.isCancel(error)) {
      return;
    }

    if (!error?.config?.no_error_notification) {
      const maxErrorLength = 200;

      let errorText = JSON.stringify(error?.response?.data);
      if (errorText.length > maxErrorLength) {
        errorText = errorText.slice(0, maxErrorLength) + '...';
      }
      Vue.notify({
        title: 'Network error!',
        type: 'error',
        group: 'system-errors',
        text: `HTTP ERROR ${error?.response?.status}: <br>${errorText}`,
        duration: -1,
      });
      throw error;
    }
  });
});

export default () => {
  return Api;
};
