<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('roles.header') }}
            <b-btn
              v-if="users.roles.actions?.roleCreate"
              class="pull-right"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> {{ $t('global.add_new') }}
            </b-btn>
          </div>
          <div class="card-block">
            <table class="table table-striped table-condensed">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t('global.name') }}</th>
                  <th v-if="hasAnyActions">
                    {{ $t('global.actions') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="role in users.roles.data"
                  :key="role.name"
                >
                  <td>{{ role.id }}</td>
                  <td>{{ role.name }}</td>
                  <td v-if="hasAnyActions">
                    <button
                      v-if="users.roles.actions?.roleUpdate"
                      type="button"
                      class="btn btn-success table-button-size"
                      @click.stop="editRole(role.id)"
                    >
                      <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                    </button>
                    <button
                      v-if="users.roles.actions?.roleDelete"
                      type="button"
                      class="btn btn-danger table-button-size"
                      @click.stop="deleteRole(role.id)"
                    >
                      <i class="fa fa-trash" /> {{ $t('global.delete') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <role-form
          :model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import RoleForm from './RoleForm';
import { VuexTypes } from '@/store/types';

export default {
  name      : 'Roles',
  components: { RoleForm },
  data      : function () {
    return {
      newRoleName       : '',
      checkedPermissions: {},
      addItem           : {},
      showAddForm       : false,
    };
  },
  computed: {
    ...mapState(['users']),
    ...mapGetters(['can']),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
    hasAnyActions() {
      return this.users.roles.actions.roleCreate || this.users.roles.actions.roleUpdate || this.users.roles.actions.roleCreate;
    },
  },
  mounted () {
    this.list();
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.ROLES_LIST,
      delete: VuexTypes.ROLES_DELETE,
    }),
    editRole (roleId) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, this.users.roles.data.find((x) => x.id === roleId));
    },
    deleteRole (id) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(id);
        this.addItem = {};
      }
    },
    formSaved () {
      this.showAddForm = false;
      this.addItem = {};
    },
    openAddForm () {
      this.showAddForm = true;
      this.addItem = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.table-button-size {
  font-size: 11px;
  min-width: 50px;
}
</style>
