import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/model_checkpoint?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/model_checkpoint/${id}`);
  },
  store (data) {
    return Api().post('/model_checkpoint', data);
  },
  update (data) {
    return Api().put(`/model_checkpoint/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/model_checkpoint/${id}`);
  },
};
