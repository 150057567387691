import Api from '@/services/api';

export default {
  list(currentPage) {
    return Api().get(`/model_training?page=${currentPage}`);
  },
  get(id) {
    return Api().get(`/model_training/${id}`);
  },
  store(data) {
    return Api().post('/model_training', data);
  },
  update(data) {
    return Api().put(`/model_training/${data.id}`, data);
  },
  delete(id) {
    return Api().delete(`/model_training/${id}`);
  },
  train(id) {
    return Api().post(`/model_training/train/${id}`);
  },
  cancel(id) {
    return Api().post(`/model_training/cancel/${id}`);
  },
  running() {
    return Api().get('/model_training/running');
  },
};
