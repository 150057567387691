import Api from '@/services/api';

export default {
  list(currentPage, extraParams = {}) {
    const params = Object.assign({ page: currentPage }, extraParams);
    return Api().get('/segmented_images', {
      params: params,
    });
  },
  get(id) {
    return Api().get(`/segmented_images/${id}`);
  },
  getImg(id) {
    return Api().get(`/segmented_images/img/${id}`);
  },
  store(data) {
    return Api().post('/segmented_images', data);
  },
  assignTypes(data) {
    return Api().post('/segmented_images/assign_product_types', data);
  },
  plot(data) {
    return Api().post('/segmented_images/plot', data);
  },
  updatePoints(data) {
    return Api().post('/segmented_images/update_points', data);
  },
  update(data) {
    return Api().put(`/segmented_images/${data.id}`, data);
  },
  updateMulti(data) {
    return Api().put('/segmented_images/update_multi', data);
  },
  delete(id) {
    return Api().delete(`/segmented_images/${id}`);
  },
  deleteMulti(data) {
    return Api().delete('/segmented_images', {
      data: data,
    });
  },
  updateTrainAccepted(data) {
    return Api().put(`/segmented_images/train_accepted/${data.id}`, data);
  },
  reloadImage(id) {
    return Api().post('/segmented_images/reload_image', { id });
  },
  reloadImageMulti(ids) {
    return Api().post('/segmented_images/reload_image_multi', { ids });
  },
};
