<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> {{ $t('class_groups.header') }}
            <b-button size="sm" variant="success" class="float-right mr-1 d-print-none" @click="createItem()">
              <i class="fa icon-pencil" /> {{ $t('class_groups.create_class_list') }}
            </b-button>
          </div>
          <div class="card-block">
            <b-alert v-if="!classGroups.list.total" show variant="secondary" class="text-center mb-0">
              {{ $t('global.no_data') }}
            </b-alert>
            <b-table v-else striped responsive :items="classGroups.list.data" :fields="tableFields" class="table-custom">
              <template #cell(class_group_items)="row">
                <b-badge v-for="classGroupItem in row.value" :key="`class-group-item-${classGroupItem.id}`" variant="dark"
                  style="margin-right: 1px;">
                  {{ classGroupItem.name }}
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <router-link v-if="can('super-admin', 'cvision-admin')" :to="{ name: 'ClassListsEdit', params: { id: row.item.id } }"
                  class="btn btn-success btn-sm table-button-size">
                  <i class="fa icon-pencil" /> {{ $t('global.edit') }}
                </router-link>
                <button v-if="can('super-admin', 'cvision-admin')" type="button"
                  class="btn btn-primary btn-sm table-button-size" @click="cloneItem(row.item)">
                  <i class="fa fa-clone" /> {{ $t('global.clone') }}
                </button>
                <button v-if="can('super-admin', 'cvision-admin')" type="button"
                  class="btn btn-warning btn-sm table-button-size" @click="deleteItem(row.item)">
                  <i class="fa fa-trash" /> {{ $t('global.delete') }}
                </button>
              </template>
            </b-table>
            <b-pagination v-if="classGroups.list.total > 0" v-model="currentPage" :total-rows="classGroups.list.total"
              :per-page="classGroups.list.per_page" class="mt-4" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';

export default {
  name: 'ClassGroups',
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapState(['classGroups']),
    ...mapGetters(['can']),

    tableFields() {
      return [
        { key: 'id', label: '#' },
        { key: 'name', label: this.$t('global.name') },
        { key: 'description', label: this.$t('global.description') },
        { key: 'class_group_items', label: this.$t('class_groups.classes') },
        { key: 'actions', label: this.$t('global.actions'), thClass: 'fit', tdClass: 'fit' },
      ];
    },
  },
  watch: {
    roles: 'onRolesLoaded',
    currentPage: function () {
      this.list({ currentPage: this.currentPage });
    },
  },
  mounted() {
    this.list({ currentPage: this.currentPage });
  },
  methods: {
    ...mapActions({
      list: VuexTypes.CLASS_GROUPS_LIST,
      clone: VuexTypes.CLASS_GROUPS_CLONE,
      delete: VuexTypes.CLASS_GROUPS_DELETE,
    }),
    createItem() {
      this.$router.push({ name: 'ClassListsCreate' });
    },
    cloneItem(item) {
      if (confirm(this.$t('global.clone_confirm_text'))) {
        this.clone(item.id);
      }
    },
    deleteItem(item) {
      if (confirm(this.$t('global.delete_confirm_text'))) {
        this.delete(item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-custom ::v-deep .table {

  td,
  th {
    &.fit {
      white-space: nowrap;
      width: 1%;
    }
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}
</style>
