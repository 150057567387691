<template>
  <header class="cashier-header app-header navbar">
    <b-navbar-nav>
      <b-nav-item-dropdown
        v-if="!raspberryId"
        right
        no-caret
      >
        <template slot="button-content">
          <span class="profile-menu-btn">
            <i class="fa fa-bars" />
          </span>
        </template>
        <b-dropdown-text class="navbar-dropdown-title mt-2">
          {{ $t('global.restaurant_title') }}
        </b-dropdown-text>
        <b-dropdown-text class="navbar-dropdown-subtitle">
          {{ $t('global.restaurant_address') }}
        </b-dropdown-text>
        <b-dropdown-divider class="mx-3" />
        <b-dropdown-item
          key="logout"
          @click="logout()"
        >
          <img
            class="navbar-dropdown-item-img mb-2"
            src="@/static/img/exit.svg"
          >
          <span class="ml-2 navbar-dropdown-item-text">
            {{ $t('global.logout') }}
          </span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <span
        v-else
        class="back-arrow"
        @click="toGridView"
      >
        <img src="@/static/img/arrow-left.svg">
      </span>
      <language-switcher />
    </b-navbar-nav>
    <div class="tittle-container">
      <h1
        v-if="raspberryId"
        class="station-tittle-text"
      >
        {{ $t('global.cashDesk') + ' ' + raspberriesById(raspberryId)?.name }}
      </h1>
      <img
        v-if="!raspberryId"
        class="lido-logo-img"
        src="@/assets/logo-lido.png"
      >
    </div>
    <div>&nbsp;</div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import LanguageSwitcher from '@/components/Header/LanguageSwitcher';

export default {
  name      : 'CashierHeader',
  components: {
    LanguageSwitcher,
  },
  data () {
    return {
      raspberryId: null,
      raspberry: null,
      activeRaspberryName: null,
    };
  },
  computed: {
    ...mapGetters('cashierRaspberries', {
      raspberriesById: 'byId',
    }),
    // raspberry() {
    //   return this.raspberriesById(this.raspberryId);
    // },
  },
  watch: {
    '$route.params.raspberry_id': {
      handler: function(raspberryId) {
        this.raspberryId = parseInt(raspberryId);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch(VuexTypes.AUTH_LOGOUT).then(() => {
        this.$router.push('/pages/login').catch( ()=>{});
      });
    },
    toGridView() {
      this.$router.push('Cashier');
    },
  },
};
</script>

<style lang="scss">
  .cashier-header {
    .profile-menu-btn {
      color: white;
    }
    
    .dropdown-menu {
      width: 320px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0 0 4px 0px #787878;

      font-family: 'Avenir';

      .navbar-dropdown {
        &-title {
          font-weight: 800;
          font-size: 20px;
          color: black;
          text-transform: uppercase;
        }

        &-subtitle {
          font-weight: 400;
          font-size: 14px;
          color: #777777;
        }

        &-item-img {
          height: 22px;
        }
        
        &-item-text {
          font-weight: 800;
          font-size: 18px;
          color: #003C7E;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
.cashier-header {
    height: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('@/static/img/background-dark-wood.jpg');
    background-size: cover;
    background-position: center;
    &.navbar {
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      position: relative !important;

      .back-arrow {
        padding: 0.75rem;
        cursor: pointer;
      }
    }
  }


  .tittle-container{
    margin:auto;
    padding-top: 0.70rem;
  }
  .lido-logo-img{
    width: 5.5rem;
    height: 3rem;
  }
  .station-tittle-text{
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }

  @media (min-width: 767px) {
  /* Medium size tablet */
  }

  @media (min-width:1281px) {
    /* Desktop */
  }
</style>
