<template>
  <multiselect 
    v-if="multiple"
    v-model="cash_desks" 
    :options="cashDesk.list.data"
    label="description"
    :multiple="true"
    track-by="id"
    @input="returnValue"
  />
  <multiselect 
    v-else
    v-model="cash_desk" 
    :options="cashDesk.list.data"
    label="description"
    :multiple="false"
    track-by="id"
    @input="returnValue"
  />  
</template>

<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CashDeskSelect',

  props: {
    multiple: {
      type   : Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
  },
  data () {
    return {
      cash_desks: [],
      cash_desk: null,
    };
  },
  computed: {
    ...mapState(['cashDesk']),
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.cash_desks = [];
          this.cash_desk = null;
          return;
        }

        this.cash_desks = this.value;
        this.cash_desk = this.value[0] ? this.value[0] : null;
      },
    },
    multiple(newVal, oldVal) {
      if (!this.value) {
        this.returnValue(this.multiple ? [] : null);
      } else if (newVal === false && oldVal === true && this.value.length > 1) {
        this.returnValue(this.value[0]);
      }
    },
  },
  mounted () {
    this.loadCashDesks();
  },
  methods: {
    ...mapActions({
      loadCashDesks: VuexTypes.CASH_DESK_LIST,
    }),
    returnValue(value) {
      this.multiple ? this.$emit('input', value) : this.$emit('input', value ? [value] : []);
    },
  },
};
</script>
<style>
</style>
