<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col
          md="6"
          sm="8"
        >
          <b-card
            no-body
            class="mx-4"
          >
            <b-card-body class="p-4">
              <b-form @submit.prevent="register">
                <h1>Register</h1>
                <p class="text-muted">
                  Create your account
                </p>
                <b-form-group
                  :state="!errors.name"
                  :invalid-feedback="errors.name"
                >
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-user" /></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="model.name"
                      type="text"
                      placeholder="Username"
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :state="!errors.email"
                  :invalid-feedback="errors.email"
                >
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>@</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="model.email"
                      type="text"
                      placeholder="Email"
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :state="!errors.password"
                  :invalid-feedback="errors.password"
                >
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock" /></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="model.password"
                      type="password"
                      placeholder="Password"
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :state="!errors.password_confirmation"
                  :invalid-feedback="errors.password_confirmation"
                >
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="icon-lock" /></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="model.password_confirmation"
                      type="password"
                      placeholder="Repeat password"
                    />
                  </b-input-group>
                </b-form-group>

                <b-button
                  variant="success"
                  block
                  type="submit"
                >
                  Create Account
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { VuexTypes } from '../../store/types';
export default {
  name: 'Register',
  data () {
    return {
      dismissCountDown: 0,
      model           : {},
      errors          : {},
    };
  },
  created () {
    // alert()
  },
  methods: {
    // submit () {
    //   this.$v.$touch()
    // },
    register: function () {
      // const { name, email, password } = this.form
      this.$store.dispatch(VuexTypes.REGISTER_USER, this.model)
        .then(() => {
          this.$router.push('/pages/login').catch(()=>{});
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }
          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
  },
};
</script>
