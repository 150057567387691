<template>
  <div>
    <div
      class="label"
      :style="{ 'left': xCoord + 'px',
                'top': yCoord + 'px', 
                'width': labelw + 'px',
                'height': labelh + 'px',
                'background-color': backgroundColor, }"
    >
      <div class="label-inner">
        <p class="label-text">
          {{ `${labelText}` }}
        </p>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'TrayImageLabels', 
  components: {
  },
  props: {
    'xCoord': {
      type: Number,
      required: true,
    },
    'yCoord': {
      type: Number,
      required: true,
    },
    'labelw': {
      type: Number,
      default: 224,
    },
    'labelh': {
      type: Number,
      default: 76,
    },
    'backgroundColor': {
      type: String,
      default: '#759F01',
    },
    'labelText': {
      type: String,
      required: true,
    },
  },
  data () {
    return {
    };
  },
  computed: {
  },
  mounted () {

  },
  methods: {
  },
};
</script>
  
  <style lang="scss" scoped>

    .label{
      position: absolute;
      padding: 10px;
    }
    .label-inner{
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
    .label-text{
      color: #FFFFFF;
      height: 100%;
      width: 100%;
      font-size: 14px;
      font-weight: 800;
      line-height: 19px;
    }
  </style>
  