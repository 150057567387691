import { VuexTypes } from '../types';
import TranslationsService from '@/services/translations-service';
import statuses from '@/store/statuses';

const state = {
  translationsList: {
    data: [],
    status: statuses.EMPTY,
  },
  translationCreateData: {
    data: [],
    status: statuses.EMPTY,
  },
  // translationUpdateData: {
  //   data: [],
  //   status: statuses.EMPTY,
  // },
  translationCreate: {
    data: [],
    status: statuses.EMPTY,
  },
  translationUpdate: {
    data: [],
    status: statuses.EMPTY,
  },
  translationDelete: {
    data: [],
    status: statuses.EMPTY,
  },
};

const getters = {
  // translationsList:         (state) => state.translationsList,
  translationCreateData:    (state) => state.translationCreateData,
  // translationUpdateData:    (state) => state.translationUpdateData,
  translationCreate:        (state) => state.translationCreate,
  translationUpdate:        (state) => state.translationUpdate,
};

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  [VuexTypes.TRANSLATIONS_LIST]: (state, { data, status }) => {
    state.translationsList = { data, status };
  },
};

const actions = {
  [VuexTypes.TRANSLATIONS_LIST]: ({ commit, state }, params) => {
    state.translationsList['status'] = statuses.LOADING;
    TranslationsService.list(params).then((data) => {
      data.status = statuses.LOADED;
      commit(VuexTypes.TRANSLATIONS_LIST, data);
    }).catch((errResponse) => {
      commit(VuexTypes.TRANSLATIONS_LIST, errResponse);
    });
  },
  [VuexTypes.UNTRANSLATED]: () => {
    return new Promise((resolve, reject) => {
      TranslationsService.untranslated().then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.TRANSLATION_CREATE_DATA]: () => {
    return new Promise((resolve, reject) => {
      TranslationsService.createData().then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.TRANSLATION_UPDATE_DATA]: ({}, id) => {
    return new Promise((resolve, reject) => {
      TranslationsService.editData(id).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.TRANSLATION_CREATE]: ({}, createData) => {
    return new Promise((resolve, reject) => {
      TranslationsService.create(createData).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.TRANSLATION_UPDATE]: ({}, {data, config}) => {
    return new Promise((resolve, reject) => {
      TranslationsService.update(data, config).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
  [VuexTypes.TRANSLATION_DELETE]: ({}, id) => {
    return new Promise((resolve, reject) => {
      TranslationsService.delete(id).then((data) => {
        resolve(data);
      }).catch((data) => {
        reject(data);
      });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
