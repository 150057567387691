<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-card-text>
            <b-row>
              <b-col class="d-flex justify-content-start">
                <b-badge
                  variant="warning"
                  class="p-2 rounded-circle"
                >
                  <i
                    class="far fa-object-group"
                    style="font-size: 60px;"
                  />
                </b-badge>
                <div class="ml-2">
                  {{ $t('dashboards.trays_last_30_days') }}<br>
                  <h1 style="font-size: 46px; margin-bottom: 0;">
                    {{ stats.trays }}
                  </h1>
                </div>
              </b-col>
              <b-col class="d-flex justify-content-start">
                <b-badge
                  variant="success"
                  class="p-2 rounded-circle"
                >
                  <i
                    class="fas fa-shopping-cart text-body"
                    style="font-size: 54px;"
                  />
                </b-badge>
                <div class="ml-2">
                  {{ $t('dashboards.orders_last_30_days') }}<br>
                  <h1 style="font-size: 46px; margin-bottom: 0;">
                    {{ stats.orders }}
                  </h1>
                </div>
              </b-col>
              <b-col class="d-flex justify-content-start">
                <b-badge
                  variant="info"
                  class="p-2 rounded-circle"
                >
                  <i
                    class="fa fa-credit-card"
                    style="font-size: 60px;"
                  />
                </b-badge>
                <div class="ml-2">
                  {{ $t('dashboards.total_smart_checkouts') }}<br>
                  <h1 style="font-size: 46px; margin-bottom: 0;">
                    {{ stats.smart_checkouts }}
                  </h1>
                </div>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DashboardService from '@/services/dashboard-service';

export default {
  name: 'Dashboard',
  data () {
    return {
      stats: {
        trays: 0,
        orders: 0,
        cash_desks: 0,
        product_types: 0,
      },
    };
  },
  computed: { ...mapState(['auth']) },
  mounted () {
    DashboardService.stats().then((response) => {
      this.stats = response.data;
    }).catch((error) => {
      console.error(error);
    });
  },
};
</script>

<style lang="scss" scope>
</style>
