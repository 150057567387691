import Connection from './connection.js';
import store from '../store';

export default function (raspberry) {
  const connection = {

    connection: {},
    eventHandlers: {},

    init(raspberry) {
      this.raspberryId = raspberry.id;
      this.connection = {};

      this.initConnection();
    },

    async initConnection() {
      this.connection = await Connection.getConnection({
        auth: {
          token: store.state.auth.token,
          CDID: this.raspberryId,
        },
        channel: '/cashier',
      });

      this.setEventHandlers();
    },

    setEventHandlers() {
      this.connection.onAny((eventName, ...args) => {
        console.log('Incoming event', eventName, ...args);
        if (!this.eventHandlers[eventName]) {
          console.error(`No handler for event [${eventName}]`, this.raspberryId);
          return;
        }

        this.eventHandlers[eventName].forEach(eventFunction => {
          eventFunction(...args);
        });
      });
    },

    on(event, callbackFunction) {
      /* Allow event stacking, so use array */
      if (!this.eventHandlers[event]) {
        this.eventHandlers[event] = [];
      }

      this.eventHandlers[event].push(callbackFunction);
    },

    emit(eventName, ...args) {
      if (!this.connection) {
        return console.error(`Connection for rasbbery id ${this.raspberryId} does not exist`);
      }

      console.log('CashierConnectionHandler::emit', eventName, ...args);
      this.connection.emit(eventName, ...args);
    },
  };

  connection.init(raspberry);

  return connection;
}