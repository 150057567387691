<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col
        :sm="showForm ? '6' : '12'"
        :md="showForm ? '8' : '12'"
        :lg="showForm ? '9' : '12'"
      >
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify" /> All orders
            <b-btn
              class="pull-right"
              size="sm"
              variant="success"
              @click.prevent="openAddForm()"
            >
              <i class="fas fa-plus" /> Add new
            </b-btn>
          </div>
          <div class="card-block">
            <table class="table table-striped table-condensed">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Cash Desk ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in orders.list.data"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.order_type }}</td>
                  <td>{{ item.raspberry_id }}</td>
                  <td>
                    <button
                      v-if="can('super-admin', 'cvision-admin')"
                      type="button"
                      class="btn btn-success btn-sm table-button-size"
                      @click="editItem(item)"
                    >
                      <i class="fa icon-pencil" /> Edit
                    </button>
                    <button
                      v-if="can('super-admin', 'cvision-admin')"
                      type="button"
                      class="btn btn-warning btn-sm table-button-size"
                      @click="deleteItem(item)"
                    >
                      <i class="fa fa-trash" /> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <b-pagination
              v-model="currentPage"
              :total-rows="orders.list.total"
              :per-page="orders.list.per_page"
              class="mt-4"
            />
          </div>
        </div>
      </b-col>
      <b-col
        v-if="showForm"
        sm="6"
        md="4"
        lg="3"
      >
        <order-form
          :model="addItem"
          :show-cancel="true"
          @saved="formSaved"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import OrderForm from './OrderForm';

export default {
  name      : 'Orders',
  components: { OrderForm },
  data () {
    return {
      currentPage: 1,
      addItem    : {},
      showAddForm: false,
    };
  },
  computed: {
    ...mapState(['orders']),
    ...mapGetters(['can']),
    showForm () {
      return this.addItem.id || this.showAddForm;
    },
  },
  watch: {
    roles      : 'onRolesLoaded',
    currentPage: function () {
      this.list(this.currentPage);
    },
  },
  mounted () {
    this.list(this.currentPage);
  },
  methods: {
    ...mapActions({
      list  : VuexTypes.ORDERS_LIST,
      delete: VuexTypes.ORDERS_DELETE,
    }),
    editItem (item) {
      this.showAddForm = false;
      this.addItem = Object.assign({}, item);
    },
    deleteItem (item) {
      if (confirm('Are you sure you want to delete?')) {
        this.delete(item.id);
        this.addItem = {};
      }
    },
    formSaved () {
      this.showAddForm = false;
      this.addItem = {};
    },
    openAddForm () {
      this.showAddForm = true;
      this.addItem = {};
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
