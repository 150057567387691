<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">
              403
            </h1>
            <h4 class="pt-3">
              Oops! You're not supposed to access this.
            </h4>
            <p class="text-muted">
              The page you are looking for requres privileges you dount have.
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default { name: 'Page403' };
</script>
