<template>
  <div>
    <cash-desk-statuses-bar />
    <div class="row">
      <div
        v-for="(raspberry, idx) in rasberryList"
        :key="raspberry.id" 
        class="col-sm-6 mt-3"
      >
        <cashier-grid-desk-card
          :raspberry="raspberry"
          :cash-desk-number="idx + 1"
          @click="$emit('raspberryClick', raspberry)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CashDeskStatusesBar from './CashDeskStatusesBar.vue';
import CashierGridDeskCard from './CashierGridDeskCard.vue';

export default {
  name      : 'CashDeskGridView',
  components: {
    CashierGridDeskCard,
    CashDeskStatusesBar,
  },
  data () {
    return {
      raspberryId: null,
    };
  },
  computed: {
    ...mapState('cashierRaspberries', {
      rasberryList: 'list',
      loading: 'loading',
    }),
    ...mapState('cashierOrders', {
      ordersByRaspberry: 'byRaspberry',
    }),
  },
  watch: {},
  beforeDestroy () {},
  mounted () {},
};
</script>

<style lang="scss" scoped>

</style>
