import Api from '@/services/api';

export default {
  list (_currentPage) {
    return Api().get('/roles');
  },
  get (id) {
    return Api().get(`/roles/${id}`);
  },
  store (data) {
    return Api().post('/roles', data);
  },
  update (data) {
    return Api().put(`/roles/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/roles/${id}`);
  },
};
