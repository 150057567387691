import { defaultMutations } from 'vuex-easy-access';

import { VuexTypes } from '../types';

const state = {
  raspberry : localStorage.getItem('cashier-raspberry') || null,
  restaurant: localStorage.getItem('cashier-restaurant') || null,
  status    : '',
};

const getters = {
  // authStatus     : (state) => state.status,
};
// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  ...defaultMutations(state),
  [VuexTypes.CASHIER_LOAD]: (state, status) => {
    state.status = status;
  },
  [VuexTypes.CASHIER_SET_RESTAURANT]: (state, restaurant) => {
    if (restaurant !== null) {
      localStorage.setItem('cashier-restaurant', restaurant);
    } else {
      localStorage.removeItem('cashier-restaurant');
    }
    state.restaurant = restaurant;
    state.status = 'success';
  },
  [VuexTypes.CASHIER_SET_RASPBERRY]: (state, raspberry) => {
    if (raspberry !== null) {
      localStorage.setItem('cashier-raspberry', raspberry);
    } else {
      localStorage.removeItem('cashier-raspberry');
    }
    state.raspberry = raspberry;
    state.status = 'success';
  },
};

const actions = {
  [VuexTypes.CASHIER_SET_RESTAURANT]: ({ commit, _dispatch }, restaurant) => {
    commit(VuexTypes.CASHIER_LOAD, 'loading');
    return new Promise((resolve, _reject) => {
      commit(VuexTypes.CASHIER_SET_RESTAURANT, restaurant);
      resolve(restaurant);
    });
  },
  [VuexTypes.CASHIER_SET_RASPBERRY]: ({ commit, _dispatch }, raspberry) => {
    commit(VuexTypes.CASHIER_LOAD, 'loading');
    return new Promise((resolve, _reject) => {
      commit(VuexTypes.CASHIER_SET_RASPBERRY, raspberry);
      resolve(raspberry);
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
