import { defaultMutations } from 'vuex-easy-access';
import { VuexTypes } from '../types';
import ProductTypesService from '@/services/product-types-service';

const state = {
  list: {
    data: [],
    current_page: 1,
    total: 1,
    extraParams: [],
  },
  all: [],
  item: {},
  status: 'success',
};

const getters = {
  productTypes: ({ all }) => all,
};

// add generate mutation vuex easy access
// https://mesqueeb.github.io/vuex-easy-access/setup.html#setup
const mutations = {
  ...defaultMutations(state),
  [VuexTypes.PRODUCT_TYPES_LOAD]: (state, status) => {
    state.status = status;
  },
  [VuexTypes.PRODUCT_TYPES_LIST]: (state, list) => {
    state.list = list;
    state.status = 'success';
  },
  [VuexTypes.PRODUCT_TYPES_ALL]: (state, all) => {
    state.all = all;
    state.status = 'success';
  },
};

const actions = {
  [VuexTypes.PRODUCT_TYPES_LIST]: ({ commit, _dispatch }, { currentPage, extraParams }) => {
    commit(VuexTypes.PRODUCT_TYPES_LOAD, 'loading');
    return new Promise((resolve, reject) => {
      ProductTypesService.list(currentPage, extraParams)
        .then((resp) => {
          commit(VuexTypes.PRODUCT_TYPES_LIST, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(VuexTypes.PRODUCT_TYPES_LOAD, 'error');
          reject(error);
        });
    });
  },
  [VuexTypes.PRODUCT_TYPES_ALL]: ({ commit, _dispatch }) => {
    commit(VuexTypes.PRODUCT_TYPES_LOAD, 'loading');
    return new Promise((resolve, reject) => {
      ProductTypesService.getAll()
        .then((resp) => {
          commit(VuexTypes.PRODUCT_TYPES_ALL, resp.data);
          resolve(resp);
        })
        .catch((error) => {
          commit(VuexTypes.PRODUCT_TYPES_LOAD, 'error');
          reject(error);
        });
    });
  },
  [VuexTypes.PRODUCT_TYPES_ADD]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.PRODUCT_TYPES_LOAD, 'loading');
      ProductTypesService.store(data)
        .then((resp) => {
          dispatch(VuexTypes.PRODUCT_TYPES_LIST, { currentPage: state.list.current_page, extraParams: state.list.extraParams }).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.PRODUCT_TYPES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.PRODUCT_TYPES_UPDATE]: ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.PRODUCT_TYPES_LOAD, 'loading');
      ProductTypesService.update(data)
        .then((resp) => {
          dispatch(VuexTypes.PRODUCT_TYPES_LIST, { currentPage: state.list.current_page, extraParams: state.list.extraParams }).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.PRODUCT_TYPES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
  [VuexTypes.PRODUCT_TYPES_DELETE]: ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
      commit(VuexTypes.PRODUCT_TYPES_LOAD, 'loading');
      ProductTypesService.delete(id)
        .then((resp) => {
          dispatch(VuexTypes.PRODUCT_TYPES_LIST, { currentPage: state.list.current_page, extraParams: state.list.extraParams }).then(() => {
            resolve(resp);
          }).catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          commit(VuexTypes.PRODUCT_TYPES_LOAD, 'error');
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
