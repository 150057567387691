import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import { Backend } from '@/_axios';
import Backend from 'axios';
import messages from '@/resources/lang.json';
import merge from 'lodash/merge';

import Constants from '@/constants';

const loadedLanguages = [];

Vue.use(VueI18n);
const defaultLang = 'en';
const lang = localStorage.Lang || defaultLang;

const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: defaultLang,
  silentTranslationWarn: true,
  messages, // set locale messages
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  Backend.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  localStorage.Lang = lang;
  return lang;
}

function loadLang(lang, setAsCurrent) {
  if (!Backend) return;
  if (setAsCurrent && loadedLanguages.includes(lang)) {
    setI18nLanguage(lang);
    return;
  }
  Backend.get(`${Constants.API_URL}/i18n/${lang}`).then((translations) => {
    loadedLanguages.push(lang);
    const map = merge(i18n.getLocaleMessage(lang), translations.data.map);

    i18n.setLocaleMessage(lang, map);

    if (setAsCurrent && loadedLanguages.includes(lang)) {
      setI18nLanguage(lang);
    }
  });
}

Vue.prototype.$dt = (key, fallback, locale, values) => {
  locale = locale || i18n.locale;

  if (!i18n.te(key)) return fallback === undefined ? key : fallback;
  return i18n.t(key, locale, values);
};

Vue.prototype.$dtc = (key, fallback, choice, values) => {
  if (!i18n.tce(key)) return fallback === undefined ? key : fallback;
  return i18n.tc(key, choice, values);
};

// Used by scanner to automatically intercept keys
const $tk = function (key) {
  return key;
};
Vue.prototype.$tk = $tk;

export { i18n, loadLang, setI18nLanguage, $tk };
