<template>
  <div
    class="cashier-body animated fadeIn "
  >
    <cashier-header />
    <div class="card cashier-main">
      <div v-if="loading">
        {{ 'Loading...' }}
      </div>
      <cash-desk-grid-view
        v-else-if="view === 'cash_desk_grid_view'"
        class="m-3"
        @raspberryClick="raspberryClick"
      />
      <cash-desk-view
        v-else-if="view === 'cash_desk'"
      />
    </div>
  </div>
</template>

<script>
import CashierHeader from './elements/CashierHeader.vue';
import { mapActions, mapState } from 'vuex';
import { VuexTypes } from '@/store/types';
import CashDeskGridView from './CashDeskGrid/CashDeskGridView.vue';
import CashDeskView from './CashDesk/CashDeskView.vue';

export default {
  name      : 'Cashier',
  components: {
    CashierHeader,
    CashDeskGridView,
    CashDeskView,
  },
  data () {
    return {
      view: 'CashDeskGrid',
      selectedRaspberryId: null,
      loading: true,
    };
  },
  computed: {
    ...mapState('cashierRaspberries', {
      rasberryList: 'list',
      raspberriesLoading: 'loading',
    }),
    ...mapState('cashierOrders', {
      ordersByRaspberrys: 'byRaspberry',
      ordersLoading: 'loading',
    }),
    ...mapState('cashierOrders', {
      traysLoading: 'loading',
    }),
  },
  watch: {
    raspberriesLoading (newVal) {
      if (!newVal) {
        this.fetchOrders();
      }
    },
    ordersLoading (newVal) {
      if (!newVal) {
        this.fetchTrays();
      }
    },
    traysLoading (newVal) {
      if (!newVal) {
        this.loading = false;
      }
    },
    '$route.params.view': {
      handler: function(view) {
        if (view) {
          this.view = view;
        } else {
          this.view = 'cash_desk_grid_view';
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
    this.loadRaspberies();
    this.loadCashDesks();
  },
  methods: {
    ...mapActions('cashierRaspberries', {
      loadRaspberies: VuexTypes.CASHIER_LOAD_RASPBERRIES,
    }),
    ...mapActions('cashierOrders', {
      fetchOrders: VuexTypes.CASHIER_FETCH_CURRENT_ORDERS,
    }),
    ...mapActions('cashierTrays', {
      fetchTrays: VuexTypes.CASHIER_TRAYS_ACTION_FETCH_TRAYS,
    }),
    ...mapActions('cashierCashDesks', {
      loadCashDesks: VuexTypes.CASHIER_LOAD_CASH_DESKS,
    }),
    raspberryClick(raspberry) {
      if (this.ordersByRaspberrys[raspberry.id]) {
        this.$router.push({ path: '/cashier/cash_desk/' + raspberry.id });
        //this.selectedRaspberryId = raspberry.id;
        //this.view = 'CashierCashDesk';
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .cashier-body {
    min-height: 100vh;
    width: 100vw;
  }
  .cashier-main{
    background-color: #F5F5F5;
  }
</style>