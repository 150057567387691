<template>
  <div class="table-responsive">
    <table class="table table-striped table-condensed table-bordered">
      <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>{{ $t('models.class_name') }}</th>
          <th>{{ $t('models.next_model_id') }}</th>
          <th>{{ $t('models.product_types') }}</th>
          <th>{{ $t('models.num_segmented_images') }}</th>
          <th>{{ $t('global.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.class_name }}</td>
          <td>
            <navigation-badge v-if="getModel(item.next_model_id)"
              :to="{ name: 'ModelsEdit', params: { id: item.next_model_id } }" variant="badge-purple">
              {{ item.next_model_id }} - {{ getModel(item.next_model_id).name }}
            </navigation-badge>
          </td>
          <td>
            <div v-for="product_type in item.product_types" :key="product_type.id">
              <navigation-badge :to="{ name: 'ProductTypesView', params: { id: product_type.id } }"
                variant="badge-blue">
                {{ product_type.product && product_type.product.code ? `${product_type.product.code} - ` : `` }}
                {{ product_type.name }}
              </navigation-badge>
            </div>
          </td>
          <td class="text-center">
            {{ item.segmented_images_train_count }} /
            {{ item.segmented_images_test_count }} /
            {{ item.segmented_images_unused_count }}
            <b>({{ item.segmented_images_count }})</b>
          </td>
          <td>
            <b-button v-if="editable" size="sm" variant="success" @click.prevent="edit(item, index)">
              {{ $t('global.edit') }}
            </b-button>
            <b-button v-if="deletable" size="sm" variant="warning" @click.prevent="detach(item, index)">
              {{ $t('global.delete') }}
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavigationBadge from '@/components/NavigationBadge.vue';

export default {
  name: 'ModelClassesTable',
  components: { NavigationBadge },
  props: {
    items: {
      type: Array,
      required: false,
      default() { return []; },
    },
    editable: {
      type: Boolean,
      required: false,
      default() { return false; },
    },
    deletable: {
      type: Boolean,
      required: false,
      default() { return false; },
    },
    modelStatus: {
      type: String,
      required: false,
      default() { return ''; },
    },
  },
  data() {
    return {
      currentPage: 1,
      addItem: {},
    };
  },
  computed: {
    ...mapState(['models']),
  },
  methods: {
    edit(item, index) {
      this.$emit('edit', item, index);
    },
    detach(item, index) {
      this.$emit('detach', item, index);
    },
    getModel(id) {
      if (this.models.list?.find) {
        return this.models.list?.find(m => m.id === id);
      }

      return null;
    },
  },
};
</script>
<style lang="scss" scope>
.action {
  margin-left: 5px;

  &:hover {
    color: #e04;
    cursor: pointer;
  }
}

.table-button-size {
  font-size: 11px;
  min-width: 50px;
}

.card-header .card-actions button.btn-action-green {
  background: solid;
  background-color: #cde4d1;
  border-color: #82a088;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;
}
</style>
