<template>
  <b-card :class="{ 'bg-light-yellow': isEditing, 'bg-light-green': !isEditing }" header-tag="header">
    <div slot="header">
      <span v-if="isEditing"><i class="fa icon-plus" /> {{ $t('global.edit') }} </span>
      <span v-else><i class="fa icon-pencil" /> {{ $t('class_groups.add_new_class_for_classes') }}</span>
      <div class="card-actions">
        <a href="#" class="btn btn-setting" @click.prevent="onCancel">
          <i class="fa icon-close" />
        </a>
      </div>
    </div>
    <form>
      <b-form-group :label="$t('class_groups.class_name')" :state="!!errors.name ? false : null"
        :invalid-feedback="errors.name">
        <b-form-input v-model="modelData.name" type="text" :state="!!errors.name ? false : null" />
      </b-form-group>
      <b-form-group :label="$t('class_groups.next_model')" :state="!!errors.next_model_id ? false : null"
        :invalid-feedback="errors.next_model_id">
        <b-form-select v-model="modelData.next_model_id" :options="models" />
      </b-form-group>
      <b-form-group :label="$t('global.product_types')" :state="!!errors.product_types ? false : null"
        :invalid-feedback="errors.product_types">
        <vue-tags-input v-model="tag" :tags="inputTags" :placeholder="$t('class_groups.add_product_type')"
          :autocomplete-items="autocompleteItems" :add-only-from-autocomplete="true" @tags-changed="tagsChanged"
          @before-adding-tag="attachTag" @before-deleting-tag="detachTag" />
      </b-form-group>
      <div>
        <b-btn v-if="isEditing || showCancel" variant="outline-danger" @click.prevent="onCancel">
          {{ $t('global.cancel') }}
        </b-btn>
        <b-btn class="pull-right" variant="success" @click.prevent="onSubmit">
          {{ $t('global.save') }}
          <b-spinner v-if="loading" small type="grow" />
        </b-btn>
      </div>
    </form>
  </b-card>
</template>

<script>
import ClassGroupItemService from '@/services/class-group-item-service';
import ModelsService from '@/services/models-service';
import ProductTypesService from '@/services/product-types-service';

export default {
  name: 'ClassesForm',
  props: {
    model: {
      type: Object,
      required: false,
      default() {
        return {
          product_types: [],
        };
      },
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelData: _.cloneDeep(this.model),
      errors: {},
      tag: '',
      autocompleteItems: [],
      loading: false,
      productTypes: [],
      allModels: [],
    };
  },
  computed: {
    isEditing() {
      return typeof this.modelData.id !== 'undefined' && this.modelData.id > 0;
    },
    inputTags() {
      return this.modelData.product_types.map((a) => {
        return {
          text: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
          id: a.id,
        };
      });
    },
    classGroupId() {
      return !_.isUndefined(this.$route.params.id) ? this.$route.params.id : false;
    },
    models() {
      const array = this.allModels.map((a) => {
        return { text: `${a.id} - ${a.name}`, value: a.id };
      });
      array.unshift({ value: null, text: this.$t('global.none') });
      return array;
    },
  },
  watch: {
    modelData: function () {
      this.errors = {};
    },
    tag: 'initItems',
    model: function () {
      this.modelData = _.cloneDeep(this.model);
    },
  },
  mounted() {
    this.loadProductTypes();
    this.loadModels();
  },
  methods: {
    loadProductTypes() {
      ProductTypesService.getAll()
        .then((response) => {
          this.productTypes = response.data;
        })
        .catch((error) => {
          console.error(error.response);
        });
    },
    loadModels() {
      ModelsService.list().then((response) => {
        this.allModels = response.data;
      }).catch(() => {
        this.$notify({
          type: 'error',
          title: this.$t('global.error'),
          text: this.$t('global.connection_lost_service', { service: 'ModelsService.list' }),
        });
      });
    },
    onSubmit() {
      const data = Object.assign({}, this.modelData, {
        class_group_id: this.classGroupId,
        product_types: this.modelData.product_types.map(item => item.id),
        next_model_id: parseInt(this.modelData.next_model_id),
      });
      const action = this.isEditing ? ClassGroupItemService.update(data) : ClassGroupItemService.store(data);
      action
        .then((response) => {
          this.$emit('saved', Object.assign({}, response.data));
        })
        .catch((error) => {
          if (typeof error.response.data.data !== 'undefined') {
            this.errors = error.response.data.data;
          }
          for (const item in error.response.data.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
        });
    },
    onCancel() {
      this.$emit('canceled');
    },
    initItems() {
      if (this.tag.length < 1) {
        this.autocompleteItems = [];
        return;
      }
      this.autocompleteItems = this.productTypes.filter((a) => {
        if (a.name.toLowerCase().includes(this.tag.toLowerCase())
          || (a.product && a.product.code && a.product.code.toString().includes(this.tag.toLowerCase()))) {
          return true;
        }
      }).map((a) => {
        return {
          text: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
          id: a.id,
          name: a.product && a.product.code ? `${a.product.code} - ${a.name}` : `${a.name}`,
        };
      });
    },
    tagsChanged() {
      this.autocompleteItems = [];
    },
    attachTag(object) {
      this.tag = '';
      if (object.tag.id) {
        this.modelData.product_types.push({
          id: object.tag.id,
          name: object.tag.name,
        });
      }
    },
    detachTag(object) {
      this.tag = '';
      this.modelData.product_types.splice(object.index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
