<template>
  <b-card header-tag="header">
    <div slot="header">
      <span><i class="fa icon-key" /> {{ $t('users.change_password') }} </span>
    </div>

    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="success"
      @dismiss-count-down="countDownChanged"
    >
      {{ $t('users.password_changed') }}
    </b-alert>

    <form
      v-if="dismissCountDown===0"
      @submit.prevent="save"
    >
      <b-form-group
        :label="$t('users.old_password')"
        :state="!errors.oldpassword"
        :invalid-feedback="errors.oldpassword"
      >
        <b-form-input
          v-model="model.oldpassword"
          type="password"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.new_password')"
        :state="!errors.password"
        :invalid-feedback="errors.password"
      >
        <b-form-input
          v-model="model.password"
          type="password"
        />
      </b-form-group>
      <b-form-group
        :label="$t('users.repeat_new_password')"
        :state="!errors.password_confirmation"
        :invalid-feedback="errors.password_confirmation"
      >
        <b-form-input
          v-model="model.password_confirmation"
          type="password"
        />
      </b-form-group>
      <div>
        <b-btn
          class="pull-right"
          type="submit"
          variant="success"
        >
          {{ $t('users.change_password') }}
        </b-btn>
      </div>
    </form>
  </b-card>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions } from 'vuex';

export default {
  name: 'ChangePassword',
  data () {
    return {
      dismissCountDown: 0,
      model           : {
        oldpassword          : '',
        password             : '',
        password_confirmation: '',
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions({ changePassword: VuexTypes.CHANGE_PASSWORD }),
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showSuccess () {
      this.dismissCountDown = 3;
    },
    save () {
      this.changePassword(this.model)
        .then(() => {
          this.errors = {};
          this.$emit('saved', true);
          this.model = {};
          this.showSuccess();
        }).catch((error) => {
          this.errors = {};
          if (typeof error.data !== 'undefined') {
            this.errors = error.data;
          }

          for (const item in error.data) {
            this.errors[item] = this.errors[item].join(' ');
          }
          this.model = {};
        });
    },
  },
};
</script>
<style>
</style>
