import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/users?page=${currentPage}`);
  },
  get (id) {
    return Api().get(`/users/${id}`);
  },
  store (data) {
    return Api().post('/users', data);
  },
  update (data) {
    return Api().put(`/users/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/users/${id}`);
  },
  listPermissions () {
    return Api().get('/permissions');
  },
};
