import { VuexTypes } from '../../types';
import Vue from 'vue';

const state = {
  byCategory: {},
  loading: false,
};

const getters = {
  findById: (state) => (id) => {
    for (let key in state.byCategory) {
      const humanProduct = state.byCategory[key].find(el => el.id === id);

      if (humanProduct) {
        return humanProduct;
      }
    }
  },
};

const mutations = {
  [VuexTypes.CASHIER_HUMAN_PRODUCTS_MUTATION_SET]: (state, productList) => {
    productList.forEach(product => {
      if (!state.byCategory[product.category_id]) {
        Vue.set(state.byCategory, product.category_id, []);
      }

      const index = state.byCategory[product.category_id].findIndex(i => i.id === product.id);

      if (index === -1) {
        state.byCategory[product.category_id].push(product);
      } else {
        Vue.set(state.byCategory[product.category_id], index, product);
      }
    });
  },
};

const actions = {
  [VuexTypes.CASHIER_HUMAN_PRODUCTS_ACTION_SET]: ({ commit }, categoriesList) => {
    commit(VuexTypes.CASHIER_HUMAN_PRODUCTS_MUTATION_SET, categoriesList);
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
