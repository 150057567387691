<template>
  <div class="order-notices">
    <div
      v-if="(locked || waitingAssistance)"
      class="locked"
    >
      <img src="@/static/img/lock.svg">
      <div>
        <p>{{ $t('cashier.OrderTopNotices.locked.title') }}</p>
        <p>{{ $t('cashier.OrderTopNotices.locked.subtitle') }}</p>
        <button
          :disabled="order._loading"
          class="btn rounded-pill"
          @click="() => unlockOrder(order)"
        >
          {{ $t('cashier.OrderTopNotices.locked.btn') }}
        </button>
      </div>
    </div>
    <div
      v-else-if="mustVerifyAge"
      class="verify_age"
    >
      <img src="@/static/img/lock.svg">
      <div>
        <p>{{ $t('cashier.OrderTopNotices.verify_age.title') }}</p>
        <p>{{ $t('cashier.OrderTopNotices.verify_age.subtitle') }}</p>
        <button
          :disabled="order._loading"
          class="btn d-none"
          @click="() => verifyAge(order)"
        >
          {{ $t('cashier.OrderTopNotices.verify_age.btn') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import { VuexTypes } from '@/store/types';
import { CASH_DESK_STATES } from '@/constants';

export default {
  name      : 'OrderTopNotices',
  components: {
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locked() {
      return this.order.state === CASH_DESK_STATES.LOCKED; 
    },
    mustVerifyAge() {
      return this.order.state === CASH_DESK_STATES.VERIFY_AGE; 
    },
    waitingAssistance() {
      return this.order.state === CASH_DESK_STATES.ASSISTANCE; 
    },
  },
  methods: {
    ...mapActions('cashierOrders', {
      unlockOrder: VuexTypes.CASHIER_ORDERS_ACTION_UNLOCK,
      orderVerifyAge: VuexTypes.CASHIER_ORDERS_ACTION_VERIFY_AGE,
      lockOrder: VuexTypes.CASHIER_ORDERS_ACTION_LOCK,
    }),
    verifyAge() {
      this.orderVerifyAge(this.order);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-notices {

  >div {
    padding: 20px 30px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 50px;

    >div {
      flex: 1;

      p {
        margin: 0;

        &:first-child {
          font-weight: 800;
          font-size: 25px;
          line-height: 34px;
          margin-bottom: 5px;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 20px;
        }
      }
    }

    img {
      width: 34px;
      margin-right: 39px;
    }
  }

  .locked {
    background: #DD9800;
    color: #fff;

    .btn {
      width: 100%;
      color: #DD9800;
      background: #FFFFFF;
    }
  }

  .verify_age {
    background: #2F5466;
    color: #fff;

    .btn {
      width: 100%;
      color: #2F5466;
      background: #FFFFFF;
    }
  }
}
</style>
