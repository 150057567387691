import { VuexTypes } from '../../types';
import Vue from 'vue';
import traysService from '@/services/trays-service';
import categoriesService from '@/services/categories-service';

const state = {
  list: [],
  loading: false,
};

const getters = {
  findById: (state) => (id) => {
    for (let key in state.byOrder) {
      const tray = state.byOrder[key].find(el => el.id === id);

      if (tray) {
        return tray;
      }
    }
  },
};

const mutations = {
  [VuexTypes.CASHIER_CATEGORIES_MUTATIONS_SET]: (state, categories) => {
    categories.forEach(category => {
      const index = state.list.findIndex(i => i.id === category.id);
        
      if (index === -1) {
        state.list.push(category);
      } else {
        Vue.set(state.list, index, category);
      }
    });
  },
};

const actions = {
  [VuexTypes.CASHIER_CATEGORIES_ACTION_SET]: ({ commit }, categoriesList) => {
    commit(VuexTypes.CASHIER_CATEGORIES_MUTATIONS_SET, categoriesList);
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
