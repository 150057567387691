import Api from '@/services/api';

export default {
  list (currentPage) {
    return Api().get(`/categories?page=${currentPage}`);
  },
  all () {
    return Api().get('/categories');
  },
  forCashier () {
    return Api().get('/cashier/categories');
  },
  get (id) {
    return Api().get(`/categories/${id}`);
  },
  getChilds (parentId) {
    let query = '';
    if (parentId) {
      query = `?parent_id=${parentId}`;
    }
    return Api().get(`/categories/childs${query}`);
  },
  store (data) {
    return Api().post('/categories', data);
  },
  update (data) {
    return Api().put(`/categories/${data.id}`, data);
  },
  delete (id) {
    return Api().delete(`/categories/${id}`);
  },
};
