<template>
  <form style="padding-bottom: 5px;">
    <b-form-group :label="$t('global.name')" :state="!errors.name" :invalid-feedback="errors.name">
      <b-form-input v-model="modelData.name" type="text" />
    </b-form-group>
    <b-form-group :label="$t('global.description')" :state="!errors.description" :invalid-feedback="errors.description">
      <b-form-input v-model="modelData.description" type="text" />
    </b-form-group>
    <b-form-group v-if="modelData.id" :label="$t('global.status')" :state="!errors.status"
      :invalid-feedback="errors.status">
      <b-input-group :append="percent">
        <b-form-input disabled :value="modelData.status" />
      </b-input-group>
    </b-form-group>
    <b-button size="sm" variant="primary" @click.prevent="save">
      <i class="fa fa-dot-circle-o" /> {{ $t('global.save') }}
      <b-spinner v-if="loading" small label="Loading" type="grow" />
    </b-button>
  </form>
</template>
<script>

import { VuexTypes } from '@/store/types';
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    percent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelData: _.cloneDeep(this.value),
      showClasses: true,
      loading: false,
      statuses: [
        { value: null, text: '' },
        { value: 'created', text: 'created' },
        { value: 'ready_for_learning', text: 'ready_for_learning' },
        { value: 'training', text: 'training' },
        { value: 'failed', text: 'failed' },
        { value: 'done', text: 'done' },
      ],
    };
  },
  computed: { ...mapState(['models']) },
  watch: {
    value: function (newVal) {
      this.modelData = newVal;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      add: VuexTypes.MODELS_ADD,
      update: VuexTypes.MODELS_UPDATE,
    }),
    save() {
      this.loading = true;
      const action = this.modelData.id ? this.update(this.modelData) : this.add(this.modelData);
      action
        .then((response) => {
          this.loading = false;
          this.$emit('saved', response.data);
        }).catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style></style>
