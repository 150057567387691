<template>
  <b-modal
    id="ModalConfirmTrayProductDelete"
    v-model="showModal"
  >
    <template #modal-header>
      <img
        class="logo-icon"
        src="@/static/img/trash.svg"
      >
    </template>
      
    <p class="modal-title">
      {{ $t('cashier.ModalConfirmTrayProductDelete.title') }}
    </p>
    <p class="modal-text">
      {{ $t('cashier.ModalConfirmTrayProductDelete.text') }}
    </p>

    <template #modal-footer>
      <b-button
        class="confirm-lock-btn-cancel"
        @click="close()"
      >
        {{ $t('cashier.ModalConfirmTrayProductDelete.btn.cancel') }}
      </b-button>  
      <b-button
        :disabled="deleting"
        class="confirm-lock-btn-ok"
        @click="deleteTrayProduct"
      >
        {{ $t('cashier.ModalConfirmTrayProductDelete.btn.confirm') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import TrayProductsService from '@/services/tray-products-service';

export default {
  name      : 'ModalConfirmTrayProductDelete',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    trayProductId: {
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      deleting: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.showModal = newVal;
      },
    },
    showModal: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.close();
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteTrayProduct() {
      this.deleting = true;
      TrayProductsService.cashierDelete(this.trayProductId)
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.deleting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
  }
  .logo-icon {
      margin-left: auto;
      margin-right: auto;
      width: 33px;
  }
  .modal-title{
      text-align: center;
      font-size: 30px;
      font-weight: 800;
  }
  .modal-text{
      text-align: center;
      font-size: 18px;
      font-weight: 400;
  }
  .confirm-lock-btn-cancel {
      color: #003C7E;
      background-color: #fff;
      border: 2px solid #003C7E;
      
  }
  .confirm-lock-btn-ok {
      color: #fff;
      background-color: #B91F31;
  }
</style>
